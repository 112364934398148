import React from "react";

type UsefulLinkItemProps ={
	itemRef : string,
	itemText : string | React.ReactNode
}

const UsefulLinkItem = ({itemRef = "https://minsktrans.by/", itemText =""} : UsefulLinkItemProps) =>{
	return (
		<p>
			<a 
				href={itemRef} 
				className='text-reset'
				>
				{itemText}
			</a>
		</p>
	);
};

export default UsefulLinkItem;