import '../TravelDocumentCreateForm.css';
import React, {useState}  from "react";
import Box from '@mui/material/Box';
import FormInput, {formInputProps} from "../../../../Components/Inputs/FormInput";
import AlertChoise from "../../../../Components/AlertChoise";
import {alertStates, useAlert} from "../../../../states/useAlert";
import {travelDocumentApi} from "../../../../api";
import {LoadingButton} from "@mui/lab";

const TransportTab = ()=> {
	const [transportName, setTransportName] = useState('');
	const {alertStatus, setAlertStatus} = useAlert();
	const [createTransport, createResult] = travelDocumentApi.useCreateTransportMutation();

	const submitForm = (e : React.FormEvent<HTMLFormElement>) =>{
		e.preventDefault();
		createTransport({transportName}).unwrap()
		.then(()=>{
			setAlertStatus(true, alertStates.successAlert, `Вид транспорта ${transportName} успешно добавлена!`);
			setTransportName('');
		})
		.catch(err => {
			console.log(err);
			setAlertStatus(true, alertStates.errorAlert, err.message);
		});
	};

	return (
		<Box>
			<Box sx={{textAlign:"center"}}>
				<h3>
					Добавление нового вида транспорта
				</h3>
				<h6>
					Вид транспорта отображается при наведении на категорию транспорта при выборе ПД
				</h6>
			</Box>
			<Box sx = {{width:"70%", margin:"auto"}}>
				<form 
					id = "addTransportForm" 
					onSubmit={submitForm}
					>
					<FormInput 	
						label={"Название транспорта"} 
						inputProps={ 
							formInputProps({
								type : "text",
								name : "transportName",
								onChange : (e) => setTransportName(e.target.value),
								value : transportName,
								placeholder : "Введите название транспорта",
								minLength : 3
							})
						}
						/>
					<Box sx = {{textAlign:"center", marginTop:"0.5rem"}}>
						<AlertChoise {...alertStatus}/>
					</Box>
					<Box sx = {{textAlign:"center", marginTop:"2rem"}}>
						<LoadingButton
							type ="submit"
							className="submitNewCathegory"
							color = "success"
							variant="contained"
							loading = {createResult.isLoading}
							>
							Добавить транспорт
						</LoadingButton>
					</Box>
				</form>
			</Box>
		</Box>
	);
};
export default TransportTab;