/* eslint-disable react/prop-types */
import {Link} from "react-router-dom";
import "./Header.css";
import logo from "../../pictures/logo.png";
import React  from "react";
import ProtectedContent from "../../utils/ProtectedContent";
import NavItem from "./Components/NavItem";
import {useMediaQuery, useTheme} from "@mui/material";
import MobileMenu from "./MobileMenu";
import routeNames from "../../config/routeNames";
import {logout, selectIsLoggedIn, selectUserRole} from "../../features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";

const HeaderView = () =>{
	const dispatch = useAppDispatch();
	const onLogout = ()=>dispatch(logout());
	const role = useAppSelector(selectUserRole);
	const isLoggedIn = useAppSelector(selectIsLoggedIn);
	const unauthorizedHeaderContent = !isLoggedIn;
	const authorizedHeaderContent = isLoggedIn;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	const logoutClicked = () =>{ 
		onLogout();
	}; 
		return (
			<nav className="navbar navbar-expand-lg navbar-light bg-light headerCont">
				<div className='header-logo-container'>
					<Link to = "/">
						<img src={logo} className='header-logo' alt=''/> 
					</Link>
				</div>
				<a className="navbar-brand header-realization" href="/">
					Публичный договор
				</a>
				{isMobile ? (
						<MobileMenu/>) : (
				<>
					<div className="collapse navbar-collapse" id="navbarNav">
						<ul className="navbar-nav listItems" >
							{
								routeNames.map ((routeName) => {
									if(routeName.acceptedRoles.includes(role)){	
										return(
										routeName.routes.map((route, index) => {
											return (
												<NavItem 	to = {route.route} 
													itemText={route.name}
													key = {index}
													/>
											);
										})
										);
									}else{
										return null;
									}
								})
							}
						</ul>
					</div>
					<ProtectedContent isAllowed={ unauthorizedHeaderContent }>
						<a className="navbar-brand logout-brand " href="/login" style={{visibility:'hidden'}}>
							Вход
						</a>
					</ProtectedContent>
					<ProtectedContent isAllowed={ authorizedHeaderContent }>
						<a className="navbar-brand logout-brand " href="/" onClick={()=>logoutClicked()}>
							Выход
						</a>
					</ProtectedContent>
				</>
				)}

			</nav>
		);
};
export default HeaderView;