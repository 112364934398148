/* eslint-disable react/prop-types */
import "./SubmitCancelForm.css";
import React  from "react";
import {Row, Col} from "react-bootstrap";
import {Button} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Send as SendIcon} from "@mui/icons-material";

type SubmitCancelFormProps = {
	resetText : string,
	submitText : string,
	submitLoading? : boolean,
	submitLoadingText? : string,
	submitEndIcon? : React.ReactNode,
}

const SubmitCancelForm = ({resetText = "Отмена", submitText = "Подтвердить действие", submitLoading = false, submitLoadingText = submitText, submitEndIcon = (<SendIcon/>)} : SubmitCancelFormProps)=> {
	return (
		<>
			<Row>
				<Col 	
					className = "colReset-SubmitCancelForm"
					md = {6}
					>
					<Button 
						className={ "resetSubmitCancelForm"}
						type = "reset"
						variant="outlined"
						color="error"
						>
						{resetText}
					</Button>
				</Col>
				<Col	
					className = "colSubmit-SubmitCancelForm"
					md = {6}
					>
					<LoadingButton 	
						className={ "submitSubmitCancelForm"}
						type = "submit"
						variant="contained"
						color="success"
						loading = {submitLoading }
						endIcon ={submitEndIcon}
						loadingPosition="end"
						>
						{submitLoading  ? submitLoadingText : submitText}
					</LoadingButton>
				</Col>
			</Row>
		</>
	);
};

export default SubmitCancelForm ;