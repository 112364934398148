/* eslint-disable import/no-anonymous-default-export */
export default{
	codeRoutes : {
		activationCode : {
			check : "/activationCode/check",
			get : "/activationCode/get",
		},
		passwordRestoreCode : {
			check : "/passwordRestore/checkCode",
			get : "/passwordRestore/getCode",
		}
	},
	passwordRoutes : {
		change : "/passwordRestore/changePassword",
	},
	contractRoutes : {
		getList : "customer/invoices/get/list",
		create : "customer/invoices/create",
		search : '/search/contract',
		printInvoice : '/customer/invoices/print'
	},
	travelDocumentRoutes : {
		get : "/travelDocument/data",
		getAvailibleList : "travelDocument/available",
		getAll : "/travelDocument/all",
		create : "/travelDocument/create",
		update : "/travelDocument/update",
		delete : "/travelDocument/delete",
		copy : "/travelDocument/copy",
	},
	travelDocumentCathegoriesRoutes : {
		create : "/travelDocument/documentCathegory/create"
	},
	transportCathegoriesRoutes : {
		create : "/travelDocument/transportCathegory/create",
		getList : '/travelDocument/transportCathegory/getAllData',
	},
	transportRoutes : {
		create : "/travelDocument/transport/create",
		getList : "/travelDocument/transport/available",
	},
	authRoutes :{
		signup : "/auth/signup",
		signin : "/auth/signin"
	},
	userRoutes : {
		getCurrentUserData : "/customer/getOwnData",
		update : "/customer/update",
	},
	adminRoutes : {
		users: {
			search : "/admin/users/search",
			block : "/admin/users/block",
			unblock : "/admin/users/unblock",
			updateUser : "/admin/users/update/mainInfo",
			updateUserPassword : "/admin/users/update/password",
			getUserStatusesAndRoles : "/admin/get/accountStatuses",
		}
	},
	accountantRoutes :{
		getLastRegisteredUsers : '/accountant/getLastRegistratedList',
		extract : '/accountant/getExtract',
		searchContract : '/accountant/searchContract',
		getUserInfo : '/accountant/getUserInfo'
	},
};
