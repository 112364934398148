import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { FetchArgs, BaseQueryApi, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/query";
import {authExpired} from "../features/auth/authSlice";

const transformAuthorizedErrorResponse = (response : any) => ({
	status :  response.originalStatus ?? response?.data?.status ?? 500, 
	message : response?.data.message ?? response.data ?? 'Незвестная ошибка'
});
const transformFileResponse = (response : any, meta: FetchBaseQueryMeta | undefined, args: any, fileName : string) =>{
	let anchor = document.createElement("a");
		document.body.appendChild(anchor);			
		let objectUrl = window.URL.createObjectURL(response);
		anchor.href = objectUrl;
		anchor.download = fileName;
		anchor.click();
		window.URL.revokeObjectURL(objectUrl);
		document.body.removeChild(anchor);
	return {status : 200};
};

const baseQueryWithExpire =(baseQuery: { (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}): MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>; (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}): MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>; (arg0: any, arg1: any, arg2: any): any; }) =>  async (args: any, api: { dispatch: (arg0: { payload: undefined; type: "auth/authExpired"; }) => void; }, extraOptions: any) => {
	let result = await baseQuery(args, api, extraOptions);
	if (result.error && (result.error.status === 401 || result.error.originalStatus === 401)) {
		api.dispatch(authExpired());
  }
	return result;
  };
  

export {transformAuthorizedErrorResponse, transformFileResponse, baseQueryWithExpire};