import {PayloadAction, createSelector, createSlice} from '@reduxjs/toolkit';
import SessionService from "../../services/sessionService";
import {TransportCathegory, TravelDocument, TravelDocsData } from "../../app/types/TDTypes";
import { SelectOption } from "../../app/types/selectTypes";
export type LastAddedState = {
	contractDate : string,
	contractId : "" | number,
	contractNumber : string,
	contractYear : string,
}
type TableItem  ={
	number : number,
	count : number,
	price : number | null,
	totalPrice : number | null,
	travelDocumentId : number | null,
	selectValues :{
		documentCathegory : string,
		transportCathegory : string,
		travelDocument :  string 
	},
	isDeleting : boolean,
};

type TableSelectsState = {
	documentCathegories : Array<SelectOption>,
	documentCathegoriesTooltips : Map<string, string>,
	transportTypes : Map<string, Array<SelectOption>>,
	transportTooltips : Map<string, string>,
	travelDocuments : Map<string, Map<string, SelectOption[]>>
}

const lastAddedContract : LastAddedState = {
	contractDate : "",
	contractId : "",
	contractNumber : "",
	contractYear : "",
};
const itemsStartNumber : number = 1;
const emptyItem = (number : number):TableItem => ({
								number, 
								count : 1, 
								price : null, 
								totalPrice : null, 
								travelDocumentId : null,
								selectValues :{
									documentCathegory : '',
									transportCathegory : '',
									travelDocument : ''
								},
								isDeleting : false,
							});
const tableSelectsDefState :TableSelectsState = {
	documentCathegories : [],
	documentCathegoriesTooltips : new Map(),
	transportTypes : new Map(),
	transportTooltips : new Map(),
	travelDocuments : new Map()
};
const tableItemsDefaultState : Array<TableItem> = [emptyItem(itemsStartNumber)];
const availibleTDService = new SessionService <TravelDocsData | null>('travelDocumentsList', null);

type ContractState = {
	lastAddedContract : LastAddedState,
	availibleTravelDocuments : TravelDocsData | null,
	tableItems : TableItem[],
	tableSelectsOptions : TableSelectsState
};
const contractSlice = createSlice({
	name : "contract",
	initialState :{
		lastAddedContract,
		availibleTravelDocuments : availibleTDService.getItem(),
		tableItems : [...tableItemsDefaultState],
		tableSelectsOptions : getSelectOptionsFromRawData(availibleTDService.getItem())
	},
	reducers : {
		contractAccepted :  (state, action : PayloadAction<LastAddedState>) =>{
			state.tableItems = [...tableItemsDefaultState];
			state.lastAddedContract = {...state.lastAddedContract, ...action.payload};
		},
		clearLastAdded : state =>{
			state.lastAddedContract = {...lastAddedContract};
		},
		changeAvailibleTravelDocuments : (state, action : PayloadAction<TravelDocsData | null>) => {
			const availableTD = action.payload;
			availibleTDService.saveItem(availableTD);
			state.availibleTravelDocuments = availableTD;
			state.tableSelectsOptions = getSelectOptionsFromRawData(availableTD);			
		},
		addTableItem : (state) => {
			const newNumber = state.tableItems.reduce((max, item) => max > item.number? max : item.number, itemsStartNumber) + 1;
			state.tableItems.push(emptyItem(newNumber));
		},
		deleteTableItem : (state, {payload}) => {
			const number = payload; 
			state.tableItems = state.tableItems
			.filter(item => !item.isDeleting)
			.map((item)=> item.number !== number ? item : {...item, isDeleting : true});
		},
		deleteTableItemEnded : (state) =>{
			state.tableItems = state.tableItems.filter(item => !item.isDeleting);
		},
		clearTableItems : (state) =>{
			state.tableItems = state.tableItems.map(item => ({...item, isDeleting : true}));
		},
		changeItemCount : (state, {payload}) => {
			const {newCount, itemNumber} = payload;
			const index = state.tableItems.findIndex(item => item.number === itemNumber);
			if(index !== -1){
				const {price} = state.tableItems[index];
				const totalPrice = price? (newCount * price)/100 : null;
				state.tableItems[index].count = newCount;
				state.tableItems[index].totalPrice = totalPrice;
			}
		},
		changeSelect : (state, {payload} : PayloadAction<{number : number, documentCathegory? : string, transportCathegory? : string, travelDocument?: string}>) =>{
			const {number : itemNumber, documentCathegory = null, transportCathegory = null, travelDocument = null}  = payload;
			const index = state.tableItems.findIndex(item => item.number === itemNumber);
			if((documentCathegory === null && transportCathegory === null && travelDocument === null) || 
				index === -1){
				return;
			}
			const currentItem = state.tableItems[index];
			state.tableItems[index].selectValues = {
				documentCathegory : documentCathegory ?? currentItem.selectValues.documentCathegory,
				transportCathegory : transportCathegory ?? currentItem.selectValues.transportCathegory,
				travelDocument : travelDocument ?? currentItem.selectValues.travelDocument,
			};
			if(travelDocument && +travelDocument !== currentItem.travelDocumentId){
				if(travelDocument === ''){
					state.tableItems[index] = {...currentItem, count : 1, price:null , totalPrice : null ,travelDocumentId: null};
				}else{
					const {price, travelDocumentId} = contractSlice.getSelectors().selectTravelDocumentDescription(state, +travelDocument) || {price : 0, travelDocumentId : -1};
					state.tableItems[index] = {...currentItem, price, totalPrice: price*currentItem.count/100 , travelDocumentId};
				}
			}
		},
		sortByColumn : (state, action:PayloadAction<{colName : string, sortOrder : number}>) =>{
			const {colName, sortOrder} = action.payload;
			const items = state.tableItems;
			if(!items.length || items[0][colName as keyof TableItem] === undefined)
				return;
			state.tableItems.sort( (item1 , item2)  => {
				const item1Val = item1[colName as keyof TableItem], item2Val = item2[colName as keyof TableItem];
				if(item1Val !== null && item2Val !== null){
					if(item1Val < item2Val)
						return 1 * sortOrder;
					else if (item1[colName as keyof TableItem] === item2[colName as keyof TableItem])
						return 0;
					else
						return -1* sortOrder;
				}else{
					return 0;
				}
			});
		}
	},
	selectors :{
		selectAvailibleTravelDocuments : (state) => state.availibleTravelDocuments,
		selectTDLoadStatus : state => Boolean(state.availibleTravelDocuments),
		selectTravelDocumentDescription : (state, id : number) => state?.availibleTravelDocuments?.travelDocuments.find(doc => doc.travelDocumentId === id),
		selectTableItem : (state, number : number)  => state.tableItems.find(item => item.number === number),
		selectTableItems : createSelector((state : ContractState) => state.tableItems, (items) : TableItem[] => items.filter(item => !item.isDeleting) ),
		selectTableItemDocumentSelectValue: (state, number : number) : string  => contractSlice.getSelectors().selectTableItem(state,number)?.selectValues?.documentCathegory ?? '',
		selectTableItemTransportSelectValue: (state, number : number) : string   => contractSlice.getSelectors().selectTableItem(state,number)?.selectValues?.transportCathegory ?? '',
		selectTableItemTDSelectValue: (state, number  : number) : string => contractSlice.getSelectors().selectTableItem(state,number)?.selectValues?.travelDocument ?? '',
		selectTotalCount  :createSelector((state : ContractState): TableItem[] => contractSlice.getSelectors().selectTableItems(state) , 
							(items :TableItem[]) : number => items.reduce((total, {count}) => total += count ?? 0, 0)),
		
		selectTotalPrice :createSelector((state : ContractState): TableItem[] => contractSlice.getSelectors().selectTableItems(state), 
														(items) : number => +items.reduce((total, {totalPrice}) => total += totalPrice ?? 0, 0).toFixed(2)),
		
		selectTotalVAT :  createSelector((state : ContractState): TableItem[]  => contractSlice.getSelectors().selectTableItems(state), 
											(items) : number => +items.reduce((total, item) => total += Number((Math.round((item?.totalPrice || 0)/6*100)/100).toFixed(2)) ?? 0, 0)?.toFixed(2)),
		selectTableSelectsOptions : (state) => state.tableSelectsOptions,
	}
});

export const {selectAvailibleTravelDocuments, selectTDLoadStatus, selectTravelDocumentDescription, selectTableItem, selectTableItems,
				selectTableItemDocumentSelectValue, selectTableItemTransportSelectValue, selectTableItemTDSelectValue,
				selectTotalCount, selectTotalPrice, selectTotalVAT, selectTableSelectsOptions} = contractSlice.selectors;
export const {contractAccepted, clearLastAdded, changeAvailibleTravelDocuments} = contractSlice.actions;
export const {addTableItem, deleteTableItem, clearTableItems, changeItemCount, changeSelect, deleteTableItemEnded, sortByColumn} = contractSlice.actions;
export default contractSlice.reducer;

function getSelectOptionsFromRawData (rawTravelDocs : TravelDocsData | null) {
	if(!rawTravelDocs){
		return tableSelectsDefState;
	}
	const {travelDocuments, transportCathegories, documentCathegories, documentCathegoriesTooltips} = rawTravelDocs;
	const res :TableSelectsState = {
		documentCathegories : documentCathegories.map((documentCath, index):SelectOption =>({id :String(index), selectLabel: documentCath})),
		documentCathegoriesTooltips : new Map(Object.entries(documentCathegoriesTooltips)),
		transportTypes : new Map<string, Array<SelectOption>>(),
		transportTooltips : createTransportTooltips(transportCathegories),
		travelDocuments : new Map(),
	};
	res.transportTypes = getTransportForDocumentCathegories(res.documentCathegories, travelDocuments);
	res.travelDocuments= getTravelDocumentsForSelects(	res.documentCathegories,res.transportTypes,travelDocuments);
	return res;
}

function getTransportForDocumentCathegories(docCathegoriesSelectOptions : Array<SelectOption>, travelDocuments : Array<TravelDocument>){
	const result = new Map<string, Array<SelectOption>>();
	docCathegoriesSelectOptions.forEach((docCathegory) => {
		let transports = new Set<string>();
		travelDocuments.forEach((doc) =>{ 
			if(doc.documentCathegoryName === docCathegory.selectLabel){
				transports.add(doc.transportCathegoryName);
			}
		});
		const transportsSelectArray = Array.from(transports).map((item, index) :SelectOption => ({id : String(index), selectLabel:item}));
		result.set(docCathegory.selectLabel, transportsSelectArray);
	});
	return result;
}

function getTravelDocumentsForSelects(	docCathegoriesSelectOptions  : Array<SelectOption>, 
										transportsForDocumentCathegory : Map<string, SelectOption[]>, 
										travelDocuments : Array<TravelDocument>){
	let result = new Map<string, Map<string, SelectOption[]>>();
	docCathegoriesSelectOptions.forEach((docCathegory) => {
		let transportsMap = new Map<string, Array<SelectOption>>();
		transportsForDocumentCathegory.get(docCathegory.selectLabel)?.forEach((transportCath)=>{
			let travelDocs : Array<SelectOption> = [];
			travelDocuments.forEach((doc) =>{ 
				if(	doc.documentCathegoryName === docCathegory.selectLabel && 
					doc.transportCathegoryName === transportCath.selectLabel){
					travelDocs.push({id : String(doc.travelDocumentId), selectLabel : doc.documentName});
				}
			});
			transportsMap.set(transportCath.selectLabel, travelDocs);
		});
		result.set(docCathegory.selectLabel, transportsMap);
	});
	return result;
}

function createTransportTooltips(rawTransportCathegories : TransportCathegory){
	let tooltips = new Map<string, string>();
	for (let key in rawTransportCathegories){
		tooltips.set(key, (rawTransportCathegories[key as keyof TransportCathegory] as Array<string>).join(', '));
	}
	return tooltips;
}