import React, {useEffect, useState}  from "react";
import Box from '@mui/material/Box';
import FormInput, {formInputProps} from "../../../Components/Inputs/FormInput";
import {SelectChangeEvent, Stack, Typography} from "@mui/material";
import Select, {stringArrayToSelectItems, createTooltips} from "../../../Components/Select";
import {LifetimeLabel, MoneyInput, LoadingBackdrop, AlertChoise} from "../../../Components";
import {alertStates, useAlert} from "../../../states/useAlert";
import {Col, Row} from "react-bootstrap";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {Navigate, useLocation} from "react-router-dom";
import {travelDocumentApi} from "../../../api";
import {LoadingButton} from "@mui/lab";
import 'moment/locale/ru';
import moment from "moment";
import { SelectOption } from "../../../app/types/selectTypes";
import { CalendarLifetimeItem } from "../../../Components/TravelDocumentComponents/LifeTimeForm/LifetimeLabel";

const openFromDefaultVal = moment(new Date()).add(10, "minutes");
const expireDefaultVal = moment((new Date())).add(1, 'years');

const travelDocumentDefaultState = {
	price : "", 
	documentName : "",
	lifetimeDescription : "",
	lifetimeDays : '' as string | number,
	calendarLifetime : 0,
	useCount : null as (number | null),
	nameForDocuments : '',
	travelDocumentCathegory : '',
	transportCathegory : '',
	openFromDate : openFromDefaultVal as  moment.Moment | null | undefined,
	expireDate : expireDefaultVal as  moment.Moment | null | undefined
};

const calendarLifetime = [	
	{selectLabel : 'Не указано' , id : 0},
	{selectLabel : 'Сутки' , id : 1},
	{selectLabel : 'Неделя', id : 7},
	{selectLabel : 'Декада', id : 10},
	{selectLabel : 'Месяц' , id : 30},
	{selectLabel : 'Год'   , id : 365}
];

const TravelDocumentEditPage = ()=> {
	const location = useLocation();
	const {alertStatus, setAlertStatus} = useAlert();
	const [travelDocument, setTravelDocument] = useState({...travelDocumentDefaultState});
	const [travelDocCathegories, setTravelDocCathegories] = useState([] as SelectOption[]);
	const [transportCathegories, setTransportCathegories] = useState<object | undefined>();
	const [documentId] = useState(location?.state?.travelDocId ?? -1);
	const TDAndTransportCathegoriesQuery = travelDocumentApi.useGetTDAndTransportCathegoriesQuery({});
	const [getTD, getTDResult] = travelDocumentApi.useLazyGetTravelDocumentQuery();
	const [updateTD, updateResult] = travelDocumentApi.useUpdateTravelDocumentMutation();
	
	useEffect(()=>{
		const {error, isSuccess, isError, data} = TDAndTransportCathegoriesQuery;
		if(isSuccess){
			const {transportCathegories, documentCathegories} = data;
			setTransportCathegories(transportCathegories);
			setTravelDocCathegories(stringArrayToSelectItems(documentCathegories.map(docCath => docCath.documentCathegoryName)));
		}else if(isError){
			console.log(error);
			setAlertStatus(true, alertStates.errorAlert, error.message);
		}
	},[TDAndTransportCathegoriesQuery, setAlertStatus]);

	useEffect( () => {
		if(travelDocCathegories.length !== 0 && transportCathegories){
			getTD(documentId).unwrap()
			.then(data =>{
				const travelDocumentServer = {...data};
				setTravelDocument({
					price : (data.price /100).toFixed(2), 
					documentName : data.documentName,
					lifetimeDescription : data.lifetimeDescription,
					lifetimeDays : data.lifetimeDays ?? '',
					calendarLifetime : calendarLifetime.find(item => item.selectLabel === travelDocumentServer.calendarLifetime )?.id ?? 0,
					useCount : data.useCount ,
					nameForDocuments : data.nameForDocuments,
					travelDocumentCathegory : travelDocCathegories.find(item => item.selectLabel === travelDocumentServer.documentCathegoryName)?.id ?? '',
					transportCathegory : "" + Object.keys(transportCathegories as {}).findIndex((item) => item === travelDocumentServer.transportCathegoryName),
					openFromDate : moment(data.openFromDate),
					expireDate : moment(data.expireDate) 
				});
			})
			.catch(error =>{
				console.log(error);
				setAlertStatus(true, alertStates.errorAlert, error.message);
			});
		}
	},[documentId, getTD, setAlertStatus, transportCathegories, travelDocCathegories]);

	const handleChange = (e : SelectChangeEvent<string | number>)=>{
		const {name, value} = e.target;
		let {lifetimeDays} = travelDocument;
		if(name === 'calendarLifetime'){
			lifetimeDays = value === 0 ? '' : value;
		}
		setTravelDocument({...travelDocument, lifetimeDays, [name] : value});
	};

	const submitForm = (e : React.FormEvent<HTMLFormElement>) =>{
		e.preventDefault();
		setAlertStatus(false);
		const travelDocParam = prepareParams(travelDocument);
		updateTD({travelDocumentId : documentId, ...travelDocParam}).unwrap()
		.then(()=>{
				setAlertStatus(true, alertStates.successAlert, `Документ ${documentId} успешно обновлен!`);
				setTravelDocCathegories(travelDocCathegories);
			}
		)
		.catch(error => {
			console.log(error);
			setAlertStatus(true, alertStates.errorAlert, error.message);
		});
	};
	
	function prepareParams(travelDocument : typeof travelDocumentDefaultState){
		const {price, documentName, lifetimeDescription,lifetimeDays,calendarLifetime: calendarLifetimeFromTD, 
				useCount, nameForDocuments, travelDocumentCathegory, transportCathegory, openFromDate,expireDate} = travelDocument;
		const travelDocParam =  {
			price: +price * 100,
			documentName,
			lifetimeDescription,
			lifetimeDays,
			calendarLifetime: "" as string | null,
			useCount : !useCount ? 'NULL' : useCount,
			nameForDocuments,
			documentCathegoryName : (travelDocCathegories[travelDocumentCathegory as keyof SelectOption[]] as SelectOption)?.selectLabel ?? '',
			transportCathegoryName : Object.keys(transportCathegories ?? {})[transportCathegory as keyof string[]],
			openFromDate : openFromDate?.format('YYYY-MM-DD HH:mm:ss') ?? '',
			expireDate : expireDate?.format('YYYY-MM-DD HH:mm:ss') ?? '' 
		};
		for (let key in travelDocParam){
			if(travelDocParam[key as keyof typeof travelDocParam] === '')
				delete travelDocParam[key as keyof typeof travelDocParam];
		}
		
		if(calendarLifetimeFromTD !== 0)
			travelDocParam.calendarLifetime = calendarLifetime.find(item => item.id === calendarLifetimeFromTD)?.selectLabel ?? 'NULL';
		else
			travelDocParam.calendarLifetime = null;
		return travelDocParam;
	}

	if(documentId === -1){
		return(
			<Navigate to={'/notFound'} state={{message : 'Ошибка. Не получается найти указанный проездной документ.'}}></Navigate>
		);
	}

	return (
		<Box>
			<Box sx={{textAlign:"center"}}>
				<h3>
					Редактирование проездного документа
				</h3>
				<h6>
					После внесения необходимых изменений в ПД нажмите кнопку обновить ПД
				</h6>
			</Box>
			<Box sx = {{
					width:"70%", 
					margin:"auto"
				}}
				>
				<form 
					id = "addTravelDocumentForm" 
					onSubmit={submitForm}
					>
					<FormInput 	
						label={"Название проездного документа*"} 
						inputProps={ 
							formInputProps({
								type : "text",
								name : "documentName",
								onChange : handleChange,
								value : travelDocument.documentName,
								placeholder : "Проездной на 10 суток",
								minLength : 3
							})
						}
						/>
					<Row>
						<Col md = {6}>
							<p className="formInputLabel">
								Стоимость документа, руб*
							</p>
						</Col>
						<Col md = {6}>
							<MoneyInput 
								inputProps={
									formInputProps({
										name : "price",
										onChange : handleChange,
										value : travelDocument.price,					
										placeholder : "11.99",
									})
								}
								min = {0} 
								max = {100000}
								maxLen={10}
								/>
						</Col>
					</Row>
					<Row style={{paddingTop:0}}>
						<Col md = {6} >
							<FormInput 	
								label={"Срок действия, сут"} 
								inputProps={ 
									formInputProps({
										type : "number",
										name : "lifetimeDays",
										onChange : handleChange,
										value : travelDocument.lifetimeDays,
										placeholder : "30",
										minLength : 1,
										maxLength : 3,
										readOnly : travelDocument.calendarLifetime !== 0,
										disabled : travelDocument.calendarLifetime !== 0,
										required : false
									})
								}
								/>
						</Col>
						<Col md = {6} >
							<Row >
								<Col md = {6} >
									<p className="formInputLabel">
										По календарю
									</p>
								</Col>
								<Col 
									md = {6} 
									style={{textAlign:"right"}}
									>
									<Select 
										selectItems = {calendarLifetime.map((val) => ({...val, id : String(val.id)}))} 
										selectVal = {String(travelDocument.calendarLifetime)}
										selectOnChange = {handleChange}
										name = {"calendarLifetime"}
										sx={{textAlign:"center", minWidth:"100%"}}
										/>
								</Col>
							</Row>
						</Col>
					</Row>

					<FormInput 	
						label={"Описание срока действия*"} 
						inputProps={ 
							formInputProps({
								type : "text",
								name : "lifetimeDescription",
								onChange : handleChange,
								value : travelDocument.lifetimeDescription,
								placeholder : "с выбранной пассажиром даты",
								minLength : 3
							})}
						/>
					<Typography sx={{marginTop:"0.25rem"}}>
						*В форме выбора ПД срок действия будет отображаться как:{" "}  
						<LifetimeLabel 	
							lifetimeDays={travelDocument.lifetimeDays}
							lifetimeDescription={travelDocument.lifetimeDescription}
							calendarLifetime = { 
								travelDocument.calendarLifetime === 0 ? 
									undefined 
								: 
									(calendarLifetime.find((item)=>item.id === travelDocument.calendarLifetime)?.selectLabel as CalendarLifetimeItem)
							}
							/>
					</Typography>
					<FormInput 	
						label={"Количество поездок"} 
						inputProps={ 
							formInputProps({
								type : "number",
								name : "useCount",
								onChange : handleChange,
								value : travelDocument.useCount ?? '',
								placeholder : "0",
								minLength : 1,
								required : false
							})
						}
						/>
					<FormInput 	
						label={"Название для документов*"} 
						inputProps={ 
							formInputProps({
								type : "text",
								name : "nameForDocuments",
								onChange : handleChange,
								value : travelDocument.nameForDocuments,
								placeholder : "Проездной АВТОБУС-ТРОЛЛЕЙБУС-ТРАМВАЙ на месяц",
								minLength : 3
							})
						}
						/>
						<Row>
							<Col md = {3}>
								<p className="formInputLabel">
									Категория ПД*
								</p>
							</Col>
							<Col 
								md = {3} 
								style={{textAlign:"right"}}
								>
								<Select 
									selectItems= {travelDocCathegories} 
									selectVal={travelDocument.travelDocumentCathegory}
									selectOnChange = {handleChange}
									name = {"travelDocumentCathegory"}
									sx={{textAlign:"center", minWidth:"100%"}}
									required = {true}
									/>
							</Col>
							<Col 
								md = {3} 
								style={{paddingRight:0}}
								>
								<p className="formInputLabel">
									Категория транспорта*
								</p>
							</Col>
							<Col 
								md = {3} 
								style={{textAlign:"right"}}
								>
								<Select 
									selectItems= {stringArrayToSelectItems(Object.keys(transportCathegories ?? {}))} 
									toolTips={createTooltips(transportCathegories ?? {})}
									selectVal={travelDocument.transportCathegory}
									selectOnChange = {handleChange}
									name = {"transportCathegory"}
									sx={{textAlign:"center", minWidth:"100%"}}
									required = {true}
									/>
							</Col>
						
						</Row>
						<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru" >
						
						<Stack 	
							direction={"row"} 
							spacing={0}
							justifyContent="center"
							alignItems="center"
							useFlexGap flexWrap="wrap">
							<Typography 
								sx={{
									width:"25%", 
									justifySelf:"left", 
									fontSize:"large"
								}}
								> 
								Документ актуален с
							</Typography>
							<DateTimePicker 
								// fullWidth ={false}							
								// isRequired
								value={travelDocument.openFromDate}
								onChange={(val)=>setTravelDocument({...travelDocument, openFromDate:val})}
								sx={{
									width : "25%",
									height: "50%",
									alignSelf: "flex-end"
								}}
								shouldDisableYear={(year) => year.isAfter(moment().add(2, 'years'))}/>
							<Typography sx={{width:"25%", paddingLeft:"12.5%",justifySelf:"center", fontSize:"large"}}> по </Typography>
							<DateTimePicker 
								disablePast
								// isRequired
								value={travelDocument.expireDate}
								onChange={(val)=>setTravelDocument({...travelDocument, expireDate:val})}
								sx={{
									width : "25%",
									height: "50%",
									alignSelf: "flex-end"
								}}
								shouldDisableYear={(year) => year.isAfter(moment().add(2, 'years'))}/>
						</Stack>
						</LocalizationProvider>
					<Box sx = {{textAlign:"center", marginTop:"0.5rem"}}>
						<AlertChoise {...alertStatus}/>
					</Box>
					<Box sx = {{textAlign:"center", marginTop:"2rem"}}>
						<LoadingButton
							type ="submit"
							className="submitNewCathegory"
							color = "success"
							variant="contained"
							loading = {updateResult.isLoading}
							>
							Обновить проездной документ
						</LoadingButton>
					</Box>
				</form>
				<LoadingBackdrop open = {getTDResult.isFetching || TDAndTransportCathegoriesQuery.isFetching}/>
			</Box>
		</Box>
	);
};
export default TravelDocumentEditPage;