import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import config from "../config/config";
import apiRoutes from "../config/apiRoutes";
import {transformAuthorizedErrorResponse, transformFileResponse, baseQueryWithExpire} from './transformApiResponse';
import moment from "moment";
import { RootState } from "../app/store/store";
import { NoDataResponse, ValuesResponse } from "./responseTypes";
import { UserData, UserListItem, UserInfoResponse, UserAdminDescription, UserRole } from "../app/types/userTypes";
import { UpdateUserBodyArguments } from "./queryArgumentsTypes";

const {authRoutes, userRoutes, codeRoutes, accountantRoutes, adminRoutes} = apiRoutes;

export const userApi = createApi({
	reducerPath: 'userApi',
	baseQuery: 	baseQueryWithExpire(fetchBaseQuery({
		baseUrl: config.env.apiBase,
		prepareHeaders: (headers, {getState}) => {
			const token = 	(getState() as RootState).auth.token ||
							(getState() as RootState).passwordChange.token;
			if (token){
				headers.set('Authorization', token);
			}
		},
	})),
	tagTypes :['UserOwnData'],
	endpoints: (builder) => ({
		logIn: builder.query({
			query: ({registrationNumber, password}) => ({
				url : authRoutes.signin,
				params : {registrationNumber, password}
			}),
			transformResponse: (response) => response.values.token,
			transformErrorResponse : (response) => response.data,
		}),
		getOwnData : builder.query({
			query : ()=>({
				url:`${userRoutes.getCurrentUserData}`,
			}),
			providesTags :['UserOwnData'],
			transformResponse: (response : ValuesResponse<{user : UserData}>) => response.values.user,
			transformErrorResponse :transformAuthorizedErrorResponse,
		}),
		signup: builder.mutation({
			query : (body)=>({
				url:`${authRoutes.signup}`,
				method: "POST",
				body
			}),
			transformResponse: (response : NoDataResponse) => response,
			transformErrorResponse : (response) => response.data,
		}),
		updateOwnData : builder.mutation({
			query : (body : UpdateUserBodyArguments)=>({
				url:`${userRoutes.update}`,
				method: "POST",
				body
			}),
			invalidatesTags:['UserOwnData'],
			transformResponse: (response : NoDataResponse) => response,
			transformErrorResponse :transformAuthorizedErrorResponse,
		}),
		queryActivationCode : builder.query({
			query : ({registrationNumber})=>({
				url: codeRoutes.activationCode.get,
				params : {registrationNumber}
			}),
			transformResponse: (response : NoDataResponse) => response.message, 
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		checkActivationCode : builder.mutation({			
			query : ({registrationNumber, userInputCode})=>({
				url:`${codeRoutes.activationCode.check}`,
				method: "POST",
				body : {registrationNumber, userInputCode}
			}),
			transformResponse: (response : NoDataResponse) => response.message, 
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		queryPassRestoreCode : builder.query({
			query : ({registrationNumber})=>({
				url: codeRoutes.passwordRestoreCode.get,
				params : {registrationNumber},
			}),
			transformResponse: (response : NoDataResponse) => response?.message, 
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		checkPassRestoreCode : builder.query({			
			query : ({registrationNumber, userInputCode})=>({
				url:`${codeRoutes.passwordRestoreCode.check}`,
				params : {registrationNumber, userInputCode}
			}),
			transformResponse: (response : ValuesResponse<{message : string, token : string}> ) => response.values, 
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		changePassword : builder.query({
			query : ({newPassword})=>({
				url:`${apiRoutes.passwordRoutes.change}`,
				params : {newPassword}
			}),
			transformResponse: (response : NoDataResponse) => response, 
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		getLastRegisteredUsersList : builder.query({
			query : (limit = 10)=>({
				url: accountantRoutes.getLastRegisteredUsers,
				params : {limit}
			}),
			transformResponse: (response : ValuesResponse<{users : UserListItem[]}>) => response.values.users, 
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		downloadExtract : builder.query({
			query : () => ({
				url : accountantRoutes.extract,
				params : {time : moment().seconds},
				responseHandler : (res : Response) => res.status === 200 ? res.blob() :res.json(),
			}),
			transformResponse :(response, meta, args) =>  transformFileResponse(response, meta, args, `реестр_${moment().format('lll')}.xlsx`),
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		getUserInfo :  builder.query({
			query : (registrationNumber)=>({
				url: accountantRoutes.getUserInfo,
				params : {registrationNumber}
			}),
			transformResponse: (response : ValuesResponse<UserInfoResponse>) => response.values, 
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		//admin-role
		getUserStatusesAndRoles :  builder.query({
			query : (params)=>({
				url: adminRoutes.users.getUserStatusesAndRoles,
				params  
			}),
			transformResponse: (response : ValuesResponse<{roles: Array<UserRole>, statuses:string[]}>) => response.values, 
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		adminUserSearch  :  builder.query({
			query : ({registrationNumber, email})=>({
				url: adminRoutes.users.search,
				params : {registrationNumber, email}
			}),
			transformResponse: (response : ValuesResponse<{user : UserAdminDescription}>) => response.values.user, 
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		adminUpdateUser : builder.mutation ({
			query : (userData)=>({
				url:adminRoutes.users.updateUser,
				method: "POST",
				body : userData
			}),
			transformResponse: (response : NoDataResponse) => response?.message || '', 
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		adminBlockUser : builder.mutation ({
			query : ({registrationNumber, block})=>({
				url:	block ? adminRoutes.users.block : adminRoutes.users.unblock,
				method: "POST",
				body : {registrationNumber},
			}),
			transformResponse: (response : NoDataResponse) => response?.message || '', 
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		adminChangeUserPassword : builder.mutation ({
			query : ({registrationNumber, newPassword})=>({
				url:	adminRoutes.users.updateUserPassword,
				method: "POST",
				body : {registrationNumber, newPassword},
			}),
			transformResponse: (response : NoDataResponse) => response?.message || '', 
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
	}),
});

export const {useLogInQuery, useSignupMutation, useGetOwnDataQuery, useUpdateOwnDataMutation, useQueryActivationCodeQuery, useCheckActivationCodeMutation} = userApi;