import "./SelectTravelDocType.css";
import { useState, useEffect, useCallback } from "react";
import Select from "../../Select";
import { 	changeSelect, 
			selectTableItem, 
			selectTableItemDocumentSelectValue, 
			selectTableItemTDSelectValue, 
			selectTableItemTransportSelectValue,
			selectTableSelectsOptions } from "../../../features/contract/contractSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { SelectChangeEvent } from "@mui/material";

const noType = '-';
interface IChangeSelectParams {
	documentCathegory?: string, 
	transportCathegory?: string, 
	travelDocument?: string
}

const SelectTravelDocType = ({itemNumber} : {itemNumber : number}) =>{
	const dispatch = useAppDispatch();
	const	{	
				documentCathegories, 
				documentCathegoriesTooltips, 
				transportTypes: transportsForDocumentCathegory, 
				transportTooltips, 
				travelDocuments: travelDocumentsForSelects
		} = useAppSelector(selectTableSelectsOptions);
			
	const selectValues = useAppSelector(state =>selectTableItem(state, itemNumber))?.selectValues;
	const cathegorySelect = useAppSelector(state => selectTableItemDocumentSelectValue(state, itemNumber));
	const transportSelect = useAppSelector(state => selectTableItemTransportSelectValue(state, itemNumber));
	const TDSelect = useAppSelector(state => selectTableItemTDSelectValue(state, itemNumber));
	const changeSelectValues = 
		useCallback((changeSelectParams : IChangeSelectParams) => dispatch(changeSelect({number : itemNumber, ...changeSelectParams})), [dispatch, itemNumber]);
		
	const [documentTypes] = useState(documentCathegories);

	const getTransportTypes = useCallback((documentCathegory : string)=>{
		const docType = documentTypes[+documentCathegory];
		if(docType && documentCathegory !== ''){
			const transportTypes = transportsForDocumentCathegory.get(docType.selectLabel) ??[];
			return transportTypes;
		}
		return [];
	}, [documentTypes, transportsForDocumentCathegory]);

	const [transportTypes, setTransportTypes] = useState(getTransportTypes(cathegorySelect));
	
	const getAvailableTD = useCallback( (documentCathegory: string, transportType: string) => {
		const 	selectedDocLabel = documentTypes[+documentCathegory]?.selectLabel, 
		selectedTransport = transportTypes[+transportType]?.selectLabel;
		if(selectedDocLabel && selectedTransport && documentCathegory !== '' && transportType !== ''){
			const travelDocs = travelDocumentsForSelects.get(selectedDocLabel)?.get(selectedTransport) ?? [];
			return travelDocs;
		}
		return [];
	},[documentTypes, transportTypes, travelDocumentsForSelects]);

	const [availableTravelDocuments, setAvailableTravelDocuments] = useState(getAvailableTD(cathegorySelect, transportSelect));
	
	useEffect(()=>setTransportTypes(getTransportTypes(cathegorySelect)),[cathegorySelect, getTransportTypes]);
	useEffect(()=>{
		if(transportTypes && transportTypes.length !== 0 && cathegorySelect !== ''){
			const noTransportCathegory = transportTypes.find(type => type.selectLabel === noType);
			if(noTransportCathegory && transportSelect !== noTransportCathegory.id){
				changeSelectValues({transportCathegory : noTransportCathegory.id});
			}
		}
	}, [cathegorySelect, changeSelectValues, transportSelect, transportTypes]);

	useEffect(()=> setAvailableTravelDocuments(getAvailableTD(cathegorySelect, transportSelect)),[cathegorySelect, getAvailableTD, transportSelect]);

	useEffect(()=>{
		if(availableTravelDocuments && availableTravelDocuments.length !== 0 && cathegorySelect !== ''){
			const noDocument = availableTravelDocuments.find(travelDocument => travelDocument.selectLabel === noType);
			if(noDocument && TDSelect !== noDocument.id){
				changeSelectValues({travelDocument : noDocument.id});
			}
		}
	},[TDSelect, availableTravelDocuments, cathegorySelect, changeSelectValues]);

	const docTypeChanged = (event : SelectChangeEvent<string | number>) =>{
		const docTypeId = event.target.value;
		changeSelectValues({documentCathegory : String(docTypeId), transportCathegory : '', travelDocument : ''});
	};

	const transportTypeChanged = (event : SelectChangeEvent<string | number>) =>{
		const transportIndex= event.target.value;
		changeSelectValues({transportCathegory : String(transportIndex), travelDocument : ''});
	};

	const travelDocTypeChanged = (event : SelectChangeEvent<string | number>) =>{
		const id = event.target.value;		
		changeSelectValues({travelDocument : String(id)});
	};
	if(!selectValues){
		return (<></>);
	}
	return (
		<>
			<Select 
				selectItems={documentTypes} 
				selectVal={cathegorySelect} 
				selectOnChange={docTypeChanged}
				toolTips={documentCathegoriesTooltips}
				required
				/>
			<Select 
				selectItems={transportTypes} 
				selectVal={transportSelect}
				selectOnChange={transportTypeChanged}
				toolTips={transportTooltips}
				required
				/>
			<Select 
				selectItems={availableTravelDocuments} 
				selectVal={TDSelect}
				selectOnChange={travelDocTypeChanged}
				required
				/>
		</>
	);
};
export default SelectTravelDocType;