import { RawFormInputProps } from "./FormInput";

type MoneyInputProps = {
	inputProps : RawFormInputProps,
	maxLen? : number, 
	min? : number, 
	max? : number,
}

const MoneyInput = ({inputProps, maxLen = 20, min = 0, max = 100} : MoneyInputProps)=> {
	return (
		<input 	
			{...inputProps}
			className={"formInput"} 
			type={"number"} 
			min={min} 
			max={max}
			maxLength={maxLen} 
			step={0.01}
			onInput={ e => setLen(e)}
		/>
	);
};

function setLen(e : React.FormEvent<HTMLInputElement>){
	let input = e.currentTarget;
	if (input.value.length > input.maxLength) 
		input.value = input.value.slice(0, input.maxLength);
}

export default MoneyInput;