/* eslint-disable react/prop-types */
import "./ContractForm.css";
import React, {useCallback, useEffect, useState}  from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import TravelDocumentTableForm from "./TravelDocumentTableForm";
import {alertStates, useAlert} from "../../../states/useAlert";
import {LastAddedState, changeAvailibleTravelDocuments, contractAccepted, selectTDLoadStatus, selectTableItems, selectTotalPrice} from "../../../features/contract/contractSlice";
import {Row} from "react-bootstrap";
import {Box, FormControlLabel, Checkbox, Alert} from "@mui/material";
import config from "../../../config/config";
import {useCreateContractMutation} from "../../../api/contractApi";
import {LoadingButton} from "@mui/lab";
import {AlertChoise, LabelWithTooltip, LoadingBackdrop} from "../../../Components";
import {useGetAvailibleTDQuery} from "../../../api/travelDocumentsApi";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { TravelDocsData } from "../../../app/types/TDTypes";
import checkboxesLabels from "./checkBoxes";

const ContractForm = ()=> {
	const dispatch = useAppDispatch();
	const navigate =useNavigate();

	const onContractAdded = (contract : LastAddedState) => dispatch(contractAccepted(contract));
	const onTDLoaded = useCallback( (travelDocuments : TravelDocsData) => dispatch (changeAvailibleTravelDocuments(travelDocuments)),[dispatch]);
	
	const travelDocsLoadStatus = useAppSelector(selectTDLoadStatus);
	const [createContract, result] = useCreateContractMutation();
	const travelDocumentQuery = useGetAvailibleTDQuery({},{pollingInterval : 5000, skip : Boolean(travelDocsLoadStatus)});

	const itemsData = useAppSelector(selectTableItems);
	const contractSum = useAppSelector(selectTotalPrice);

	const [acceptTerms, setAcceptTerms]  = useState(checkboxesLabels.map(() => false));
	const {alertStatus, setAlertStatus} = useAlert();

	useEffect(()=>{
		if(travelDocumentQuery.isSuccess){
			setAlertStatus(false);
			onTDLoaded(travelDocumentQuery.data);
		}else if (travelDocumentQuery.isError){
			const {message} = travelDocumentQuery.error;
			setAlertStatus(true, alertStates.errorAlert, message);
		}
	},[onTDLoaded, setAlertStatus, travelDocumentQuery]);

	const submitForm = (e : React.FormEvent<HTMLFormElement>)=>{
		e.preventDefault();
		setAlertStatus(false);
		if(itemsData.length === 0){
			setAlertStatus(true, alertStates.dangerAlert, "Для оформления заявки должен быть выбран хотя бы 1 проездной документ!");
			return;
		}

		const now = moment(); 
		const travelDocumentsSendMap  = new Map();
		itemsData.forEach (item =>{
			const {travelDocumentId, count} = item;
			if(!travelDocumentsSendMap.has (travelDocumentId)){
				travelDocumentsSendMap.set(travelDocumentId, count);
			}else{
				const oldCount = travelDocumentsSendMap.get(travelDocumentId);
				travelDocumentsSendMap.set(travelDocumentId,  count + oldCount);
			}
		});
		const travelDocsSend = Array.from(travelDocumentsSendMap, ([travelDocumentId,count]) => ({travelDocumentId, count}));
		const sendData = {
			contractSumRuble:Math.trunc(contractSum), 
			contractSumPenny:Math.trunc( +((contractSum - Math.trunc(contractSum)).toFixed(2) )*100),
			contractDate: now.format('YYYY-MM-DD HH:mm:ss'),
			travelDocuments : travelDocsSend
		};
		createContract(sendData).unwrap()
		.then(res =>{
			onContractAdded({...res, contractDate : now.toISOString()});
			navigate('/contractAdded');
		})
		.catch(err => {
			const {message} = err;
			console.log(err);
			setAlertStatus(true, alertStates.errorAlert, message);
		});
	};

	const getAcceptsCheckboxes = () =>      
		checkboxesLabels.map((checkboxLabel,i) => {
			return <FormControlLabel 	
						key = {i} 
						control={
							<Checkbox 	
								checked={acceptTerms[i]}
								required
								/>
						}
						label = {checkboxLabel}
						onChange={ (e)=>
							setAcceptTerms(
								checkboxesLabels.map(
									(label, index) => index === i ? (e as React.ChangeEvent<HTMLInputElement>).target.checked : acceptTerms[index]	
								)
							)
						}
						/>;
		});
	
	const customerTooltipText = config.tooltips.productTooltip; 
	const content = travelDocsLoadStatus ? (<TravelDocumentTableForm/>) :
		((	<Row>
				<Alert severity="error" > 
					{travelDocumentQuery.isFetching ? 
					<>Данные о товарах загружаются. Ожидайте...</>
					:
					<>Ошибка получения данных о проездных билетах. Перезагрузите страницу! При повторной ошибке обратитесь в тех поддержку.</>
					}
				</Alert>
			</Row>));
	
	return (
		<div className= "createContract_form_contaner"> 
			<form 
				id = "createContract-form" 
				onSubmit={submitForm}
				>
				<div className="row">
					<div className="col-md-12 text-center">
						<h1 className=''>
							ЗАЯВКА
						</h1>
						<h5 className='main-createContract-text'>
							на приобретение {" "}
							<LabelWithTooltip 
								tooltipLabel={customerTooltipText} 
								labelText="товаров"
								/>
							{" "}по безналичному расчету
						</h5>
					</div>
					<div className="col-md-12 text-center">  
					</div>
				</div>
				{content}
				<Box sx={{marginLeft : "1rem"}}>
					<Box>
						{getAcceptsCheckboxes()}
					</Box>
					{/* <FormControlLabel 
						label = "Принять всё." 
						control={
							<Checkbox 
								name={"acceptAllTerms"} 
								onChange={handleChange} 
							/>
						} 
					/> */}
				</Box>    
				<AlertChoise {...alertStatus}/>
				<Row>
					<LoadingButton 
						className="submitCreateContract" 
						type="submit" 
						variant="contained"
						loading = {result.isLoading}
						>
						Завершить оформление заявки
					</LoadingButton>
				</Row>
			</form>
			<LoadingBackdrop open = {travelDocumentQuery.isFetching}/>
		</div>
	);
};
export default ContractForm;