import {Box, Typography} from "@mui/material";
import {useMemo} from 'react';
import {MRT_Table as MrtTable, useMaterialReactTable} from 'material-react-table';
import LabelWithTooltip from "../../../Components/LabelWithTooltip";
import config from "../../../config/config";

const TicketWindowList = ()=> {
	const customerTooltipText = config.tooltips.productTooltip; 
	const columns = useMemo(
		() => [
			{
				accessorKey: 'location',
				header: 'Месторасположение кассы',
				size: 100,
			},
			{
				accessorKey: 'schedule',
				header: 'Режим работы',
				size: 400,
			},
		],
		[],
	);
	const data = [
		{
			location: "А/в «Центральный», ул. Бобруйская, 6, касса  №1,2",
			schedule: ( 
						<span style={{textAlign:"center"}}>
							<strong>Понедельник – пятница (касса №1,2)<br/></strong>
							8.00 – 20.00<br/>
							<strong>Суббота (касса №2)<br/></strong>
							8.00 – 20.00<br/>
							Обеденный перерыв<br/>
							14.00 - 15.00<br/>
							Технологические перерывы:<br/>
							11.00 -11.10 <br/>
							17.30 -17.40 <br/>
							<strong>Воскресенье (касса №2)<br/></strong>
							8.00 – 19.00<br/>
							Обеденный перерыв<br/>
							14.00 - 15.00<br/>
							Технологические перерывы:<br/>
							11.00 -11.10 <br/>
							17.30 -17.40 <br/>
							<u>Праздничные дни - выходные</u> <br/>
							В дни предшествующие государственному празднику, режим работы кассы
							8.00 – 19.00
						</span>),		
		},
		{
			location: `Станция метро «Грушевка» пр. Дзержинского (подземный переход) касса №145	`,
			schedule: ( <span style={{textAlign:"center"}}>
							<strong>Понедельник – пятница</strong><br/>
							7.00 – 19.00<br/>
							Обеденный перерыв<br/>
							13.20 -15.00<br/>
							Технологические перерывы:<br/>
							понедельник, среда, пятница<br/>
							09.30-10.00 <br/>
							17.00-17.15<br/>
							Технологические перерывы:<br/>
							вторник, четверг<br/>
							09.45-10.00 <br/>
							17.00-17.15<br/>
							<u>Выходные –<br/>
							суббота, воскресенье, праздничные дни<br/></u>
						</span>),	
		},
		{
			location: "Пункт обмена БСК пр. Партизанский, 6",
			schedule: ( <span style={{textAlign:"center"}}>
							<strong>Понедельник – пятница</strong><br/>
							8.00 – 20.00<br/>
							<u>Выходные –<br/>
							суббота, воскресенье, праздничные дни</u><br/>
							Обеденный перерыв<br/>
							14.00 - 15.00<br/>
							Технологические перерывы:<br/>
							11.00 -11.10<br/>
							13.00 -13.10<br/>
							17.30 -17.40<br/>
						</span>),	
		},
	];

	const table = useMaterialReactTable({
		columns,
		data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
		enableColumnActions: false,
		enableColumnFilters: false,
		enablePagination: false,
		enableSorting: false,
		mrtTheme: (theme) => ({
			baseBackgroundColor: theme.palette.background.default, //change default background color
		}),
		muiTableBodyRowProps: {hover: false},
		muiTableProps: {
			sx: {
			border: '1px solid rgba(81, 81, 81, .5)',
			},
		},
		muiTableHeadCellProps: {
			sx: {
			border: '1px solid rgba(81, 81, 81, .5)',
			fontStyle: 'italic',
			fontWeight: 'normal',
			},
		},
		muiTableBodyCellProps: {
			sx: {
			border: '1px solid rgba(81, 81, 81, .5)',
			},
		},
	});

	return (
		<Box sx={{margin:"auto"}}>
			<Typography 
				variant="h4" 
				gutterBottom 
				>
				Кассы, осуществляющие отпуск{" "}
				<LabelWithTooltip 
					tooltipLabel={customerTooltipText} 
					labelText="товаров"
					/>{" "}
					по безналичному расчету
				</Typography>
			<MrtTable table={table}/>
		</Box>
	);};

export default TicketWindowList;