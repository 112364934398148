/* eslint-disable react/prop-types */
import {Link} from "react-router-dom";
import "./Header.css";
import React, {useState}  from "react";
import {Drawer, List, ListItem, ListItemText, IconButton} from "@mui/material";
import {Menu as MenuIcon} from "@mui/icons-material";
import styled from "styled-components";
import routeNames from "../../config/routeNames";
import {useDispatch, useSelector} from "react-redux";
import {logout, selectIsLoggedIn, selectUserRole} from "../../features/auth/authSlice";

const MenuItem = styled(ListItem)(() => ({
		width : "30vw",
		marginBlock : "1vh"
}));
const MenuLink = styled(Link)(() => ({
	color : "gray",
	fontSize : "1.3rem",
	":hover" :{
		color : "black",
	}
}));


const MobileMenu = () =>{
	const dispatch = useDispatch();
	const onLogout = ()=>dispatch(logout());
	const role = useSelector(selectUserRole);
	const isLoggedIn = useSelector(selectIsLoggedIn);

	const [openDrawer, setOpenDrawer] = useState(false);
	const LogoutClick = () =>{
		setOpenDrawer(false);
		onLogout();
	};
	return (
		<>
			<Drawer open = {openDrawer}
					onClose={() => setOpenDrawer(false)}
					sx={{width:"30%"}}
				>
				<List >

				{
					routeNames.map ((routeName) => {
						if(routeName.acceptedRoles.includes(role)){	
							return(
								routeName.routes.map((route, index) => {
									return (
										<MenuItem 	onClick={() => setOpenDrawer(false)}
													key = {index}> 
											<ListItemText>
											<MenuLink to={route.route}>{route.name}</MenuLink>
											</ListItemText>
										</MenuItem>
									);
								})
							);
						}else{
							return null;
						}
					})
				}
				{
					isLoggedIn ?(
						<MenuItem onClick={LogoutClick}> 
								<ListItemText>
								<MenuLink to={'/'}>Выход</MenuLink>
								</ListItemText>
						</MenuItem>
					) 
					: 
					(<></>)
				}

				</List>
			</Drawer>
			<IconButton onClick={() => setOpenDrawer(!openDrawer)}>
				<MenuIcon/>
			</IconButton>
		</>
	);
};
export default MobileMenu;