import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {JwtPayload, jwtDecode} from "jwt-decode";
import {roleNames} from "../../config/roleNames";
import SessionService from "../../services/sessionService";

type AuthState = {
	token : string | null,
	isLoggedIn : boolean,
	isExpired : boolean,
	role : string
};

const tokenSessionItemName = "authToken";
const noToken = null;
const tokenService = new SessionService<string>(tokenSessionItemName, noToken);
const authorizedState = (token : string) : AuthState => ({token, isLoggedIn : true, isExpired : false, role : getRoleFromToken(token)});
const unauthorizedState = () : AuthState => ({token : noToken, isLoggedIn : false, isExpired : false, role : unauthorizedRole});
const expiredState = (state : AuthState) : AuthState => ({...state,...getToken(), isExpired : true});
const unauthorizedRole = roleNames.unauthorized;

const initialState : AuthState = {
	...getToken()
};

export const authSlice = createSlice({
	name : "auth",
	initialState,
	reducers : {
		authSuccess : (state, action: PayloadAction<string>) =>{
			const newToken = action.payload;
			tokenService.saveItem(newToken);
			return authorizedState(newToken);
		},
		logout : () =>{
			tokenService.removeItem();
			sessionStorage.clear();
			return unauthorizedState();
		},
		authExpired : (state) =>{
			return expiredState(state);
		},
		expireCleared : () =>{
			tokenService.removeItem();
			sessionStorage.clear();
			return unauthorizedState();
		}
	},
	selectors:{
		selectToken : (sliceState) => sliceState.token,
		selectIsLoggedIn : (sliceState) => sliceState.isLoggedIn,
		selectIsAuthExpired : (sliceState) => sliceState.isExpired,
		selectUserRole : (sliceState) => sliceState.role,
	}
});

function  getToken () : AuthState{
	const token = tokenService.getItem();
	if(token)
		return authorizedState(token);
	else
		return unauthorizedState();
}
 
interface JwtPayloadExt extends JwtPayload {
	userRole : string
};

function getRoleFromToken(token : string){
	const decoded = jwtDecode(token);
	return (decoded as  JwtPayloadExt).userRole;
}

export const {selectToken, selectUserRole, selectIsAuthExpired, selectIsLoggedIn} = authSlice.selectors;
export const {authSuccess, authExpired, logout, expireCleared} = authSlice.actions;
export default authSlice.reducer;