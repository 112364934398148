import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import SessionService from "../../services/sessionService";

type PasswordChangeState = {
	isCodeSend : boolean | null,
	registrationNumber : string|null,
	token : string|null
};

const codeSendSessionItemName =  "isPassChangeCodeSend";
const regNumberSessionItemName = "passChangeCodeRequester";
const passChangeTokenItemName = 'passChangeToken';
const codeSendService = new SessionService<boolean|null>(codeSendSessionItemName, false);
const registrationNumberService = new SessionService<string|null>(regNumberSessionItemName, null);
const passChangeTokenService = new SessionService<string|null>(passChangeTokenItemName, null);

const initialState : PasswordChangeState = {
	isCodeSend : codeSendService.getItem(),
	registrationNumber : registrationNumberService.getItem(),
	token : passChangeTokenService.getItem()
}

export const passwordChangeSlice = createSlice({
	name : "passwordChange",
	initialState,
	reducers : {
		setPasswordChangeCodeSend : (state, action : PayloadAction<boolean>) =>{
			const value = action.payload;
			if(value !== true && value  !== false){
				return;
			}
			state.isCodeSend = value ;
			codeSendService.saveItem(value);
		},
		setPasswordChangeRequester : (state, action  : PayloadAction<string | null>) =>{
			state.registrationNumber = action.payload;
			state.isCodeSend = false;
			codeSendService.saveItem(false);
			registrationNumberService.saveItem(action.payload);
		},
		setPasswordChangeToken : (state, action: PayloadAction<string | null>)=>{
			state.token = action.payload;
			passChangeTokenService.saveItem(action.payload);
		},
		clearPasswordChangeData : () => {
			registrationNumberService.removeItem();
			codeSendService.removeItem();
			passChangeTokenService.removeItem();
		}
	},
	selectors:{
		selectPasswordChangeRequester : (state) => state.registrationNumber,
		selectIsPasswordChangeCodeSend : state => state.isCodeSend,
		selectPasswordChangeToken: state => state.token,
	}
});

export const { selectPasswordChangeRequester, selectIsPasswordChangeCodeSend, selectPasswordChangeToken} = passwordChangeSlice.selectors;
export const {setPasswordChangeCodeSend, setPasswordChangeRequester, setPasswordChangeToken,clearPasswordChangeData} = passwordChangeSlice.actions;
export default passwordChangeSlice.reducer;