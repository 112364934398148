import './TravelDocumentCreateForm.css';
import React, {useState}  from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CathegoryTab from "./Tabs/CathegoryTab";
import TransportTab from "./Tabs/TransportTab";
import TransportCathegoryTab from "./Tabs/TransportCathegoryTab";
import TravelDocumentTab from "./Tabs/TravelDocumentTab";

const TravelDocumentCreateForm = ()=> {
	const [tabValue, setTabValue] = useState('10');

	const handleChange = (event : React.SyntheticEvent<Element, Event>, newValue : string) => {
		setTabValue(newValue);
	};

	return (
		<Box sx={{minHeight:'40rem'}}>
			<TabContext value={tabValue}>
				<Box sx={{borderBottom: 1, borderColor: 'divider', marginBottom:"0.0rem"}}>
					<TabList 	
						onChange={handleChange} 
						aria-label="lab API tabs example"
						centered
						variant="fullWidth"
						>
						<Tab 	
							label={"Добавление ПД"} 
							value={'10'}
							className="tabHeader"
							/>
						<Tab 	
							label="Добавление категории ПД" 
							value={'20'}
							className="tabHeader"
							/>
						<Tab 	
							label="Добавление категории транспорта" 
							value={'30'}
							className="tabHeader"
							/>
						<Tab 	
							label="Добавление транспорта" 
							value={'40'}
							className="tabHeader"
							/>
					</TabList>
				</Box>
				<TabPanel value={'10'}>
					<TravelDocumentTab/>
				</TabPanel>
				<TabPanel value={'20'}>
					<CathegoryTab/>
				</TabPanel>
				<TabPanel value={'30'}>
					<TransportCathegoryTab/>
				</TabPanel>
				<TabPanel value={'40'}>
					<TransportTab/>
				</TabPanel>
			</TabContext>
		</Box>
	);
};
export default TravelDocumentCreateForm;