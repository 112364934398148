import React, {useCallback}  from "react";
import {Box} from "@mui/material";
import {useState, useEffect} from "react";
import {DataGrid,
		ruRU,
		GridToolbar,
		GridRowModes,
		GridActionsCellItem,
		GridRowEditStopReasons,
		GridRowSelectionModel,
		GridRowEditStopParams,
		GridRowModesModel,} from "@mui/x-data-grid";
import {Edit as EditIcon,DeleteOutlined as DeleteIcon,Save as SaveIcon, Close as CancelIcon} from '@mui/icons-material';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {ruRU as pickersBgBG} from "@mui/x-date-pickers/locales";
import {ruRU as coreBgBG} from "@mui/material/locale";
import {alertStates, useAlert} from "../../../states/useAlert";
import AlertChoise from "../../../Components/AlertChoise";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import moment from "moment";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import './travelDocumentList.css';
import {useDispatch, useSelector} from "react-redux";
import {selectTDCathegories, selectTransportCathegories, selectTravelDocuments, 
		setTDAndTransportCathegories, setTravelDocuments} from "../../../features/travelDocuments/travelDocumentsSlice";
import {useNavigate} from "react-router-dom";
import {travelDocumentApi} from "../../../api";
import { TravelDocument } from "../../../app/types/TDTypes";

type TableRow = {
	documentName: string;
    documentCathegoryName: string;
    transportCathegoryName: string;
    id: number;
    createDateLabel: string;
    updated: string;
    openFromDateLabel: Date;
    expireDateLabel: Date;
    price: number;
	isNew : boolean | undefined;
};

const TravelDocumentList = () => {
	const travelDocuments = useSelector(selectTravelDocuments);
	const travelDocumentCathegories = useSelector(selectTDCathegories);
	const transportCathegories = useSelector(selectTransportCathegories);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const setTD = useCallback((travelDocs : TravelDocument[] | null)=> dispatch(setTravelDocuments(travelDocs)),[dispatch]);
	const setTDCathegoriesAndTransportCath = useCallback((data : any) => dispatch(setTDAndTransportCathegories(data)),[dispatch]);
	
	const [rows, setRows] = useState<TableRow[]>([]);
	const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
	
	const [openFromCopy, setOpenFromCopy]= useState<moment.Moment | null>(moment(new Date()).add(1,'month'));
	const [openFromUpdate, setOpenFromUpdate]= useState<moment.Moment | null>(moment(new Date()).add(1,'day'));
	const [expireUpdate, setExpireUpdate] = useState<moment.Moment | null>(moment(new Date()).add(1,'day'));
	
	const [contextMenu, setContextMenu] = React.useState<any>(null);
	const {alertStatus, setAlertStatus} = useAlert();
	const travelDocumentsQuery = travelDocumentApi.useGetAllTDQuery({});
	const TDCathegoriesQuery = travelDocumentApi.useGetTDAndTransportCathegoriesQuery({});
	const [updateTD, updateResult] = travelDocumentApi.useUpdateTravelDocumentMutation();
	const [deleteTD, deleteResult] = travelDocumentApi.useDeleteTravelDocumentsMutation();
	const [copyTD] = travelDocumentApi.useCopyTravelDocumentsMutation();

	useEffect (()=>{
		if(travelDocumentsQuery.isSuccess){
			setTD(travelDocumentsQuery.data);
		}else if(travelDocumentsQuery.isError){
			const {status, message} = travelDocumentsQuery.error;
			if (status === 204){
				setAlertStatus(true, alertStates.dangerAlert, message);
			}else{
				setAlertStatus(true, alertStates.errorAlert, message ?? 'Неизвестная ошибка получения ПД.');
			}
		}
	},[setAlertStatus, setTD, travelDocumentsQuery]);

	useEffect (()=>{
		if(TDCathegoriesQuery.isSuccess){
			const {transportCathegories, documentCathegories} = TDCathegoriesQuery.data;
			setTDCathegoriesAndTransportCath({
				transportCathegories : Object.keys(transportCathegories),
				travelDocumentCathegories : documentCathegories.map((item) => item.documentCathegoryName)
			});
		}else if(TDCathegoriesQuery.isError){
			const {status, message} = TDCathegoriesQuery.error;
			if (status === 204){
				setAlertStatus(true, alertStates.dangerAlert, message);
			}else{
				setAlertStatus(true, alertStates.errorAlert, message ?? 'Неизвестная ошибка получения ПД.');
			}
		}
	},[TDCathegoriesQuery, setAlertStatus, setTDCathegoriesAndTransportCath]);

	useEffect(()=>{
		if(updateResult.isError || deleteResult.isError){
			setTD(null);
			setAlertStatus(true, alertStates.errorAlert, updateResult?.error?.message ?? deleteResult?.error?.message);
		}
	},[updateResult, deleteResult, setTD, setAlertStatus]);

	useEffect(()=>{
		if(travelDocuments)
			setRows(formatDocsForTable(travelDocuments));
	},[travelDocuments]);

	function formatDocsForTable (travelDocuments : Array<TravelDocument> = []) : TableRow[] {
		return  travelDocuments.map((document) => ({
						documentName: document.documentName,
						documentCathegoryName: document.documentCathegoryName,
						transportCathegoryName : document.transportCathegoryName ,
						id : document.travelDocumentId, 
						createDateLabel : moment( document.created).format('DD.MM.YY'),
						updated : moment(document.updated).format('DD.MM.YY HH:mm'), 
						openFromDateLabel : new Date(document.openFromDate),
						expireDateLabel : new Date(document.expireDate),
						price : document.price / 100,
						isNew : undefined
				}) 
		) ?? [];
	}

	const handleContextMenu = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.preventDefault();
		setContextMenu(
			contextMenu === null
			? 
				{
					mouseX: event.clientX + 2,
					mouseY: event.clientY - 6,
				}
			:
				null,
		);
	};

	const handleClose = () => {
		setAlertStatus(false);
		setContextMenu(null);
	};

	const theme = createTheme(
		{
			palette: {
				primary: {main: "#1976d2"},
			},
		},
		ruRU, // x-data-grid translations
		pickersBgBG, // x-date-pickers translations
		coreBgBG, // core translations
	);

	const handleEditClick = (id : number) => () => {
		setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
	};

	const handleSaveClick = (id : number) => () => {
		setAlertStatus(false);
		setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
	};

	const handleDeleteClick = (id : number) => () => {
		setAlertStatus(false);
		deleteTD([id]);
		setRows(rows.filter((row) => row.id !== id));
	};

	const handleCancelClick = (id : number) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: {mode: GridRowModes.View, ignoreModifications: true},
	});

	const editedRow = rows.find((row) => row.id === id);
		if (editedRow?.isNew) {
			setRows(rows.filter((row) => row.id !== id));
		}
	};

	const handleRowEditStop = (params : GridRowEditStopParams<any>) => { //,event
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			//event.defaultMuiPrevented = true;
		}
	};

	const toSqlDatetime = (inputDate : string | Date) => {
		const date = new Date(inputDate);
		const dateWithOffest = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
		return dateWithOffest
			.toISOString()
			.slice(0, 19)
			.replace('T', ' ');
	};
	
	const processRowUpdate = (newRow : TableRow) => {
		setAlertStatus(false);
		console.log(newRow.price);
		console.log(newRow.price*100);
		
		
		console.log(Math.floor(newRow.price * 100));
		console.log(Math.ceil(newRow.price * 100));
		console.log(Math.round(newRow.price * 100));

		updateTD({...newRow, expireDate : toSqlDatetime (newRow.expireDateLabel), 
			openFromDate : toSqlDatetime(newRow.openFromDateLabel), 
				price : Math.round(newRow.price * 100), travelDocumentId : newRow.id});
		const updatedRow = {...newRow, isNew: false};
		setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
		return updatedRow;
	};

	const handleRowModesModelChange = (newRowModesModel : GridRowModesModel) => {
		setRowModesModel(newRowModesModel);
	};
	const handleDeleteItems = () =>{
		setAlertStatus(false);
		handleClose();
		if(rowSelectionModel.length===0)
			return;
		deleteTD(rowSelectionModel);
		setRows(rows.filter((row) => !rowSelectionModel.includes(row.id)));
	};
	const handleCopyItems = () =>{
		setAlertStatus(false);
		handleClose();
		if(rowSelectionModel.length===0)
			return;
		copyTD({travelDocumentsIdList : rowSelectionModel,});
	};
	const handleCopyWithSetOpenTime = (event : React.MouseEvent<HTMLLIElement, MouseEvent>) =>{
		setAlertStatus(false);
		const {localName} = event.currentTarget;
		if(localName !== 'li')
			return;
		if (event.currentTarget !== event.target) return;
		handleClose();
		if(rowSelectionModel.length===0)
			return;
		copyTD({ 
				travelDocumentsIdList : rowSelectionModel,
				copyOptions :{
					openFromDate : openFromCopy?.format('YYYY-MM-DD HH:mm:ss'), 
					expireDate : openFromCopy?.add(1,'year').format('YYYY-MM-DD HH:mm:ss')
				}
		});
		setRowSelectionModel([]);
	};

	const handleSetUnactualTravelDocuments = ()=>{
		handleClose();
		if(rowSelectionModel.length===0)
			return;
		const newDate = (new Date());
		newDate.setFullYear(newDate.getFullYear()-1);
		updateTD({travelDocumentId : rowSelectionModel ,expireDate : toSqlDatetime (newDate)});
		setRows(rows.map((row) => (rowSelectionModel.includes(row.id) ? {...row, expireDateLabel: newDate} : row)));
		setRowSelectionModel([]);
	};
	const handleChangeOpenFrom = (event : React.MouseEvent<HTMLLIElement, MouseEvent>)=>{
		const {localName} = event.currentTarget;
		if (event.currentTarget !== event.target) return;
		if(localName !== 'li')
			return;
		handleClose();
		if(rowSelectionModel.length===0)
			return;
		updateTD({travelDocumentId : rowSelectionModel ,openFromDate : toSqlDatetime (openFromUpdate?.format('YYYY-MM-DD HH:mm:ss') ?? "")});
		setRows(rows.map((row) => (rowSelectionModel.includes(row.id) ? {...row, openFromDateLabel: openFromUpdate?.toDate() ?? new Date()} : row)));
		setRowSelectionModel([]);
	};
	const handleExpireUpdate = (event: React.MouseEvent<HTMLLIElement, MouseEvent>)=>{
		const {localName} = event.currentTarget;
		if (event.currentTarget !== event.target) return;

		if(localName !== 'li')
			return;
		handleClose();
		if(rowSelectionModel.length===0)
			return;
		updateTD({travelDocumentId : rowSelectionModel ,expireDate : toSqlDatetime (expireUpdate?.format('YYYY-MM-DD HH:mm:ss')??'')});
		setRows(rows.map((row) => (rowSelectionModel.includes(row.id) ? {...row, expireDateLabel: expireUpdate?.toDate() ?? new Date()} : row)));
		setRowSelectionModel([]);
	};

	const handleEditTravelDocument = () =>{
		if(rowSelectionModel.length !== 1)
			return;
		navigate('/travelDocument/edit/', {state : {travelDocId : rowSelectionModel[0]}});
	};

	const columns = [
		{field: "id", headerName: "ID", flex : 0.05, editable : false},
		{field: "documentName", headerName: "Название", flex:0.2, editable : true},
		{
			field: "price", 
			headerName: "Цена",
			type : 'number', 
			flex:0.05, 
			editable : true
		},
		{
			field: "documentCathegoryName", 
			headerName: "Категория", 
			type: 'singleSelect',
			valueOptions: travelDocumentCathegories ?? [],
			flex:0.12, 
			editable : true
		},
		{
			field: "transportCathegoryName", 
			headerName: "Тип транспорта",
			type: 'singleSelect',
			valueOptions: transportCathegories ?? [],
			flex:0.12, 
			editable : true
		},
		{
			field: "openFromDateLabel", 
			headerName: "Доступен с", 
			type: 'dateTime',
			flex:0.09, 
			editable : true
		},
		{
			field: "expireDateLabel", 
			headerName: "По",
			type: 'dateTime', 
			flex:0.09, 
			editable : true
		},
		// {field: "updated", headerName: "Изменен", flex:0.07},
		{field: "createDateLabel", headerName: "Создан", flex:0.07, editable : false},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Действия',
			flex : 0.07,
			cellClassName: 'actions',
			getActions: ({id}:{id:number}) => {
				const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
				if (isInEditMode) {
				return [
					<GridActionsCellItem
					icon={<SaveIcon />}
					label="Save"
					sx={{
						color: 'primary.main',
					}}
					key= {id}
					onClick={handleSaveClick(id)}
					/>,
					<GridActionsCellItem
					icon={<CancelIcon />}
					key= {id}
					label="Cancel"
					className="textPrimary"
					onClick={handleCancelClick(id)}
					color="inherit"
					/>,
				];
				}
		
				return [
				<GridActionsCellItem
					icon={<EditIcon />}
					label="Edit"
					key= {id}
					className="textPrimary"
					onClick={handleEditClick(id)}
					color="inherit"
				/>,
				<GridActionsCellItem
					icon={<DeleteIcon />}
					label="Delete"
					key= {id}
					onClick={handleDeleteClick(id)}
					color="inherit"
				/>,
				];
			},
		},
	];


	return (
		<Box sx={{margin:"auto", width:"100%", textAlign:"center"}}>
			<Box sx={{marginBottom:"3rem"}}>
				<h1>
					Cписок проездных документов
				</h1>
				<h5>
					для изменения данных нужного документа выберите его и нажмите соответствующую кнопку
				</h5>
			</Box>
			<ThemeProvider theme={theme} >
				<Box 
					onContextMenu={handleContextMenu} 
					style={{cursor: 'context-menu'}}
					>
					<DataGrid  
						slots={{
							toolbar: GridToolbar
						}}
						autoHeight
						columns={columns} 
						rows = {rows} 
						checkboxSelection
						disableRowSelectionOnClick
						editMode="row"
						rowModesModel={rowModesModel}
						onRowModesModelChange={handleRowModesModelChange}
						onRowEditStop={handleRowEditStop}
						processRowUpdate={processRowUpdate}							
						rowSelectionModel={rowSelectionModel}
						onRowSelectionModelChange={setRowSelectionModel}
						pageSizeOptions={[5, 10, 25,50]}
						initialState={{
							pagination: {paginationModel: {pageSize: 10}},
						}}
						/>
				</Box>
			</ThemeProvider>
			
			{/* <Box sx = {{textAlign:"center", marginTop:"0.5rem"}}>
				<FormControlLabel 	
					label={"Показать только актуальные проездные"}
					control={<Switch name="showActual"/>} 
					componentsProps={{
						typography : {className:"switchTD"}
					}}
					style={{margin:"auto"}}
					/>
			</Box> */}
			<AlertChoise {...alertStatus}/>
			<Menu
				open={contextMenu !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
				contextMenu !== null
					? {top: contextMenu.mouseY, left: contextMenu.mouseX}
					: undefined
				}
				>
				<MenuItem 
					className="TDLmenuItem" 
					onClick={handleDeleteItems}
					>
					Удалить
				</MenuItem>
				<MenuItem 
					className="TDLmenuItem" 
					onClick={handleCopyItems}
					>
					Создать копию
				</MenuItem>
				<MenuItem 
					className="TDLmenuItem" 
					onClick={handleSetUnactualTravelDocuments}
					>
					Сделать неактуальными
				</MenuItem>
				<MenuItem 
					className="TDLmenuItem" 
					onClick={handleEditTravelDocument}
					>
					Редактировать документ
				</MenuItem>
				<MenuItem 
					className="TDLmenuItem" 
					onClick={handleCopyWithSetOpenTime}
					>
					Создать копию актуальную с 
					<LocalizationProvider 
						dateAdapter={AdapterMoment} 
						adapterLocale="ru" 
						>
						<DateTimePicker 
							disablePast
							value={openFromCopy}
							onChange={setOpenFromCopy}
							sx={{
								'.MuiInputBase-input': {
									paddingTop :"0.5rem ",
									paddingBottom :"0.5rem"
								},
								width : "fit-content",
								marginLeft:"0.5rem",
								alignSelf: "flex-end",
								zIndex:3,
							}}
							/>
					</LocalizationProvider>
				</MenuItem>
				<MenuItem 
					className="TDLmenuItem" 
					onClick={handleChangeOpenFrom}
					>
					Сделать доступным с  
					<LocalizationProvider 
						dateAdapter={AdapterMoment} 
						adapterLocale="ru" 
						>
						<DateTimePicker 
							value={openFromUpdate}
							onChange={setOpenFromUpdate}
							sx={{
								'.MuiInputBase-input': {
									paddingTop :"0.5rem ",
									paddingBottom :"0.5rem"
								},
								width : "fit-content",
								marginLeft:"0.5rem",
								alignSelf: "flex-end",
							}}
							/>											
					</LocalizationProvider>
				</MenuItem>
				<MenuItem 
					className="TDLmenuItem" 
					onClick={handleExpireUpdate}
					>
					Установить срок дейтсвия до   
					<LocalizationProvider 
						dateAdapter={AdapterMoment} 
						adapterLocale="ru" 
						>
						<DateTimePicker 
							value={expireUpdate}
							onChange={setExpireUpdate}
							sx={{
								'.MuiInputBase-input': {
									paddingTop :"0.5rem ",
									paddingBottom :"0.5rem"
								},
								width : "fit-content",
								marginLeft:"0.5rem",
								alignSelf: "flex-end",
							}}
							/>
					</LocalizationProvider>
				</MenuItem>

			</Menu>
		</Box>
	);
};
export default TravelDocumentList;