import {useState}  from "react";
import {FormControl, MenuItem, Select as SelectMUI, Tooltip, SelectProps as SelectMUIProps} from "@mui/material";
import {SxProps, styled} from '@mui/material/styles';
import {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';
import { SelectOption } from "../../app/types/selectTypes";

let origProps : SelectMUIProps<string | number>;
type SelectProps = {
	selectItems : SelectOption[],
	selectVal? : string | null,
	selectOnChange? : typeof origProps.onChange | null,
	disabled? : boolean,
	toolTips? : Map<string, string>,
	required? : boolean,
	name? : string,
	size? : 'small' | 'medium',
	variant? : "standard" | "outlined" | "filled" | undefined,
	sx? : SxProps
}

const Select = ({selectItems = [], selectVal = null, selectOnChange = null, disabled = false, toolTips = new Map(), required = false, 
					name = "", size = "small", variant = "standard", sx = {m: 1, minWidth: 120}} : SelectProps)=> {
	const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
		))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			color: 'rgba(0, 0, 0, 0.87)',
			boxShadow: theme.shadows[1],
			fontSize: 11,
		},
		}));
	
	const [selectChooseItem, setSelectChooseItem]  = useState('');
	return (
		<>
			<FormControl 
				variant= {variant} 
				size={size} 
				disabled = {disabled} 
				required = {required}  
				sx={{
					...sx, 
					maxWidth:"90%"
				}} 
				>
				<SelectMUI 
						labelId="selectTravelDocTypeLabel"
						id = "selectTravelDocType"
						value={selectVal ?? selectChooseItem}
						onChange={ selectOnChange ?? (event=>setSelectChooseItem(event.target.value))}
						name= {name}
						>
						{
							selectItems.map((item) => {
									if(!toolTips.get(item.selectLabel)){
										return (
											<MenuItem 
												value = {item.id} 
												key = {item.id}
												>
												{item.selectLabel}
											</MenuItem>
										);
									}else{
										return(
											<MenuItem 
												value = {item.id} 
												key = {item.id}
												>
												<LightTooltip	
													className="selectItem"
													title = {toolTips.get(item.selectLabel)} 
													disableInteractive
													placement="top"
													>
													<span>{item.selectLabel}</span>
												</LightTooltip >
											</MenuItem>
										);
									}
								})
						}
				</SelectMUI>
			</FormControl>
		</>
	);
};

export default Select;

export function stringArrayToSelectItems (	array : string[], 
											itemToId : ((item: string) => string) | undefined = undefined,
											itemToLabel = (item: string | object) => String(item))  : Array<SelectOption> {
	return array.map((item, index) => ({id : itemToId ? itemToId(item) : String(index), selectLabel : itemToLabel(item)}));
}

export function createTooltips(rawData : object){
	let tooltips = new Map();
	for (let key in rawData){
		tooltips.set(key,( rawData[key as keyof object] as string[]).join(', '));
	}
	return tooltips;
}