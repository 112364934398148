export default class SessionService<Type = any> {
	private readonly _itemName : string = "";
	private _defaultValue : Type | null = null;
	constructor(itemName : string, defaultValue : Type | null){
		this._itemName = itemName;
		this._defaultValue = defaultValue;
	}
	getItem() : Type | null{
		const itemFromSession = sessionStorage.getItem(this._itemName);
		if(itemFromSession)
			return JSON.parse(itemFromSession);
		else{
			sessionStorage.setItem(this._itemName, JSON.stringify(this._defaultValue ));
			return this.getItem();
		}
	}
	removeItem(){
		sessionStorage.removeItem(this._itemName);
	}
	saveItem(itemNew : Type | null){
		sessionStorage.setItem(this._itemName, JSON.stringify(itemNew));
	}
}