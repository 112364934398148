import {Chip, Collapse, Paper} from "@mui/material";
import React from "react";
import {Col, Row} from "react-bootstrap";
import styled from "styled-components";
import CallIcon from '@mui/icons-material/Call';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {grey} from "@mui/material/colors";
import {TransitionGroup} from "react-transition-group";

const ChipInput = styled.input`
	width : 100%;
	/* 6.85vw; */
	border : none;   
	outline: 0;
	outline-offset : 0;
	background-color : ${grey[200]};
	&:focus {
		background-color : #fff;
		border-radius : 6px;
	}
`;
const inputDefProps = {
	placeholder : '(+375 17) 000-00-00', 
	required : true,
	type : 'tel',
	pattern : "[\\+\\d\\-\\(\\) ]{7,20}",
	minLength : 7,
	maxLength : 24,
};
export type PhoneItem = {
	id : number,
	phone : string,
	action : 'delete' | 'insert' | 'update' | 'add'
}

type ContactInputProps ={
	label : React.ReactNode
	phoneNumbers : Array<PhoneItem>,
	setPhoneNumbers : (newPhones : Array<PhoneItem>) => void,
	disabled? : boolean,
	maxCount? : number
}

const ContactsInput = ({label, phoneNumbers, setPhoneNumbers, disabled = false, maxCount = 3} : ContactInputProps)=> {
	const notDeletedLength = (phoneNumbers :  Array<PhoneItem>) => phoneNumbers.reduce((length, item) => length += item.action !== 'delete' ? 1:0 ,0);
	const phonesChips = ()=>([
		...phoneNumbers.filter(number=> number.action !== 'delete').map((number) =>  ({
											key : number.id, 
											label : <ChipInput 
														readOnly={disabled} 
														{...inputDefProps} 
														value={number.phone} 
														onChange = {(e : React.ChangeEvent<HTMLInputElement>) => handleChange(e, number.id)}
														/>
									}))]);
	function handleChange (e : React.ChangeEvent<HTMLInputElement> , id : number){
		const newValue = e.target.value;
		setPhoneNumbers(phoneNumbers.map(number => number.id === id ? {...number,phone: newValue} : number));
	}
	const handleDelete = (deleteId : number) => () => {
		const deleteItem = phoneNumbers.find((item) => item.id === deleteId);
		if(deleteItem?.action === 'insert'){
			setPhoneNumbers(phoneNumbers.filter((phone) => phone.id !== deleteId));
		}else{
			setPhoneNumbers(phoneNumbers.map((phone) => phone.id !== deleteId ? phone : {...phone, action : 'delete'}));
		}
	};
	const  handleAdd = () => {
		if( notDeletedLength(phoneNumbers) >=maxCount){
			return;
		}
		setPhoneNumbers([...phoneNumbers,{id : phoneNumbers.reduce((max,number)=> max > number.id ? max : number.id,0)+1, phone:'', action : 'insert'}]);
	};

	return (
		<Row>
			<Col 	
				md ={6}
				className = {`formInputLabel`}
				style={{margin:"auto"}}
				>
				{label}		
			</Col>
			<Col 	
				md ={6}
				className = {`formInputContainer`}
				style={{margin:"auto"}}
				>	
				<Paper
					sx={{
						p: 0.5,
						m: 0,
					}}
					>	
					<TransitionGroup 
						style={{
							display: 'flex',
							justifyContent: 'left',
							flexWrap: 'wrap',
							justifyItems:'space-between',
						}}
						>
						{
							phonesChips().map((data, index) => {
								return (
									<Collapse 
										key={data.key} 
										orientation="horizontal"
										sx={{
											maxWidth: '31%',
											marginRight: index % 3 !== 2 ? '0.5vw' : 0,
											marginBlock: '0.25vh',	
										}}
										>
										<Chip
											disabled={disabled}
											icon={<CallIcon/>}
											label={data.label}
											onDelete = {handleDelete(data.key)}
											/>
									</Collapse>
								);	
							})
						}
						{
							notDeletedLength(phoneNumbers) < maxCount &&
							<Collapse 
								orientation="horizontal"
								sx={{
									maxWidth: '32%',
									marginBlock: '0.25vh',	
								}}
								>
								<Chip
									disabled={disabled}
									label={<AddCircleIcon color="success"/>}
									onClick = { handleAdd}
									/>					
							</Collapse>
							
						}
					</TransitionGroup>
				</Paper>
			</Col>
		</Row>
	);
};

export default ContactsInput;


