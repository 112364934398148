import './TravelDocumentTableForm.css';
import {Container, Row} from "react-bootstrap";
import React from "react";
import {Collapse} from "@mui/material";
import {TransitionGroup} from "react-transition-group";
import TravelDocumentTableHeader from "../travelDocumentFormComponents/TravelDocumentTableHeader";
import TravelDocumentTableItem from "../travelDocumentFormComponents/TravelDocumentTableItem";
import TravelDocumentTabelAddRowButton from "../travelDocumentFormComponents/TravelDocumentTabelAddRowButton";
import TravelDocumentTableFooter from "../travelDocumentFormComponents/TravelDocumentTabelFooter";
import {addTableItem, selectTableItems} from "../../../../features/contract/contractSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";

const TravelDocumentTableForm = () => {
	const itemsData = useAppSelector(selectTableItems);
	const dispatch = useAppDispatch();
	const addItem = () => dispatch(addTableItem());

	const renderItems = ()=>{
		let items = [];
		for(let i = 0; i < itemsData.length; i++){
			if(itemsData[i].isDeleting)
				continue;
			const {number} = itemsData[i];
			items.push( 
			<Collapse key = {number}>
				<TravelDocumentTableItem 	
					showNumber = {String(i+1)}
					itemNumber = {number}
				/>
			</Collapse>
			);
		}
		return items;
	};

	return (
		<>
			<Row>
				<h5>
					Наименование и количество приобретаемых товаров
				</h5>
			</Row>
			<Container className="travelDocFormContainer">
				<TravelDocumentTableHeader/>
				<TransitionGroup>
				{renderItems()}
				</TransitionGroup>
				<TravelDocumentTabelAddRowButton onClick={()=>addItem()}/>
				<TravelDocumentTableFooter/>
			</Container>
		</>
	);
};
export default TravelDocumentTableForm;