import * as React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
 
type ProtectedRouteProps = {
	isAllowed : boolean,
	redirectPath : string,
	redirectMessage : string | null
};

const ProtectedRoute = ({
  isAllowed,
  children,
  redirectPath = '/',
  redirectMessage = null
} : React.PropsWithChildren<ProtectedRouteProps>) => {
	
	const location = useLocation();
	if (!isAllowed && redirectMessage){
		return <Navigate to={'/notFound'} state={{from: location, message : redirectMessage}} replace />;
	}
	if (!isAllowed){
		return <Navigate to={redirectPath} state={{from: location, message : redirectMessage}} replace />;
	}
	return children ?? <Outlet />;
};
export default ProtectedRoute;