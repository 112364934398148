import {Button} from "@mui/material";
import "./RegistrationSuccess.css";

const RegistrationSuccessPage = ()=> {
	return (
		<div className='registrationSuccessContainer'>
			<h2 className='registrationSuccessHeader'>
				Регистрация успешно завершена.
			</h2>
			<p className='registrationSuccessText'> 
				Электронная почта потверждена. Для оформления заявки авторизуйтесь в системе.
			</p>
			<a href='/login'>
				<Button 
					variant="contained" 
					className="submitReg" 
					color="success"
					>
					Войти в систему
				</Button>
			</a>
		</div>
	);};

export default RegistrationSuccessPage;