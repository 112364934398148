import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import SessionService from "../../services/sessionService";
const codeSendSessionItemName =  "isActivationCodeSend";
const regNumberSessionItemName = "activationCodeRequester";
const codeSendService = new SessionService<boolean>(codeSendSessionItemName, false);
const registrationNumberService = new SessionService<string>(regNumberSessionItemName, null);

type ActivationState  = {
	isCodeSend : boolean | null,
	registrationNumber : string | null
};

const initialState : ActivationState  = {
	isCodeSend : codeSendService.getItem(),
	registrationNumber : registrationNumberService.getItem(),
} ;

export const activationSlice = createSlice({
	name : "activation",
	initialState,
	reducers : {
		onCodeSendChanged : (state, action : PayloadAction<boolean>) =>{
			const value = action.payload;
			if(value !== true && value  !== false){
				return;
			}
			state.isCodeSend = value ;
			codeSendService.saveItem(value);
		},
		onRegistrationNumberChanged : (state, action : PayloadAction<string | null>) =>{
			state.registrationNumber = action.payload;
			state.isCodeSend = false;
			codeSendService.saveItem(false);
			registrationNumberService.saveItem(action.payload);
		},
		onClearActivationData : () => {
			registrationNumberService.removeItem();
			codeSendService.removeItem();
		}
	},
	selectors :{
		codeSendSelector : (state) => state.isCodeSend,
		activatingRegNumberSelector : state => state.registrationNumber,
	}
});

export const {onCodeSendChanged, onRegistrationNumberChanged, onClearActivationData} = activationSlice.actions;
export const {codeSendSelector, activatingRegNumberSelector} = activationSlice.selectors;
export default activationSlice.reducer;