import {MDBIcon} from "mdb-react-ui-kit";
import React from "react";

type ContactsItemProps ={
	icon : string,
	itemText : string | React.ReactNode
}

const ContactsItem = ({icon = "phone", itemText =""} : ContactsItemProps) =>{
	return (
		<p style={{marginBottom:'1vh'}}>
			<MDBIcon 
				icon= {icon} 
				className="me-2" 
				/>
			{itemText}
		</p>
	);
};

export default ContactsItem;