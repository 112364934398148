/* eslint-disable react/prop-types */
import React from "react";
import {Paper} from "@mui/material";

import Grid from '@mui/material/Unstable_Grid2';

const TableHeader = () => {
	return (
		<Paper elevation={2} className="travelDocFormHeader row">
			<Grid container alignItems={'center'} justifyContent={'space-between'}>
				<Grid xs = {1} className="travelDocFormHeaderItem ItemBorder"> <span>№</span></Grid>
				<Grid xs = {6}  className="travelDocFormHeaderItem ItemBorder">Наименование</Grid>
				<Grid	xs = {1} 
						className="travelDocFormHeaderItem ItemBorder">
						Единицы измерения
				</Grid>
				<Grid	xs = {1} 
						className="travelDocFormHeaderItem ItemBorder">
						Количество
				</Grid>
				<Grid 	xs = {1} 
						className="travelDocFormHeaderItem ItemBorder  textSmall"
						>
						Цена, руб.
				</Grid>
				<Grid 	xs = {1} 
						className="travelDocFormHeaderItem ItemBorder textSmall"
						>
						Сумма, руб.
				</Grid>
				<Grid xs = {1} className="travelDocFormHeaderItem ItemBorder textSmall">В т.ч. НДС (20%), руб.</Grid>
			</Grid>
		</Paper>
	);
};
const TableFooter = ({totalPrice, totalCount, totalVAT}) => {
	const showNumber = (num) => num ? num : '-';
	return (
		<Paper elevation={2} className="travelDocFormFooter row">
			<Grid container alignItems={'center'} justifyContent={'space-between'}>
				<Grid xs = {1} className="travelDocFormFooterItem "><strong>Всего</strong></Grid>
				<Grid xs = {7} className="travelDocFormFooterItem ItemBorder"> </Grid>
				<Grid xs = {1} className="travelDocFormFooterItem ItemBorder"><strong>{showNumber(totalCount)}</strong></Grid>
				<Grid xs = {1} className="travelDocFormFooterItem ItemBorder"></Grid>
				<Grid xs = {1} className="travelDocFormFooterItem ItemBorder"> <strong>{showNumber(totalPrice)}</strong> </Grid>
				<Grid xs = {1} className="travelDocFormFooterItem ItemBorder"> <strong>{showNumber(totalVAT)}</strong> </Grid>
				<Grid xs = {1} className="travelDocFormFooterItem ItemBorder"></Grid>
		</Grid>
		</Paper>
	);
};

const TableItems = ({items}) => {
	return items.map ( (item, index) => 
		(<Paper className="travelDocFormItemRow row" elevation={3} key={index}>
			<Grid   container 
					justifyContent="center"
					alignItems="center">
				<Grid 	xs = {1} className="travelDocFormItemCol"><strong>{index + 1}</strong></Grid>
				<Grid 	xs = {6}  className="travelDocFormItemCol ">{item.name}</Grid>
				<Grid 	xs = {1} className="travelDocFormItemCol ">{item.units}</Grid>
				<Grid 	xs = {1} className="travelDocFormItemCol ">{item.count}</Grid>
				<Grid 	xs = {1} className="travelDocFormItemCol ">{(item.price/100)?.toFixed(2) ?? '-'}</Grid>
				<Grid 	xs = {1} className="travelDocFormItemCol ">{(item.price * item.count/100)?.toFixed(2) ?? '-'}</Grid>
				<Grid 	xs = {1}  className="travelDocFormItemCol ">{(item.price * item.count/100/6)?.toFixed(2) ?? '-'}</Grid>
			</Grid>
		</Paper>)
	);
};

const TravelDocumentsDisplayTable = ({travelDocuments}) => {
	const totalCount = travelDocuments.reduce((prev, curr) => prev += curr.count ?? 0, 0);
	const totalPrice = (travelDocuments.reduce((prev, curr) => prev += curr.price * curr.count ?? 0, 0)/100)?.toFixed(2);
	const totalVAT = (travelDocuments.reduce((prev, curr) => prev += Number((curr.price*curr.count/6/100).toFixed(2)) ?? 0, 0))?.toFixed(2);

	return (
		<>
			<TableHeader/>
			<TableItems items = {travelDocuments}/>
			<TableFooter 	totalCount = {totalCount} 
							totalPrice = {totalPrice}
							totalVAT={totalVAT}/>
		</>
	);
};
export default TravelDocumentsDisplayTable;