import {Backdrop, CircularProgress, BackdropProps} from "@mui/material";

interface LoadingBackdropProps{
	open : boolean,
	backdropProps? : BackdropProps
}

const LoadingBackdrop = ({open, backdropProps = {open : open}}: LoadingBackdropProps)=>{
	return (
		<Backdrop
			sx={{
				color: '#fff',
				 zIndex: (theme) => theme.zIndex.drawer + 1
			}}
			{...backdropProps}
			open={open}
			>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
};

export default LoadingBackdrop;