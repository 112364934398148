import "./NotFoundPage.css";
import {Button} from "@mui/material";
import {Link, useLocation} from "react-router-dom";

const NotFoundPage = ()=> {
	const location = useLocation();
	const message = location.state?.message;

	return (
		<div className='notFoundContainer'>
			<h1 className='notFoundHeader' >
				404
			</h1>
			<h2 className='notFoundUnderHeader'>
				Страница не найдена
			</h2>
			<p className='notFoundText'> 
				{message ?? "Запрошенная страница не найдена, перемещена либо не доступна"}
			</p>
			<div className=''>
				<Link to = "/">
					<Button 
						type ="submit"
						className="notFoundButton"
						color = "success"
						variant="contained"
						>
						Вернуться на главную
					</Button>
				</Link>
			</div>
		</div>
	);
};
export default NotFoundPage;