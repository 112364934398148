import http, { fileHttp } from "../http-common";
import qs from "qs";

export default class QueryService {
	response = (status, message, values = null) => {
		if(!values){
			return {status, message};
		}else{
			return  {status, message, values};
		}
	};

	errResponse (err, defaultMessage = 'Неизвестная ошибка', defaultCode = 500){
		const message = err?.response?.data?.values?.message ?? err?.response?.statusText ?? defaultMessage,
				code = err?.response?.data?.status ?? err?.response?.status ?? defaultCode;
		return this.response(code, message);
	}

	postOnServerAuthorized(token, apiPath,sendData, userConfig = null) {
		const config = userConfig ?? { 
			headers:{
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization" : token
			}
		};
		if(userConfig && !userConfig.headers.Authorization){
			config.headers.Authorization = token;
		}
		let data = qs.stringify({
			...sendData
		});

		return http.post(apiPath,data, config
		).then(((res,err) => {
			const status = res.data;
			if(res.status === 204){
				return this.response(204, 'No content');
			}
			if(err){
				return this.response(status.status, status.values.message);
			}else{
				return this.response(status.status, status.values.message, status.values);
			}
		}));
	}

	postOnServer(apiPath,sendData, userConfig = null) {
		const config = userConfig ?? { 
			headers:{
				"Content-Type": "application/x-www-form-urlencoded"
			}
		};
		let data = qs.stringify({
			...sendData
		});

		return http.post(apiPath,data, config
		).then((res,err) => {
			const status = res.data;
			if(res.status === 204){
				return this.response(204, 'No content');
			}
			if(err){
				return this.response(status.status, status.values.message);
			}else{
				return this.response(status.status, status.values.message, status.values);
			}
		});
	}

	getFromServer(apiPath,sendData, userConfig = null){
		const config = userConfig ?? { 
			params : sendData
		};
		return http.get(apiPath, config,
		).then((res,err) => {
			const resData = res.data.values;
			if(res.status === 204){
				return this.response(204, 'No content');
			}
			if(err){
				return this.response(res.status, resData.message);
			}else{
				return this.response(res.status, resData.message, resData);
			}
		});
        
	}
	getFromServerAuthorized(token, apiPath, sendData, userConfig = null){
		const config = userConfig ?? { 
			params : sendData,
			headers:{
				"Authorization" : token
			}
		};
		if(userConfig && !userConfig.params){
			config.params = sendData;
		}
		if(userConfig && !userConfig.headers.Authorization){
			config.headers.Authorization = token;
		}

		return http.get(apiPath, config,
		).then(((res,err) => {
			const resData = res.data.values;
			if(res.status === 204){
				return this.response(204, 'No content');
			}
			if(err){
				return this.response(res.status, resData.message);
			}else{
				return this.response(res.status, resData.message, resData);
			}
		})); 
	}

	async getFromServerAsync(apiPath,sendData, userConfig = null){
		const config = userConfig ?? { 
			params : sendData
		};
		return await http.get(apiPath, config,
		).then(
				(res,err) => {
			const resData = res.data.values;
			if(res.status === 204){
				return this.response(204, 'No content');
			}
			if(err)
				return this.response(res.status, resData.message);
			else
				return this.response(res.status, resData.message, resData);
			
		}).catch(err => {
			console.log(err?.response ?? err);
			return this.errResponse(err);
		});
	}

	async getFromServerAuthorizedAsync(token, apiPath, sendData, userConfig = null){
		const config = userConfig ?? { 
			params : sendData,
			headers:{
				"Authorization" : token
			}
		};
		if(userConfig && !userConfig.params){
			config.params = sendData;
		}
		if(userConfig && !userConfig.headers.Authorization){
			config.headers.Authorization = token;
		}

		return await http.get(apiPath, config,
		).then(((res,err) => {
			const resData = res.data.values;
			if(res.status === 204){
				return this.response(204, 'No content');
			}
			if(err){
				return this.response(res.status, resData.message);
			}else{
				return this.response(res.status, resData.message, resData);
			}
		})).catch(err => {
			console.log(err?.response ?? err);
			return this.errResponse(err);
		}); 
	}

	async postOnServerAsync(apiPath,sendData, userConfig = null) {
		const config = userConfig ?? { 
			headers:{
				"Content-Type": "application/x-www-form-urlencoded"
			}
		};
		let data = qs.stringify({
			...sendData
		});

		return await http.post(apiPath,data, config
		).then((res,err) => {
			const status = res.data;
			if(res.status === 204){
				return this.response(204, 'No content');
			}
			if(err){
				return this.response(status.status, status.values.message);
			}else{
				return this.response(status.status, status.values.message, status.values);
			}
		})
		.catch(err => {
			console.log(err?.response ?? err);
			return this.errResponse(err);
		});
	}

	async postOnServerAuthorizedAsync(token, apiPath,sendData, userConfig = null) {
		const config = userConfig ?? { 
			headers:{
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization" : token
			}
		};
		if(userConfig && !userConfig.headers.Authorization){
			config.headers.Authorization = token;
		}
		let data = qs.stringify({
			...sendData
		});

		return http.post(apiPath,data, config
		).then((res,err) => {
			const status = res.data;
			if(res.status === 204){
				return this.response(204, 'No content');
			}
			if(err){
				return this.response(status.status, status.values.message);
			}else{
				return this.response(status.status, status.values.message, status.values);
			}
		})
		.catch(err => {
			console.log(err?.response ?? err);
			return this.errResponse(err);
		});
	}

	getFileFromServer(token, queryString, fileName){
		let anchor = document.createElement("a");
		document.body.appendChild(anchor);
		return fileHttp.get(queryString,{
			headers: {
				"Authorization": token
			},
			responseType : 'blob'
		})
			.then(response => {
				console.log(response);
				if(response.status !== 200){
					// eslint-disable-next-line no-throw-literal
					throw {status : response.status, message : response?.message ?? "Ошибка скачивания файла"};
				}
				return response.data;
			})
			.then( blobby => {
				let objectUrl = window.URL.createObjectURL(blobby);
				anchor.href = objectUrl;
				anchor.download = fileName;
				anchor.click();
				window.URL.revokeObjectURL(objectUrl);
			})
			.finally(()=>{document.body.removeChild(anchor);});
	}
}