import {Typography} from "@mui/material";
import "./LifetimeForm.css";
import React from "react";
import LifetimeLabel from "./LifetimeLabel";
// import Select from "../../Selects/Select";
// import {useState} from "react";
// import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
// import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
// import 'moment/locale/ru';
// import moment from "moment";
export type LifetimeFormProps ={
	travelDocument? : {
		lifetimeDays? : number | string,
		lifetimeDescription : string,
		calendarLifetime? : "Сутки" | "Неделя" | "Декада" | "Месяц" | "Год"
	}
}
const LifetimeForm = ({travelDocument} : LifetimeFormProps) =>{
	// const [periodSelect, setPeriodSelect] = useState('');

	// const periodChanged = (event)=>{
	// 	setPeriodSelect(event.target.value);
	// };
	if(!travelDocument){
		return (
			<>
			<Typography>Выберите наименование товара</Typography>
			</>
		);
	}else{
		// const SelectPeriod = ({calendarLifetime}) =>{
		// 	if(calendarLifetime){
		// 		return(
		// 			<Select selectItems={getPeriods(calendarLifetime)}
		// 					selectVal={periodSelect}
		// 					selectOnChange={periodChanged}></Select>
		// 		);
		// 	}
		// };

		return (
			<>
				<Typography  sx={{marginBottom : "2%"}}>
					<LifetimeLabel 
						{...travelDocument} 
						lifetimeDescription={ travelDocument.lifetimeDescription}
						/>
				</Typography>
				{/* <SelectPeriod calendarLifetime = {calendarLifetime}/>
				<Stack 	direction={"row"} 
						spacing={2}
						justifyContent="center"
						alignItems="center"
						useFlexGap flexWrap="wrap">
					<Typography sx={{width:"43%", justifySelf:"left"}}> Дата начала </Typography>
					<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru" >
						<DatePicker fullWidth ={false}
									disablePast
									isRequired
									value={ moment(new Date()).utc()}
									sx={{
										width : "50%",
										height: "50%",
										alignSelf: "flex-end"
									}}
									shouldDisableYear={(year) => year.isAfter(moment().add(2, 'years'))}
									/>
					</LocalizationProvider>
					<Typography sx={{width:"43%", justifySelf:"left"}} > Дата конца: </Typography>
					<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru" >
						<DatePicker fullWidth ={false}
									disablePast
									isRequired
									disabled
									value={ moment(new Date()).utc()}
									sx={{
										width : "50%",
										height: "50%",
										alignSelf: "flex-end"
									}}
									/>
					</LocalizationProvider>
				</Stack> */}
			</>
		);
	}

	
	// function getPeriods (calendarLifetime){
	// 	if(calendarLifetime === "Месяц"){
	// 		const Months = ['Январь', 'Февраль', 'Март', "Апрель", 'Май', 'Июнь',
	// 						'Июль', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
	// 		return Months.map((item, index) =>{ return {id : index+1, selectLabel : item};});
	// 	}else{
	// 		return [];
	// 	}
	// }
};

export default LifetimeForm;