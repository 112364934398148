import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {TransportCathegory, TDCathegory, TravelDocument} from '../../app/types/TDTypes';

type TDState = {
	travelDocuments : null | Array<TravelDocument>,
	travelDocumentCathegories : null | Array<TDCathegory>,
	transportCathegories : null | TransportCathegory,
}

const initialState : TDState = {
	travelDocuments : null,
	travelDocumentCathegories : null,
	transportCathegories : null,
};

export const travelDocumentsSlice = createSlice({
	name : "travelDocuments",
	initialState : initialState,
	reducers : {
		setTravelDocuments : (state, action : PayloadAction<null | Array<TravelDocument>>) =>{
			state.travelDocuments = action.payload;
		},
		setTDAndTransportCathegories : (state, action) =>{
			const {transportCathegories, travelDocumentCathegories} = action.payload;
			state.transportCathegories = transportCathegories;
			state.travelDocumentCathegories = travelDocumentCathegories;
		}
	},
	selectors : {
		selectTravelDocuments : (state) => state.travelDocuments,
		selectTDCathegories : state => state.travelDocumentCathegories,
		selectTransportCathegories : state => state.transportCathegories
	}
});

export const {selectTravelDocuments, selectTDCathegories, selectTransportCathegories} = travelDocumentsSlice.selectors;
export const {setTravelDocuments, setTDAndTransportCathegories} = travelDocumentsSlice.actions;
export default travelDocumentsSlice.reducer;