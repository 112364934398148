import '../TravelDocumentCreateForm.css';
import React, {useState}  from "react";
import Box from '@mui/material/Box';
import FormInput, {formInputProps} from "../../../../Components/Inputs/FormInput";
import {LoadingButton} from "@mui/lab";
import AlertChoise from "../../../../Components/AlertChoise";
import {alertStates, useAlert} from "../../../../states/useAlert";
import {travelDocumentApi} from "../../../../api";

const docCathegoryDefault = {
	cathegoryName : "",
	cathegoryTooltip : "",
	cathegoryUnits : "ед."
};

const CathegoryTab = ()=> {
	const [docCathegory, setDocCathegory] = useState(docCathegoryDefault);
	const {alertStatus, setAlertStatus} = useAlert();
	const [createCath, createResult] = travelDocumentApi.useCreateTDCathegoryMutation();

	const submitForm = (e : React.FormEvent<HTMLFormElement>) =>{
		e.preventDefault();
		createCath(docCathegory).unwrap().then(() => {
			setAlertStatus(true, alertStates.successAlert, `Категория ${docCathegory.cathegoryName} успешно добавлена!`);
			setDocCathegory(docCathegoryDefault);
		})
		.catch(error => {
			setAlertStatus(true, alertStates.errorAlert, error.message);
		});
	};
	const handleChange = (e : React.ChangeEvent<HTMLInputElement>) =>{
		const {name, value} = e.target;
		setDocCathegory({...docCathegory, [name] : value});
	};

	return (
		<Box>
			<Box sx={{textAlign:"center"}}>
				<h3>
					Добавление новой категории проездных документов
				</h3>
				<h6>
					Категория ПД отображается первой при выборе проездного документа (билет, проездной, БСК и т.д.)
				</h6>
			</Box>
			<Box sx = {{width:"70%", margin:"auto"}}>
				<form 
					id = "addDocCathegoryForm" 
					onSubmit={submitForm}
					>
					<FormInput 	
						label={"Название категории"} 
						inputProps={ 
							formInputProps({
								type : "text",
								name : "cathegoryName",
								onChange : handleChange,
								value : docCathegory.cathegoryName,
								placeholder : "Название категории",
								minLength : 2
							})
						}
						/>
					<FormInput 	
						label={"Всплываяющая подсказка"} 
						inputProps={ 
							formInputProps({
								type : "text",
								name : "cathegoryTooltip",
								onChange :handleChange,
								value : docCathegory.cathegoryTooltip,
								placeholder : "Полное название категории",
								minLength : 2
							})
						}
						/>
					<FormInput 	
						label={"Единицы измерения"} 
						inputProps={ 
							formInputProps({
								type : "text",
								name : "cathegoryUnits",
								onChange : handleChange,
								value : docCathegory.cathegoryUnits,
								placeholder : "Единицы измерения",
								minLength : 2,
								maxLength : 31
							})
						}
						/>
					<Box sx = {{textAlign:"center", marginTop:"0.5rem"}}>
						<AlertChoise {...alertStatus}/>
					</Box>
					<Box sx = {{textAlign:"center", marginTop:"2rem"}}>
						<LoadingButton
							type ="submit"
							className="submitNewCathegory"
							color = "success"
							variant="contained"
							loading = {createResult.isLoading}
							>
							Добавить категорию
						</LoadingButton>
					</Box>
				</form>
			</Box>
		</Box>
	);
};
export default CathegoryTab;