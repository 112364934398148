import {AddCircle} from "@mui/icons-material";
import {Box, IconButton} from "@mui/material";
import './TravelDocumentTabelAddRowButton.css';

const TravelDocumentTabelAddRowButton = ({onClick} : {onClick: React.MouseEventHandler<HTMLButtonElement>}) =>{						
	return (
		<Box 
			className = "row createTravelDocButtonBox"
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				'& > :not(style)': {
					// marginBottom: 1,
					backgroundColor: "rgb(225 243 255 / 90%)",
					height : "3rem",
					width : "100%",
					// paddingTop : "0.5rem",
					color : "rgb(9 10 59 / 73%)"
				},
			}}
			>
			<IconButton	
				onClick={onClick}  
				sx={{
						borderRadius:"0.1rem", 
						backgroundColor: "rgba(221, 237, 255 , 0.9)"
				}}
				>
				<AddCircle	
					color="primary" 
					fontSize="large"
					/>
			</IconButton> 
		</Box>
	);
};
export default TravelDocumentTabelAddRowButton;