import React, { SyntheticEvent }  from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {Box, Button, Snackbar, Alert, SnackbarCloseReason} from "@mui/material";
import ProtectedContent from "../../../utils/ProtectedContent";
import PublicContractView from "./publicContract/PublicContractView";
import styled from "styled-components";
import {selectIsLoggedIn} from "../../../features/auth/authSlice";
import { useAppSelector } from "../../../app/hooks/hooks";

const NavButtonContainer = styled((Box))`
    text-align: right;
	margin-top: 3vh;
    margin-right: 0;
`;
const HomeNavButton = styled((Button))`
	min-width: fit-content !important;
    width: 12vw;
    height: 6vh;
    font-size: 1rem !important; 
    font-weight: bold !important; 
    letter-spacing:0.1ex !important;
`;

const Home = ()=> {
	const isLoggedIn = useAppSelector(selectIsLoggedIn);
	const unauthorizedContent = !isLoggedIn;
	const authorizedContent = isLoggedIn;
	const userRole = useAppSelector(state => state.auth.role)
	const [open, setOpen] = React.useState(false);
	
	const handleClose = (event :Event | SyntheticEvent<Element, Event>, reason : SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	return (
		<>        
			<PublicContractView onCopyRequisites = {()=>setOpen(true)}/>
			<NavButtonContainer>
				<ProtectedContent isAllowed={unauthorizedContent}>
					<Link to = "/registration">
						<HomeNavButton 
							variant="contained"  
							color="success"
							>
							Перейти к регистрации
						</HomeNavButton>
					</Link>
				</ProtectedContent>
				<ProtectedContent isAllowed={authorizedContent  && userRole === 'customer'}>
					<Link to = "/invoiceForm">
						<HomeNavButton variant="contained">
							К оформлению заявки
						</HomeNavButton>
					</Link>
				</ProtectedContent>
			</NavButtonContainer>
			<Snackbar 
				open={open} 
				autoHideDuration={5000} 
				onClose={handleClose}
				>
				<Alert 
					severity="success" 
					sx={{
						width: '100%'
					}}
					>
					Реквизиты скопированы в буфер обмена
				</Alert>
			</Snackbar>	
		</>
	);
};
export default Home;

Home.propTypes = {
	isLoggedIn : PropTypes.bool
};