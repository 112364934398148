import "./AlertDialog.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

export interface AlertDialogProps{
	state : boolean,
	OkText? : string | React.ReactNode,
	cancelText? : string | React.ReactNode,
	dialogMainText : string | React.ReactNode, 
	dialogTitleText : string | React.ReactNode,
	onCancel : ()=>void,
	onOk: ()=>void,
}

export default function AlertDialog({state, OkText = "Продолжить", cancelText = "Отменить", dialogMainText = "", dialogTitleText = "?",
	onCancel = ()=>{}, onOk = ()=>{}} : AlertDialogProps) {
  
	const handleCloseCancel = () => {
		onCancel();
	};
	const handleCloseOK = () => {
		onOk();
	};
  
	return (
		<div>
			<Dialog
				open={state}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{dialogTitleText}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{dialogMainText}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseCancel}>
						{cancelText}
					</Button>
					<Button onClick={handleCloseOK} autoFocus>
						{OkText}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}