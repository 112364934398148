import React, {useEffect, useState} from "react";
import {Box, TextField, Button, IconButton, Typography, Snackbar, Alert, Divider, AlertColor, SnackbarCloseReason} from "@mui/material";
import {Col, Row} from "react-bootstrap";
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import moment from "moment";
import {ContentCopy, Download as DownloadIcon, NavigateNext} from "@mui/icons-material";
import {useClipboard} from 'use-clipboard-copy';
import labelDescription from './userInfoLabels';
import {DataGrid, GridColDef, GridRowSelectionModel, ruRU} from "@mui/x-data-grid";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {ruRU as pickersBgBG} from "@mui/x-date-pickers/locales";
import {ruRU as coreBgBG} from "@mui/material/locale";
import config from "../../../config/config";
import {useLocation, useNavigate} from "react-router-dom";
import {userApi} from "../../../api";
import {LoadingBackdrop} from "../../../Components";
import {LoadingButton} from "@mui/lab";
import {useDownloadContractMutation} from "../../../api/contractApi";

type ContractsItemState = {
	id : number,
	contractId: string,
    contractNumber: string,
    contractYear: string,
    contractSumRuble: number,
    contractSumPenny: number,
	contractDate: string,
    differentDocumentsCount: number,
    totalDocumentsCount: number,
	contractSum : number
}


const LabelItem = styled(Typography)(() => ({
	backgroundColor: '#fff',
	textAlign: 'left',
	fontSize:"large",
	padding:"0.5vh 2vh",
	fontFamily:"sans-serif"
  }));

const Item = styled(Typography)(() => ({
	backgroundColor: '#fff',
	textAlign: 'left',
	fontSize:"large",
	padding:"0.5vh 0vh",
	
	fontWeight : "bold",
  }));


const defaultUserDataState = {
	registrationNumber: "",
	email: "",
	customerFullName: "",
	legalAddress: "",
	mailingAddress: "",
	checkingAccount: "",
	bankCode: "",
	bankName: "",
	chiefFullName: "",
	chiefDocument: "",
	phoneNumbers : [''],
	phoneNumbersLabel : [] as Array<React.ReactElement>,
	phoneNumbersCopy : '',
	statusName : '',
	registrationDate : '',
};

const UserInfoPage = ()=> {
	const [searchUser, searchResult] = userApi.useLazyGetUserInfoQuery(); 
	const [downloadContract, downloadResult] = useDownloadContractMutation();
	const location = useLocation();
	const [searchNumber, setSearchNumber] = useState( location.state?.userRegistrationNumber ?? '');
	const [userData, setUserData] = useState(defaultUserDataState);

	const [contracts, setContracts] = useState([] as ContractsItemState[]);
	const [rowSelectionModel, setRowSelectionModel] = React.useState([] as GridRowSelectionModel);
	
	const [open, setOpen] = React.useState(false);
	const [snackbar, setSnackbar] = useState({type : 'success', text : ''});
	const clipboard = useClipboard();
	const navigate = useNavigate();
	
	const setSuccessSnackbarText = (text : string) => setSnackbar({type : 'success', text});

	const DataItem = ({label, item, copyText = item} : {label : string, item : string | string[] | React.ReactElement, copyText : typeof item}) =>{
		return (
			<Grid 
				xs={12} 
				container
				>
				<Grid  
					xs={5} 
					key={1}
					>
					<LabelItem>
						{label}
					</LabelItem>
				</Grid>
				<Grid 
					xs={6}  
					key={2}
					>
					<Item>
						{item === '' ? '-' : item}
					</Item>
				</Grid>
				<Grid 
					xs={1}  
					key={3}>
					<IconButton 
						disabled ={ item === ''}  
						onClick={()=>{
							clipboard.copy(copyText); 
							setSuccessSnackbarText(label + ' скопирован в буфер обмена');
							setOpen(true);
							}
						}>
						<ContentCopy color="success"/>
					</IconButton>
				</Grid>
			</Grid>
		);
	};
	
	const UserData = ({userData} : {userData : typeof defaultUserDataState}) =>{
		if(!userData){
			return (
			<Typography variant="h2">
				Ошибка отображения информации о пользователе
			</Typography>
			);
		}
		return labelDescription.itemsDescription.map((item) => {
			return (
				<DataItem 	
					label={item.label}
					item={userData[item.itemName as keyof typeof defaultUserDataState] as string}
					copyText={userData[item.copy as keyof typeof defaultUserDataState] as string}
					key={item.key}
					/>
			);
		});
	};

	const submitForm = (e : React.FormEvent<HTMLFormElement> | undefined)=>{
		e?.preventDefault();
		setUserData(defaultUserDataState);
		searchUser(searchNumber).unwrap().then(responseValues => {
			const {user, phones, contracts : contractsData} = responseValues;
			const 	registrationDate = moment(user.registrationDate).format('LL'),
					phoneNumbersLabel = phones?.map((number, index) => (<span key = {index}>{number}<br/></span>)) ?? 'Ошибка',
					phoneNumbersCopy = phones?.reduce((prev, number) =>`${prev} ${number}`,'') ?? 'Ошибка';
			setUserData({...user, phoneNumbers : phones, phoneNumbersLabel, phoneNumbersCopy, registrationDate});
			setContracts(contractsData.map(contract =>({
				...contract,
				id : contract.contractId,
				contractId : `${contract.contractNumber}-${contract.contractYear}`,
				contractSum : (contract.contractSumRuble + contract.contractSumPenny/100),
				contractDate : moment(contract.contractDate).format(config.dateFormats.accountantInfoTable),
			})));
		})
		.catch(error=>{
			console.log(error);
			setSnackbar({type : 'error',text : error.message});
			setOpen(true);
		});
	};

	const handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
		setSearchNumber(e.target.value); 
		if(userData !== defaultUserDataState){
			setUserData(defaultUserDataState);
			setContracts([]);
		}
	};

	const handleClose = (event : Event | React.SyntheticEvent<any, Event>, reason : SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};
	const handleNavigate = () =>{
		if(rowSelectionModel.length > 0){
			const contract = contracts.find(contract => contract.id === rowSelectionModel[0]);
			if(contract)
				navigate("/search/invoice/", {state:{contractSearchString : `${contract.contractNumber}-${contract.contractYear}`}});
		}
		else{
			setSnackbar({type : 'error',text : 'Выберите заявку в списке'});
		}
	};

	const downloadSelectedContract = ()=>{
		if(rowSelectionModel.length > 0){
			const contractId = rowSelectionModel[0];
			const contract = contracts.find(contract => contract.id === rowSelectionModel[0]);
			if(!contract)
				return;
			const {contractNumber, contractYear} = contract;
			downloadContract({contractId, contractNumber, contractYear}).unwrap()
			.catch(error =>{
				console.log(error);
				const {message}   = error;
				setSnackbar({type : 'error',text : message});
				setOpen(true);
			});
		}
		else{
			setSnackbar({type : 'error',text : 'Выберите заявку в списке'});
		}
	};

	useEffect (()=>{
		if(location?.state?.userRegistrationNumber && location?.state?.userRegistrationNumber !== '')
			submitForm(undefined);
		window.scrollTo(0,0);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const columns : GridColDef[] = [
		{field: "contractId", headerName: "Номер заявки", flex : 0.2},
		{field: "differentDocumentsCount", headerName: "Количество различных наименований ПД в заявке", flex:0.15, align : "center", headerAlign : "center"},
		{field: "totalDocumentsCount", headerName: "Всего ПД в заявке", flex:0.15, align : "center", headerAlign : "center"},
		{field: "contractSum", headerName: "Сумма, руб.", flex:0.2, type : 'number', align : "center", headerAlign : "center"},
		{field: "contractDate", headerName: "Дата оформления заявки", flex:0.3},
	];

	const theme = createTheme(
		{
			palette: {
				primary: {main: "#1976d2"},
			},
		},
		ruRU, // x-data-grid translations
		pickersBgBG, // x-date-pickers translations
		coreBgBG, // core translations
	);

	return (
		<>
			<Typography 
				variant="h4" 
				align="center" 
				sx={{marginBottom:"1vh"}}
				>
				Поиск покупателя по УНП:
			</Typography>
			<Box 	component="form"
				id = 'searchUserForm'
				onSubmit={submitForm}>
				<Row style={{display:"flex"}}>
					<Col md = {10}>
						<TextField 	
							label="Унп пользователя" 
							placeholder="111111111" 
							type="search"
							required
							inputProps={{
								maxLength : 9,
								minLength : 9,
							}}
							value={searchNumber}
							onChange={handleChange}
							fullWidth 
							/>
					</Col>
					<Col md = {2}>
						<LoadingButton
							type="submit"
							variant="outlined"
							sx = {{fontSize:"large", width:"100%", height:"100%"}}
							loading = {searchResult.isFetching}
							>
							Поиск
						</LoadingButton>
					</Col>
				</Row>
				<Grid 	
					container 
					spacing={2}
					sx={{marginTop:"2vh"}}
					>
					<UserData userData={userData}/>
				</Grid>
			</Box>
			
			<Divider sx={{
				marginBlock:"3vh", 
				borderColor:'rgba(0, 0, 0, 0.45)'
				}
			}
			/>

			<Typography 
				variant="h4" 
				align="center" 
				sx={{marginBottom:"3vh"}}
				>
					Список заявок покупателя:
			</Typography>
			<Box sx = {{width:"100%", margin:"auto"}}>
				<ThemeProvider theme={theme}>
					<DataGrid  
						columns={columns}
						rows = {contracts}
						rowSelectionModel={rowSelectionModel}
						onRowSelectionModelChange={setRowSelectionModel}
						initialState={{
							pagination: {paginationModel: {pageSize: 10}},
						}}
						pageSizeOptions={[5,10,25,50]}
						autoHeight
						/>
				</ThemeProvider>
			</Box>
			
			<Box 	
				className = 'printButtonContainer' 
				display={ userData === defaultUserDataState ? 'none' : 'inherit'}
				>
				<Button 
					className='printButton'
					type="button"
					variant="contained"
					startIcon={<NavigateNext/>}
					onClick = {handleNavigate} 
					>
					Просмотреть заявку
				</Button>
				<LoadingButton 
					className='printButton'
					type="button"
					variant="contained"
					startIcon={<DownloadIcon />}
					onClick = {downloadSelectedContract} 
					loading ={downloadResult.isLoading}
					>
					Скачать счёт
				</LoadingButton>
			</Box>
			
			<Snackbar 
				open={open} 
				autoHideDuration={5000} 
				onClose={handleClose}
				>
					<Alert 
						severity={snackbar.type as AlertColor} 
						sx={{width: '100%'}}
						>
						{snackbar.text}
					</Alert>
			</Snackbar>
			<LoadingBackdrop open = {searchResult.isFetching}/>
		</>
	);
};
export default UserInfoPage;
