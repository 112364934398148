export type CalendarLifetimeItem  = "Сутки" | "Неделя" | "Декада" | "Месяц" | "Год";
type LifetimeLabelProps ={
	lifetimeDays? : number | string,
	lifetimeDescription : string,
	calendarLifetime? : CalendarLifetimeItem
};

export default function LifetimeLabel ({lifetimeDays, lifetimeDescription, calendarLifetime} : LifetimeLabelProps){
	const dayLabel = lifetimeDays === 1 || lifetimeDays === '1' ? "cутки" : "суток" ;
	const calendarHelper = {
		"Сутки" : "календарные",
		"Неделя" : "календарная",
		"Декада" : "календарная",
		"Месяц" : "календарный",
		"Год" : "календарный",
		"" : ""
	};
	if(lifetimeDescription && !lifetimeDescription?.startsWith(",")){
		lifetimeDescription = ' ' + lifetimeDescription;
	}
	const lifetimeLabel = lifetimeDays && !calendarLifetime ? 
										`${lifetimeDays} ${dayLabel}${lifetimeDescription}` : 
										lifetimeDescription?  `${lifetimeDescription}` : `${calendarHelper[calendarLifetime ?? "" ]} ${calendarLifetime?.toLocaleLowerCase()}`;
	return (
	<>
		{lifetimeLabel}
	</>
	);
}