import type { ValuesResponse,NoDataResponse } from "./responseTypes";
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import config from "../config/config";
import apiRoutes from "../config/apiRoutes";
import {baseQueryWithExpire, transformAuthorizedErrorResponse} from './transformApiResponse';
import { TDCathegory, TransportCathegory, TransportType, TravelDocsData, TravelDocument } from "../app/types/TDTypes";
import { RootState } from "../app/store/store";
const {travelDocumentRoutes, transportCathegoriesRoutes, travelDocumentCathegoriesRoutes, transportRoutes} = apiRoutes;

export const travelDocumentApi = createApi({
	reducerPath: 'travelDocumentsApi',
	baseQuery: 	baseQueryWithExpire(fetchBaseQuery({
		baseUrl: config.env.apiBase,
		prepareHeaders: (headers, {getState}) => {
			const token = (getState() as RootState).auth.token;
			if (token){
				headers.set('Authorization', token);
			}
		},
	})),
	tagTypes :['TDList'],
	endpoints: (builder) => ({
		//with cathegories data
		getAvailibleTD: builder.query({
			query: () => `${travelDocumentRoutes.getAvailibleList}`,
			transformResponse: (response : ValuesResponse<{travelDocumentsData : TravelDocsData}>) =>response?.values?.travelDocumentsData ?? [],
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		//only TD
		getAllTD : builder.query({
			query: () => `${travelDocumentRoutes.getAll}`,
			transformResponse: (response) =>response?.values?.travelDocuments ?? [],
			transformErrorResponse : transformAuthorizedErrorResponse,
			providesTags : ['TDList']
		}),
		getTDAndTransportCathegories : builder.query({
			query: () => `${transportCathegoriesRoutes.getList}`,
			transformResponse: (response : ValuesResponse<{cathegories : {transportCathegories : TransportCathegory, documentCathegories : Array<TDCathegory>}}>) =>response?.values?.cathegories ?? 
								{transportCathegories : {}, documentCathegories : []},
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		updateTravelDocument : builder.mutation ({
			query : (body)=>({
				url:`${travelDocumentRoutes.update}`,
				method: "POST",
				body
			}),
			transformResponse: (response : NoDataResponse) => response.message,
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		deleteTravelDocuments : builder.mutation ({
			query : (travelDocumentsIdList)=>({
				url:`${travelDocumentRoutes.delete}`,
				method: "POST",
				body : {travelDocumentsIdList}
			}),
			transformResponse: (response : NoDataResponse) => response.message,
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		copyTravelDocuments : builder.mutation ({
			query : ({travelDocumentsIdList, copyOptions})=>({
				url:`${travelDocumentRoutes.copy}`,
				method: "POST",
				body : {travelDocumentsIdList, ...copyOptions}
			}),
			transformResponse: (response : NoDataResponse) => response.message,
			transformErrorResponse : transformAuthorizedErrorResponse,
			invalidatesTags : ['TDList']
		}),
		createTDCathegory : builder.mutation ({
			query : (body)=>({
				url : travelDocumentCathegoriesRoutes.create,
				method: "POST",
				body
			}),
			transformResponse: (response : NoDataResponse) => response.message,
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		createTransport : builder.mutation ({
			query : ({transportName})=>({
				url : transportRoutes.create,
				method: "POST",
				body : {transportName}
			}),
			transformResponse: (response : NoDataResponse) => response.message,
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		getTransports : builder.query({
			query: () => transportRoutes.getList,
			transformResponse: (response : ValuesResponse<{transportTypes : TransportType[]}>) =>response?.values?.transportTypes ?? [],
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		createTransportCathegory : builder.mutation ({
			query : ({transportCathegoryName, availableTransport})=>({
				url : transportCathegoriesRoutes.create,
				method: "POST",
				body : {transportCathegoryName, availableTransport}
			}),
			transformResponse: (response: NoDataResponse) => response.message,
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		createTravelDocument: builder.mutation ({
			query : (body)=>({
				url : travelDocumentRoutes.create,
				method: "POST",
				body 
			}),
			transformResponse: (response: NoDataResponse) => response.message,
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		getTravelDocument : builder.query({
			query: (travelDocumentId) => ({
				url : travelDocumentRoutes.get,
				params : {travelDocumentId}
			}),
			transformResponse: (response : ValuesResponse<{document : TravelDocument}>) =>response?.values?.document,
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
	}),

});
export const {useGetAvailibleTDQuery} = travelDocumentApi;

