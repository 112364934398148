import "./Cabinet.css";
import React, {useState, useEffect}  from "react";
import {alertStates, useAlert} from "../../../states/useAlert";
import config from "../../../config/config";
import {useGetOwnDataQuery, useUpdateOwnDataMutation} from "../../../api/userApi";
import {Col, Row} from "react-bootstrap";
import {Button} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {LoadingBackdrop, ContactsInput, AlertChoise, formInputProps, FormInput, LabelWithTooltip} from "../../../Components";
import ToggleVisibility from "../../../utils/ToggleVisability";
import { RawFormInputProps } from "../../../Components/Inputs/FormInput";
import { PhoneItem } from "../../../Components/Inputs/ContactsInput";

const userDataDef = {
	registrationNumber:"",
	email: "",
	customerFullName:"",
	legalAddress:"",
	mailingAddress:"",
	checkingAccount:"",
	bankCode:"",
	bankName:"",
	chiefFullName:"",
	chiefDocument:"",
	phoneNumbers : [] as PhoneItem[],
};

const UserCabinet = ()=> {
	const customerData = useGetOwnDataQuery({});
	const [updateOwnData, updateResult] = useUpdateOwnDataMutation();
	const [IsDisabled, setDisable]  = useState(true);
	const {alertStatus, setAlertStatus} = useAlert();
	const [userDataChanged, setUserDataChanged] = useState(false);
	const legalEntityTooltip = config.tooltips.legalEntityTooltip;
	const [userData, setUserData] = useState(userDataDef);
	useEffect(()=>{
		const {data, error, isError, isSuccess} = customerData;
		if(isSuccess){
			setUserData({
							...data,
							phoneNumbers : data.phoneNumbers.map(number => ({...number, action : 'update'}))
						}); 
		}else if(isError){
			const {message} = error;
			console.log(error);
			setAlertStatus(true, alertStates.errorAlert, message);
		}
	},[customerData, setAlertStatus]);
	
	const handleChange = (e : React.ChangeEvent<HTMLInputElement>)=>{
		setUserData({...userData,[e.target.name]:e.target.value});
		if(!userDataChanged){
			setUserDataChanged(true);
		}
	};
	const cabinetInputProps = ({type, name, value, pattern, minLength, maxLength, placeholder,readOnly, disabled, required} : RawFormInputProps)=> 
		formInputProps({type, name, onChange:handleChange,value, pattern, minLength, maxLength, placeholder, readOnly : readOnly ?? IsDisabled,disabled: disabled ??IsDisabled, required});
	
	const resetForm = (e : React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setDisable(true);
		customerData.refetch();
	};

	const submitForm = (e : React.FormEvent<HTMLFormElement>)=>{
		e.preventDefault();
		if(IsDisabled){
			setTimeout(()=>{setDisable(!IsDisabled);},200);
			setUserDataChanged(false);
			setAlertStatus(false);
		}else if(userDataChanged){
			setAlertStatus(false);
			if(userData.phoneNumbers.length <=0  || userData.phoneNumbers.every(number => number.action ==='delete')){
				setAlertStatus(true, alertStates.errorAlert, 'Должен быть добавлен хотя бы 1 номер');
				return;
			}
			const {registrationNumber: _d1, email : _d2,...sendData } = userData;
			updateOwnData(sendData).unwrap()
			.then(res =>{
				setAlertStatus(true, alertStates.successAlert, res.message);
				setDisable(!IsDisabled);
			})
			.catch(err => {
				console.log(err);
				setAlertStatus(true, alertStates.errorAlert, err.message);
			});	
		}else if(!userDataChanged){
			setAlertStatus(true, alertStates.dangerAlert, "Внесите изменения в данные о покупателе!");
		}
	};

	return (
		<div className= "edit_form_contaner"> 
			<form 
				id = "edit-form" 
				onSubmit={submitForm} 
				onReset={resetForm} 
				>
				<div className="row">
					<div className="col-md-12 text-center main-edit-text">
						<h1>
							Данные Покупателя
						</h1>
						<h5>
							для изменения данных нажмите кнопку {`"Разрешить редактирование"`}, внесите изменения и сохраните их
						</h5>
					</div>
				</div>
				<FormInput 	
					label={(
						<span>
							Наименование{" "}
							<LabelWithTooltip 
								tooltipLabel={legalEntityTooltip} 
								labelText="юридического лица"/>
								<br/>
								(фамилия, собственное имя, отчество (если таковое имеется) индивидуального предпринимателя)
								</span>
					)} 
					inputProps={ 
						cabinetInputProps({
							type : "text",
							name : "customerFullName",
							value : userData.customerFullName,
							placeholder : `ОАО "Рога и копыта"`,
							minLength : 2
						})
					}
				/>
				<FormInput 	
					label={"Фамилия, собственное имя, отчество (если таковое имеется) руководителя"} 
					inputProps={ 
						cabinetInputProps({
							type : "text",
							name : "chiefFullName",
							value : userData.chiefFullName,
							placeholder : "Иванов Иван Иванович",
							minLength : 3
						})
					}
				/>
				<FormInput 	
					label={"Наименование документа, на основании которого действует руководитель"} 
					inputProps={ 
						cabinetInputProps({
							type : "text",
							name : "chiefDocument",
							value : userData.chiefDocument,
							placeholder : "Устав, доверенность, др",
							minLength : 3
						})
					}
				/>
				<FormInput 	
					label={(
						<span>
							Местонахождения{" "}
							<LabelWithTooltip 
								tooltipLabel={legalEntityTooltip} 
								labelText="юридического лица"/>
								<br/>
								(место жительства индивидуального предпринимателя)
						</span>
					)} 
					inputProps={ 
						cabinetInputProps({
							type : "text",
							name : "legalAddress",
							value : userData.legalAddress,
							placeholder : "Ул.Красная, 00-000, 220000, г.Минск",
							minLength : 3
						})
					}
				/>
				<FormInput 	
					label={"Почтовый адрес (адрес для переписки)"} 
					inputProps={ 
						cabinetInputProps({
							type : "text",
							name : "mailingAddress",
							value : userData.mailingAddress,
							placeholder : "Ул.Красная, 00-000, 220000, г.Минск",
							minLength : 3
						})
						}
				/>
				<FormInput 	
					label={"Электронный адрес"} 
					inputProps={ 
						cabinetInputProps({
							type : "email",
							name : "email",
							value : userData.email,
							placeholder : "example@gmail.com",
							disabled : true,
							readOnly : true
						})
					}
				/>
				<FormInput 	
					label={"УНП"} 
					inputProps={ 
						cabinetInputProps({
							type : "text",
							name : "registrationNumber",
							value : userData.registrationNumber,
							pattern : "[\\dA-Za-z]{8}[\\d]",
							minLength : 9,
							maxLength : 9,
							placeholder : "100008858",
							disabled : true,
							readOnly : true
						})
					}
				/>
				<FormInput 	
					label={"Расчетный счёт"} 
					inputProps={ 
						cabinetInputProps({
							type : "text",
							name : "checkingAccount",
							value : userData.checkingAccount,
							pattern : "BY\\d{2}[A-Z]{4}\\d{20}",
							minLength : 28,
							maxLength : 28,
							placeholder : "BY00AKBB00000000000000000000"
						})
					}
				/>
				<FormInput 	
					label={"Банк"} 
					inputProps={ 
						cabinetInputProps({
							type : "text",
							name : "bankName",
							value : userData.bankName,
							placeholder : "ОАО \"АСБ Беларусбанк\", г.Минск",
							minLength : 3
						})
					}
				/>
				<FormInput 	
					label={"БИК"} 
					inputProps={ 
						cabinetInputProps({
							type : "text",
							name : "bankCode",
							value : userData.bankCode,
							placeholder : "AKBBBY2X",
							minLength : 8,
							maxLength : 11,
							pattern:"[A-Z]{6}[A-Z0-9]{2}([\\d]{3})?",
						})
					}
				/>
				<ContactsInput 	
					label={"Контактные телефоны"}
					phoneNumbers = {userData.phoneNumbers}
					setPhoneNumbers = {(numbers)=>{setUserDataChanged(true);setUserData({...userData, phoneNumbers : numbers});}}
					disabled={IsDisabled}
					/>
				<Row>
					<AlertChoise {...alertStatus}/>
				</Row>

				<ToggleVisibility visible = {IsDisabled}>
					<Row className="submitEditContainer">
						<Button 
							className='submitEdit'
							variant="contained"
							type = "submit"
							sx={{margin:"auto"}}
							>
							Разрешить редактирование
						</Button>
					</Row>
				</ToggleVisibility>
				<ToggleVisibility visible = {!IsDisabled}>
					<Row>
						<Col 	
							className="text-center submitEditContainer"
							md = {6}
							>
							<LoadingButton 
								className='submitEdit'
								variant="outlined"
								color="error"
								type = "reset"
								sx={{margin:"auto"}}
								loading = {customerData.isFetching}
								>
								Отменить изменения
							</LoadingButton>
						</Col>
						<Col 	
							className="text-center submitEditContainer"
							md = {6}
							>
							<LoadingButton 
								className='submitEdit'
								variant="contained"
								type = "submit"
								sx={{margin:"auto"}}
								loading = {updateResult.isLoading}
								>
									Сохранить изменения
							</LoadingButton>
						</Col>
					</Row>
				</ToggleVisibility>
			</form>
			<LoadingBackdrop open = {customerData.isFetching}/>
		</div>
	);
};
export default UserCabinet;