import '../TravelDocumentCreateForm.css';
import React, {useEffect, useState}  from "react";
import Box from '@mui/material/Box';
import FormInput, {formInputProps} from "../../../../Components/Inputs/FormInput";
import {SelectChangeEvent, Stack, Typography} from "@mui/material";
import AlertChoise from "../../../../Components/AlertChoise";
import Select, {stringArrayToSelectItems, createTooltips} from "../../../../Components/Select";
import {alertStates, useAlert} from "../../../../states/useAlert";
import MoneyInput from "../../../../Components/Inputs/MoneyInput";
import {Col, Row} from "react-bootstrap";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/ru';
import moment from "moment";
import LifetimeLabel, { CalendarLifetimeItem } from "../../../../Components/TravelDocumentComponents/LifeTimeForm/LifetimeLabel";
import {travelDocumentApi} from "../../../../api";
import {LoadingButton} from "@mui/lab";
import { SelectOption } from "../../../../app/types/selectTypes";

const openFromDefaultVal = moment(new Date()).add(10, "minutes");
const expireDefaultVal = moment((new Date())).add(1,'years');

const travelDocumentDefaultState = {
	price : "", 
	documentName : "",
	lifetimeDescription : "",
	lifetimeDays : '' as string | number,
	calendarLifetime : 0,
	useCount : null as (number | null),
	nameForDocuments : '',
	travelDocumentCathegory : '',
	transportCathegory : '',
	openFromDate : openFromDefaultVal as  moment.Moment | null | undefined,
	expireDate : expireDefaultVal as  moment.Moment | null | undefined
};

const calendarLifetime = [	{selectLabel : 'Не указано' , id : 0},
								{selectLabel : 'Сутки' , id : 1},
								{selectLabel : 'Неделя', id : 7},
								{selectLabel : 'Декада', id : 10},
								{selectLabel : 'Месяц' , id : 30},
								{selectLabel : 'Год'   , id : 365}];

const TravelDocumentTab = ()=> {
	const {alertStatus, setAlertStatus} = useAlert();
	const [travelDocument, setTravelDocument] = useState({...travelDocumentDefaultState});
	const [travelDocCathegories, setTravelDocCathegories] = useState<SelectOption[]>([]);
	const [transportCathegories, setTransportCathegories] = useState<object | null>();
	const [createTD, createResult] = travelDocumentApi.useCreateTravelDocumentMutation();
	const TDAndTransportCathegoriesQuery = travelDocumentApi.useGetTDAndTransportCathegoriesQuery({});

	useEffect(()=>{
		const {error, isSuccess, isError, data} = TDAndTransportCathegoriesQuery;
		if(isSuccess){
			const {transportCathegories, documentCathegories} = data;
			setTransportCathegories(transportCathegories);
			setTravelDocCathegories(stringArrayToSelectItems(documentCathegories.map(docCath => docCath.documentCathegoryName)));
		}else if(isError){
			console.log(error);
			setAlertStatus(true, alertStates.errorAlert, error.message);
		}
	},[TDAndTransportCathegoriesQuery, setAlertStatus]);
	
	const handleChange = (e : React.ChangeEvent<HTMLInputElement>)=>{
		const {name, value} = e.target;
		setTravelDocument({...travelDocument, [name] : value});
	};
	const handleSelectChange = (e : SelectChangeEvent<string | number>)=>{
		const {name, value} = e.target;
		setTravelDocument({...travelDocument, [name] : value});
	};
	const handleSelectCalendarLifetimeChange = (e : SelectChangeEvent<string | number>)=>{
		const { value} = e.target;
		let {lifetimeDays} = travelDocument;
		lifetimeDays = value === 0 ? '' : value;
		setTravelDocument({...travelDocument, lifetimeDays});
	}

	const submitForm = (e : React.FormEvent<HTMLFormElement>) =>{
		e.preventDefault();
		setAlertStatus(false);
		const travelDocParam = prepareParams(travelDocument);
		createTD(travelDocParam).unwrap()
		.then(()=>{
			setAlertStatus(true, alertStates.successAlert, `Документ ${travelDocument.documentName} успешно добавлен!`);
			setTravelDocument({...travelDocumentDefaultState});
		})
		.catch(error => {
			console.log(error);
			setAlertStatus(true, alertStates.errorAlert, error.message);
		});
	};
	
	function prepareParams(travelDocument : typeof travelDocumentDefaultState){
		const {price, documentName, lifetimeDescription,lifetimeDays,calendarLifetime: calendarLifetimeFromTD, 
				useCount, nameForDocuments, travelDocumentCathegory, transportCathegory, openFromDate,expireDate} = travelDocument;
		const travelDocParam =  {
			price: +price * 100,
			documentName,
			lifetimeDescription,
			lifetimeDays,
			calendarLifetime: "" as string | null,
			useCount : !useCount ? 'NULL' : useCount,
			nameForDocuments,
			documentCathegoryName : (travelDocCathegories[travelDocumentCathegory as keyof SelectOption[]] as SelectOption)?.selectLabel ?? '',
			transportCathegoryName : Object.keys(transportCathegories ?? {})[transportCathegory as keyof string[]],
			openFromDate : openFromDate?.format('YYYY-MM-DD HH:mm:ss') ?? '',
			expireDate : expireDate?.format('YYYY-MM-DD HH:mm:ss') ?? '' 
		};
		for (let key in travelDocParam){
			if(travelDocParam[key as keyof typeof travelDocParam] === '')
				delete travelDocParam[key as keyof typeof travelDocParam];
		}
		
		if(calendarLifetimeFromTD !== 0)
			travelDocParam.calendarLifetime = calendarLifetime.find(item => item.id === calendarLifetimeFromTD)?.selectLabel ?? 'NULL';
		else
			travelDocParam.calendarLifetime = null;
		return travelDocParam;
	}

	return (
		<Box>
			<Box sx={{textAlign:"center"}}>
				<h3>
					Добавление проездного документа
				</h3>
				<h6>
					Для добавления проездного документа заполните все необходимые поля
				</h6>
			</Box>
			<Box sx = {{width:"70%", margin:"auto"}}>
				<form 
					id = "addTravelDocumentForm" 
					onSubmit={submitForm}
					>
					<FormInput 	
						label={"Название проездного документа*"} 
						inputProps={ 
							formInputProps({
								type : "text",
								name : "documentName",
								onChange : handleChange,
								value : travelDocument.documentName,
								placeholder : "Проездной на 10 суток",
								minLength : 3
							})
						}
						/>
					<Row>
						<Col md = {6} >
							<p className="formInputLabel">
								Стоимость документа, руб*
							</p>
						</Col>
						<Col md = {6}>
							<MoneyInput 
								inputProps={
									formInputProps({
										name : "price",
										onChange : handleChange,
										value : travelDocument.price,					
										placeholder : "11.99",
									})
								}
								min = {0} 
								max = {100000}
								maxLen={10}
								/>
						</Col>
					</Row>

					<Row style={{paddingTop:0}}>
						<Col md = {6} >
							<FormInput 	label={"Срок действия, сут"} 
							inputProps={ 
								formInputProps({
									type : "number",
									name : "lifetimeDays",
									onChange : handleChange,
									value : travelDocument.lifetimeDays,
									placeholder : "30",
									minLength : 1,
									maxLength : 3,
									readOnly : travelDocument.calendarLifetime !== 0,
									disabled : travelDocument.calendarLifetime !== 0,
									required : false
								})
							}
							/>
						</Col>
						<Col md = {6} >
							<Row >
								<Col md = {6} >
									<p className="formInputLabel">
										По календарю
									</p>
								</Col>
								<Col md = {6} style={{textAlign:"right"}}>
									<Select 
										selectItems= {calendarLifetime.map((val) => ({...val, id : String(val.id)}))} 
										selectVal={String(travelDocument.calendarLifetime)}		
										selectOnChange = {handleSelectCalendarLifetimeChange}
										name = {"calendarLifetime"}
										sx={{textAlign:"center", minWidth:"100%"}}
										/>
								</Col>
							</Row>
						</Col>
					</Row>

					<FormInput 	
						label={"Описание срока действия*"} 
						inputProps={ 
							formInputProps({
								type : "text",
								name : "lifetimeDescription",
								onChange : handleChange,
								value : travelDocument.lifetimeDescription,
								placeholder : "с выбранной пассажиром даты",
								minLength : 3
							})
						}
						/>
					<Typography sx={{marginTop:"0.25rem"}}>
						*В форме выбора ПД срок действия будет отображаться как:{" "}  
						<LifetimeLabel 	
							lifetimeDays={travelDocument.lifetimeDays}
							lifetimeDescription={travelDocument.lifetimeDescription}
							calendarLifetime = { 
								travelDocument.calendarLifetime === 0 ? 
									undefined 
								: 
									(calendarLifetime.find((item)=>item.id === travelDocument.calendarLifetime)?.selectLabel as CalendarLifetimeItem)
							}
							/>
					</Typography>
					<FormInput 	
						label={"Количество поездок"} 
						inputProps={ 
							formInputProps({
								type : "number",
								name : "useCount",
								onChange : handleChange,
								value : travelDocument.useCount ?? '',
								placeholder : "0",
								minLength : 1,
								required : false
							})
						}
						/>
					<FormInput 	
						label={"Название для документов*"} 
						inputProps={ 
							formInputProps({
								type : "text",
								name : "nameForDocuments",
								onChange : handleChange,
								value : travelDocument.nameForDocuments,
								placeholder : "Проездной АВТОБУС-ТРОЛЛЕЙБУС-ТРАМВАЙ на месяц",
								minLength : 3
							})
						}
						/>
						<Row>
							<Col md = {3}>
								<p className="formInputLabel">
									Категория ПД*
								</p>
							</Col>
							<Col 
								md = {3} 
								style={{textAlign:"right"}}
								>
								<Select 
									selectItems= {travelDocCathegories} 
									selectVal={travelDocument.travelDocumentCathegory}
									selectOnChange = {handleSelectChange}
									name = {"travelDocumentCathegory"}
									sx={{textAlign:"center", minWidth:"100%"}}
									required = {true}
									/>
							</Col>
							<Col 
								md = {3} 
								style={{paddingRight:0}}
								>
								<p className="formInputLabel">
									Категория транспорта*
								</p>
							</Col>
							<Col 
								md = {3} 
								style={{textAlign:"right"}}
								>
								<Select 
									selectItems= {stringArrayToSelectItems(Object.keys(transportCathegories ?? {}))} 
									toolTips={createTooltips(transportCathegories ?? {})}
									selectVal={travelDocument.transportCathegory}
									selectOnChange = {handleSelectChange}
									name = {"transportCathegory"}
									sx={{textAlign:"center", minWidth:"100%"}}
									required = {true}
									/>
							</Col>
						</Row>
						<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru" >
						
						<Stack 	
							direction={"row"} 
							spacing={0}
							justifyContent="center"
							alignItems="center"
							useFlexGap flexWrap="wrap"
							>
							<Typography 
								sx={{
									width:"25%", 
									justifySelf:"left", 
									fontSize:"large"
								}}
								> 
								Документ актуален с
							</Typography>
							<DateTimePicker 
								disablePast
								value={travelDocument.openFromDate}
								onChange={(val)=>setTravelDocument({...travelDocument, openFromDate:val})}
								sx={{
									width : "25%",
									height: "50%",
									alignSelf: "flex-end"
								}}
								shouldDisableYear={(year) => year.isAfter(moment().add(2, 'years'))}
								/>
							<Typography sx={{width:"25%", paddingLeft:"12.5%",justifySelf:"center", fontSize:"large"}}> 
								по 
							</Typography>
							<DateTimePicker
								disablePast
								value={travelDocument.expireDate}
								onChange={(val)=>setTravelDocument({...travelDocument, expireDate:val})}
								sx={{
									width : "25%",
									height: "50%",
									alignSelf: "flex-end"
								}}
								shouldDisableYear={(year) => year.isAfter(moment().add(2, 'years'))}
								/>
						</Stack>
						</LocalizationProvider>
					<Box sx = {{textAlign:"center", marginTop:"0.5rem"}}>
						<AlertChoise {...alertStatus}/>
					</Box>
					<Box sx = {{textAlign:"center", marginTop:"2rem"}}>
						<LoadingButton
							type ="submit"
							className="submitNewCathegory"
							color = "success"
							variant="contained"
							loading = {createResult.isLoading}
							>
							Добавить Документ
						</LoadingButton>
					</Box>
				</form>
			</Box>
		</Box>
	);
};
export default TravelDocumentTab;