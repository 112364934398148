/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import * as React from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Footer.css";
import {MDBFooter, MDBContainer, MDBRow, MDBCol} from "mdb-react-ui-kit";
import {  } from "mdb-react-ui-kit";
import logo from "../../pictures/logo.png";
import {Link} from "react-router-dom";
import SiteMapItem from "./FooterComponents/SiteMapItem";
import UsefulLinkItem from "./FooterComponents/UsefulLinkItem";
import ContactsItem from "./FooterComponents/ContactsItem";
import routeNames from "../../config/routeNames";
import {selectUserRole} from "../../features/auth/authSlice";
import { useAppSelector } from "../../app/hooks/hooks";


function Footer() {
	const role = useAppSelector(selectUserRole);

	return (
		<div className="footer">
			<MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
				<section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
					<div className='me-5 d-none d-lg-block'>
						{/* <span>Минсктранс в социальных сетях:</span> */}
					</div>
					<div>
						{/* <IconLink 	icon = {"telegram"} 
									itemRef = {"https://t.me/minsktrans_official"} />
						<IconLink 	icon = {"instagram"} 
									itemRef = {"https://www.instagram.com/minsktrans_official/"} />
						<IconLink 	icon = {"bell"} 
									itemRef = {"https://t.me/STS_transport_bot"} /> */}
					</div>
				</section>

				<section className=''>
					<MDBContainer className='text-center text-md-start '>
						<MDBRow className='mt-3'>
							<MDBCol md="4" lg="4" xl="3" className='mx-auto mb-4'>
								<h6 className='text-uppercase fw-bold mb-4 text-center'>
									<div className='footer-logo-container'>
										<Link to = "https://minsktrans.by/">
											<img src={logo} className='footer-logo' alt =''></img>
										</Link>
									</div>
									
										Государственное предприятие "Минсктранс"
									
								</h6>
								{/* <p>
									Режим работы филиала «Агенство Минсктранс»: <br/>

								</p> */}
							</MDBCol>

							<MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
								<h6 className='text-uppercase fw-bold mb-4'>Карта сайта</h6>
								{
									routeNames.map ((routeName) => {
										if(routeName.acceptedRoles.includes(role)){	
											return(
											routeName.routes.map((route, index) => {
												return (
													<SiteMapItem 	itemRef = {route.route}
																	itemText = {route.name}
																	key = {index}
																/>
												);
											})
											);
										}else{
											return null;
										}
									})
								}
							</MDBCol>

							<MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
								<h6 className='text-uppercase fw-bold mb-4'>Полезные ссылки</h6>
								<UsefulLinkItem  	itemRef = {"https://minsktrans.by/"}
													itemText = {"Главный сайт"}/>
								<UsefulLinkItem  	itemRef = {"https://t.me/minsktrans_official"}
													itemText = {"Telegram"}/>
								<UsefulLinkItem  	itemRef = {"https://www.instagram.com/minsktrans_official/"}
													itemText = {"Instagram"}/>
							</MDBCol>

							<MDBCol md="4" lg="4" xl="5" className='mx-auto mb-md-0 mb-4'>
								<h6 className='text-uppercase fw-bold mb-4'>Филиал "Агентство Минсктранс" государственного предприятия "Минсктранс"</h6>
								<ContactsItem 	itemText = {"просп. Партизанский, 6, 220033, г.Минск"}
												icon = {"home"}/>
								<ContactsItem 	itemText = {"agentstvo@minsktrans.by"}
												icon = {"envelope"}/>
								<ContactsItem 	itemText = {"lepeshko@ag.minsktrans.by (отдел обеспечения и контроля билетной продукции)"}
												icon = {"envelope"}/>							
								<ContactsItem 	itemText = {"(+375 17) 246-98-00 (приемная)"}
												icon="phone"/>
								<ContactsItem 	itemText = {<span>(+375 17) 246-98-45, (+375 17) 246-98-11, (+375 17) 246-98-48 (отдел обеспечения и контроля билетной продукции)</span>}
												icon="phone"/>
								<ContactsItem 	itemText = {"(+375 17) 246-98-32 (отдел обеспечения и контроля билетной продукции)"}
												icon="print"/>	
								<ContactsItem 	itemText = {<span>пн. – чт.: 08:00 – 17:00, пт.: 08:00 – 15:45, перерыв: 12:00 – 12:45, сб., вс.: выходные.</span>}
												icon="calendar"/>	
							</MDBCol>
						</MDBRow>
					</MDBContainer>
				</section>

				<div className='text-center p-4' style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}}>
					© 2023: 
					<a className='text-reset fw-bold' href='https://minsktrans.by/'>
						Государственное предприятие «Минсктранс»
					</a>
				</div>
			</MDBFooter>
		</div>
	);
}

export default Footer;