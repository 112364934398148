import React from "react";

 
type ProtectedContentProps = {
	isAllowed : boolean,
	role? : string,
	acceptedRoles? : Array<string>
};

const ProtectedContent = ({
  isAllowed,
  children,
  acceptedRoles = [],
  role
} : React.PropsWithChildren<ProtectedContentProps>) => {
	const isRoleAllowed = acceptedRoles.length === 0 ? true : acceptedRoles.includes(role ?? '');

	if (!isAllowed || !isRoleAllowed) {
		return <></>;
	}

	return children;
};
export default ProtectedContent;