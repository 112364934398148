/* eslint-disable react/prop-types */
import {Tooltip, Typography} from "@mui/material";
import {green} from "@mui/material/colors";
import {styled} from '@mui/material/styles';
import { TooltipProps, tooltipClasses} from '@mui/material/Tooltip';
import React, {  }  from "react";


let t : TooltipProps;
interface LabelWithTooltipProps {
	labelText : React.ReactNode,
	tooltipLabel : React.ReactNode, 
	placement? : typeof t.placement
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
  ))({
	[`& .${tooltipClasses.tooltip}`]: {
	  maxWidth: 500,
	},
  });

const LabelWithTooltip = ({labelText = "",tooltipLabel = "", placement = "right"} : LabelWithTooltipProps)=> {
	return (
		<CustomWidthTooltip 
			title ={tooltipLabel} 
			placement={placement}
			>
			<Typography 
				sx={
					{
						color:green[900], 
						opacity:0.8, 
						":hover":{
							color:green[900], 
							transition:"1s", 
							opacity:1
						}
					}
				}
				variant="inherit"
				component={'span'}
				> 
				{labelText} 
			</Typography>
		</CustomWidthTooltip>
	);
};
export default LabelWithTooltip;