import {Alert, Collapse} from "@mui/material";

export interface AlertChoiseProps {
	open : boolean,
	severity : 'error' | 'success' | 'warning' | 'info',
	message : string
}
const AlertChoise = ({open, severity, message = ""} : AlertChoiseProps)=> {
	return (
		<Collapse in = {open}>
			<Alert 
				severity={severity ?? 'info'} 
				sx={{
						fontSize:"medium"
					}}
				> 
				{message}
			</Alert>
		</Collapse>
	);
};
export default AlertChoise;