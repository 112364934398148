import { styled } from "@mui/material";
import React from "react";
import {NavLink} from "react-router-dom";

interface NavItemProps{
	to : string, 
	itemText : string | React.ReactNode
}

const StyledLink = styled(NavLink)`
	font-size: larger !important;
	padding: 0;
`

const NavItemText = styled('span')`
	:hover {	
		transition: 0.5s;
		text-shadow : 0px 0px 1px rgb(219, 218, 218) !important;
	}	
`;

const NavItem = ({to, itemText} : NavItemProps ) => {
	return(
		<li className="nav-item">
			<StyledLink
				to = {to} 
				className="nav-link" 
				>
				<NavItemText>
					{itemText}
				</NavItemText>
			</StyledLink>
		</li>
	);
};

export default NavItem;