import React, {} from "react";
import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import Stack from '@mui/material/Stack';
import './CountInput.css';
import {ClickAwayListener} from "@mui/material";

interface CountInputProps{
	count : number,
	setCount : (newCount: number) => void
}

const CountInput  = ({count, setCount} : CountInputProps)=>{
	const changeCount = (countVal : number) => {
		if(!count && count !== 0)
			return;
		let countNew = count + countVal;
		if(countNew < 1){
			return;
		}
		setCount(countNew);
	};
	const handleChange : React.ChangeEventHandler<HTMLInputElement> = (e) => {
		const {value} = e.target;
		if(+value >= 0 && +value < 1000){
			setCount(+value);
		}else if (value === ""){
			setCount(0);
		}
	};
	const checkCount = () => {
		if(count===0)
			setCount(1);
	};

	return (
		<Stack 
			direction = "row" 
			display={'block'} 
			spacing={2} 
			alignContent={"center"} 
			justifyContent={"center"}
			>
			<MinusIcon 
				className="iconCount" 
				onClick={()=>{changeCount(-1);}}
				/>
			<ClickAwayListener onClickAway={()=>checkCount()}>
					<input 	
						className="countLabel" 
						type="number" 
						value={count.toString()} 
						onChange={handleChange}
						/>
			</ClickAwayListener>
			<AddIcon 
				className="iconCount" 
				onClick={()=>{changeCount(+1);}}
				/>
		</Stack>
	);
};
export default CountInput;