/* eslint-disable @typescript-eslint/no-unused-vars */
import "./ContractAddedPage.css";
import React from "react";
import {clearLastAdded} from "../../../features/contract/contractSlice";
import {Navigate} from "react-router-dom";
import {alertStates, useAlert} from "../../../states/useAlert";
import AlertChoise from "../../../Components/AlertChoise";
import {Box, Button, IconButton, Tooltip} from "@mui/material";
import {Row} from "react-bootstrap";
import {ArrowBack, Download as DownloadIcon} from "@mui/icons-material";
import {alertDialogStates, useAlertDialog} from "../../../Components/AlertDialog/useAlertDialog";
import AlertDialog from "../../../Components/AlertDialog/AlertDialog";
import moment from "moment";
import {useDownloadContractMutation} from "../../../api/contractApi";
import {authExpired} from "../../../features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";

const ContractAddedPage = () => {
	const {contractId, contractNumber, contractYear,contractDate} = useAppSelector(state => state.contract.lastAddedContract);
	const dispatch = useAppDispatch();
	const onExpired = ()=> dispatch(authExpired());
	const {alertStatus, setAlertStatus} = useAlert();
	const {alertDialog, setAlertDialog} = useAlertDialog();
	const [startDownload] = useDownloadContractMutation();
	const confirmQuitDialog = alertDialogStates.customState({
		state : true, 
		dialogTitleText : "Вы точно желаете покинуть страницу?", 
		dialogMainText : (
			<span>
				Вы будете направлены к форме заполнения <strong>новой</strong> 
				заявки.После этого вернуться к данной странице будет невозможно.
			</span>
		),
		onOk : ()=>dispatch(clearLastAdded()), 
		onCancel : ()=> setAlertDialog(alertDialogStates.hiddenState)
	});
	
	function  onDowloadClicked(){
		setAlertStatus(false);
		startDownload({contractId, contractNumber, contractYear}).unwrap()
		.catch(error =>{
			const {status, message} = error;
			if(status === 401){
				onExpired();
			}
			setAlertStatus(true, alertStates.errorAlert, message);
		});
	}
	
	if(contractId === ""){
		return(
			<Navigate to={"/invoiceForm"}></Navigate>
		);
	}

	return (
		<>
			<div className='ContractAddedInfo'>
				<Box sx = {{margin:'auto'}}>
				<Row style={{padding:0, marginBlockEnd:'4vh'}}>
					{/* <Col md = {2}>
						<Tooltip title = {"Вернуться к оформлению заявки"}>
							<IconButton size="large" color="secondary" onClick={()=>setAlertDialog(confirmQuitDialog)}>
								<ArrowBack/>
							</IconButton>
						</Tooltip>
					</Col> */}
					<div className="col-md-12 text-center">
						<h1>Заявка № {`${contractNumber}−${contractYear}`} от {moment (contractDate).format('DD.MM.YYYY')} принята!</h1>
						<h5 className='text-center'>
						Скачайте и распечатайте счет
						</h5>
					</div>
				</Row> 
				
				<Box className="text-center" sx={{marginBlockEnd:'4vh'}}>
					<Button className="contractAddedActionButton"
							startIcon={<DownloadIcon />}
							type="button"
							variant="contained"
							onClick={onDowloadClicked}> 
								Скачать счёт 
					</Button>
					<AlertChoise {...alertStatus}/>
				</Box>
				
				<Box sx={{width:"60%", margin:"auto"}}>
					<p className='text-center'>
					Срок действия счета составляет 5 (пять) рабочих дней с даты его оформления.<br/>
					
					Отпуск товаров производится в течение 5 (пяти) рабочих дней со дня поступления денежных средств на расчетный счет Продавца,
					но не ранее 13:00 рабочего дня, следующего за днем поступления денежных средств на расчетный счет Продавца.
					</p>
				</Box>
				<AlertDialog {...alertDialog}/>
				</Box>
			</div>
			
		</>
	);
};
export default ContractAddedPage;