// import './TravelDocumentTableHeader.css';
import './TDTableHeaderStyles.css';
import {Box, IconButton, Paper, Stack, styled} from "@mui/material";
import {useState} from "react";
import {Col} from "react-bootstrap";
import {Delete} from "@mui/icons-material";
import {clearTableItems, sortByColumn} from "../../../../../features/contract/contractSlice";
import { useAppDispatch } from "../../../../../app/hooks/hooks";

const TableHeader = styled(Paper)`
	background-color: rgba(221, 237, 255 , 0.51);
	font-weight: 700;
	text-align: center;
	min-height: fit-content;
	height: fit-content;
	border-top-right-radius: 1.75rem !important; 
	border-top-left-radius: 1.75rem !important;
	border: 0.15rem;	
	border-style :double;
	border-color: rgba(82, 148, 224, 0.678);
	padding-top: 1rem;
	padding-bottom: 1rem;
	color: rgb(9 10 59 / 73%);
`


const TravelDocumentTableHeader = () =>{		
	const arrowUp = "\u{1F815}";
	const arrowDown = `\u{1F817}`;
	const ascendingSort = 1, descendingSort = -1, noSort = 0;
	const dispatch = useAppDispatch();
	const clearItems = ()=> dispatch(clearTableItems());
	const sortItemsByColumn = (colName : string, sortOrder : number)=>dispatch(sortByColumn({colName,sortOrder}));
	const [sortOrder, setSortOrder] = useState({
		count : noSort, 
		price : noSort, 
		totalPrice : noSort
	});
	
	const showSymbol = (sortOrder : number) =>{
		if(sortOrder === ascendingSort)
			return arrowDown;
		if(sortOrder === descendingSort)
			return arrowUp;
		return '';
	};

	const onColumnClick = (e : React.MouseEvent<HTMLElement, MouseEvent>)=>{
		const id = e.currentTarget.id;
		const sortKey = id as keyof typeof sortOrder
		if(sortOrder[sortKey] === undefined)
			return;
		let userOrder = sortOrder[sortKey];
		if(sortOrder[sortKey] === noSort)
			userOrder = ascendingSort;
		sortItemsByColumn(id, userOrder);
		setSortOrder({...sortOrder, [id]:-userOrder});
	};

	return (
		<TableHeader 
			elevation={2} 
			className="row"
			>
			<Stack 
				direction= "row"   
				justifyContent="center"
				alignItems="center"
				>
				<Col 
					xs = {1} 
					className="travelDocFormHeaderItem ItemBorder"
					> 
						<span>№</span>
				</Col>
				<Col 
					xs = {4}  
					className="travelDocFormHeaderItem ItemBorder"
					>
					Наименование
				</Col>
				<Col 
					xs = {2} 
					className="travelDocFormHeaderItem ItemBorder"
					>
					Срок действия
				</Col>
				<Col	
					xs = {1} 
					className="travelDocFormHeaderItem ItemBorder"
					onClick={onColumnClick}
					id="count"
					>
					Количество<span className="sortOrderSymbol">{ showSymbol(sortOrder.count)}</span>
				</Col>
				<Col 	
					xs = {1} 
					className="travelDocFormHeaderItem ItemBorder  textSmall"
					onClick={onColumnClick}	
					>
					<Box 
						id="price" 
						sx={{
							maxWidth:"90%", 
							textAlign:"center"
						}}
						>
						Стоимость<br/> 
						за единицу<br/> 
						с НДС,<br/>
						руб.
						</Box> 
						<span className="sortOrderSymbol">
							{showSymbol(sortOrder.price)}
						</span>
				</Col>
				<Col 	
					xs = {1} 
					className="travelDocFormHeaderItem ItemBorder textSmall"
					onClick={onColumnClick}
					id="totalPrice"
					>
					Стоимость<br/> с НДС,<br/>руб.{" "}
					<span className="sortOrderSymbol">
						{showSymbol(sortOrder.totalPrice)}
					</span>
				</Col>
				<Col 
					xs = {1} 
					className="travelDocFormHeaderItem ItemBorder textSmall"
					>
					НДС<br/>
					сумма,<br/>
					(ставка<br/>
					20%),<br/>
					руб.
				</Col>
				<Col 
					xs = {1} 
					className="travelDocFormHeaderItem ItemBorder"
					> 
					<IconButton 
						color="error" 
						onClick={clearItems}
						>
						<Delete/>
					</IconButton>
				</Col>
			</Stack>
		</TableHeader>
	);
};
export default TravelDocumentTableHeader;