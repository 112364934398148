import './TravelDocumentTableItem.css';
import {Col} from "react-bootstrap";
import React from "react";
import {Box, IconButton, Paper, Stack} from "@mui/material";
import CountInput from '../../../../../Components/Inputs/CountInput';
import SelectTravelDocType from "../../../../../Components/TravelDocumentComponents/SelectForm/SelectTravelDocType";
import LifetimeForm, { LifetimeFormProps } from "../../../../../Components/TravelDocumentComponents/LifeTimeForm/LifetimeForm";
import {Delete} from "@mui/icons-material";
import {changeItemCount, deleteTableItem, selectTableItem, selectTravelDocumentDescription} from "../../../../../features/contract/contractSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";


const TravelDocumentItem = ({showNumber = '-', itemNumber} : {showNumber : string, itemNumber : number}) =>{
	const dispatch = useAppDispatch();
	const {count = 1, price = null, totalPrice = null, travelDocumentId = -1} = useAppSelector(state => selectTableItem(state, itemNumber)) ?? {};
	const travelDocDecsription = useAppSelector(state => selectTravelDocumentDescription(state, travelDocumentId ?? -1));
	const changeCount = (newCount:number) => dispatch(changeItemCount({newCount, itemNumber}));
	const deleteThis = () => dispatch(deleteTableItem(itemNumber));
	const VAT = totalPrice ? (Math.round(totalPrice/6*100)/100).toFixed(2) : '-' ;
	
	return (
		<Paper 
			className="travelDocFormItemRow row" 
			elevation={3} 
			>
		<Stack  
			direction= "row"   
			justifyContent="center"
			alignItems="center"
			>
			<Col 	
				xs = {1} 
				className="travelDocFormItemCol"
				>
				<strong>
					{showNumber}
				</strong>
			</Col>
			<Col 	
				xs = {4}  
				className="travelDocFormItemCol "
				>
				<Box sx={{maxWidth:"95%"}}>
					<SelectTravelDocType itemNumber={itemNumber}/>
				</Box>
			</Col>
			<Col 	
				xs = {2} 
				className="travelDocFormItemCol "
				>
				<LifetimeForm {...({travelDocument : travelDocDecsription} as LifetimeFormProps)}/>
			</Col>
			<Col 	xs = {1} 
					className="travelDocFormItemCol ">
				<CountInput  	
					count={count} 
					setCount = {changeCount}/>
			</Col>
			<Col 	
				xs = {1} 
				className="travelDocFormItemCol "
				>
				{price ? (price/100)?.toFixed(2) : '-'}
			</Col>
			<Col 	
				xs = {1} 
				className="travelDocFormItemCol "
				>
				{totalPrice?.toFixed(2) ?? '-'}
			</Col>
			<Col 
				xs = {1}  
				className="travelDocFormItemCol "
				>
				{VAT}
			</Col>
			<Col 	
				xs = {1} 
				className="travelDocFormItemCol "
				> 
				<IconButton 
					color="error" 
					onClick={deleteThis}
					>
					<Delete/>
				</IconButton>
			</Col>
		</Stack>
		</Paper>
	);
};
export default TravelDocumentItem;