import BasicModal from "./ModalAlert";
import {expireCleared, selectIsAuthExpired} from "../../features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";

const AuthExpireModal = () => {
	const dispatch = useAppDispatch();
	const isExpired = useAppSelector(selectIsAuthExpired);
	const onExpireShown =()=> dispatch(expireCleared());
	return <BasicModal 	
				isOpen={isExpired}
				header = "Cрок действия авторизации истек"
				mainText = "для продолжения работы повторно войдите в систему"
				onClose= {()=>{
					onExpireShown();
				}}
				/>;
};
export default AuthExpireModal;