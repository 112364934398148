import React, { PropsWithChildren } from "react";

type ToggleProps = {
	visible : boolean
}

export default function ToggleVisibility({visible, children} : PropsWithChildren<ToggleProps>) {
	if(visible){
		return (
			<>
				{children}
			</>
		);
	}
}