import React, {  }  from "react";
import {useState} from "react";
import {Box, TextField, Typography, Snackbar, Alert, Divider, AlertColor, SnackbarCloseReason} from "@mui/material";
import {Col, Row} from "react-bootstrap";
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import moment from "moment";
import TravelDocumentsDisplayTable from "./components/TravelDocumentsDisplayTable";
import {useLocation} from "react-router-dom";
import {contractApi} from "../../../api";
import {LoadingButton} from "@mui/lab";

const HeaderGrid = styled(Grid)(() => ({
	//backgroundColor: 'rgb(255 206 116 / 70%)',
	textAlign: 'left',
	fontWeight:'bold',
	fontSize:'1.3rem',
	paddingBlock : 0,
	marginBlock: '0.5vh'
}));

const DataGrid = styled(Grid)(() => ({
	backgroundColor: 'rgb(255 206 116 / 40%)',
	textAlign: 'center',
	borderBottom : '1px solid',
	height : 'min-content',
	color: "black",
	paddingBlock : 0,
	marginBlock: '0.5vh',
	borderRadius: '10px',
	//boxShadow: "3px 3px 3px 3px rgb(255 206 116 / 20%)",
}));
const LabelGrid = styled(Grid)(() => ({
	//backgroundColor: 'rgb(255 206 116 / 70%)',
	textAlign: 'left',
	borderBottom : 'none',
	height : 'min-content',
	paddingBlock : 0,
	marginBlock: '0.5vh',
	alignSelf : 'center'
}));

const GridRow = ({children, ...gridParams } : React.PropsWithChildren<{}>) =>{
	return (
		<Grid 
			container 
			xs = {12} 
			{...gridParams}
			>
			{children}
		</Grid>
	);
};

const defaultUserDataState = {
	registrationNumber: "-",
	email: "-",
	customerFullName: "-",
	legalAddress: "-",
	mailingAddress: "-",
	checkingAccount: "-",
	bankCode: "-",
	bankName: "-",
	chiefFullName: "-",
	chiefDocument: "-",
	phoneNumbers : ["-"],
};
const defaultContractDataState = {
	contractNumber : "",
	contractYear : "",
	contractDate : "-",
	user : defaultUserDataState,
	travelDocuments:[
		{count : 0, price : 0, units : '', name: "Введите поисковой запрос"}
	]
};

const InvoiceSearchPage = ()=> {
	const [searchContract, searchResult] = contractApi.useLazySearchContractQuery();
	const location = useLocation();
	const [searchNumber, setSearchNumber] = useState(location?.state?.contractSearchString ?? '');
	const [contractData, setContractData] = useState(defaultContractDataState);
	const [open, setOpen] = React.useState(false);
	const [snackbar, setSnackbar] = useState({type : 'success', text : ''});
	
	const submitForm = (e : React.FormEvent<HTMLFormElement> | undefined)=>{
		e?.preventDefault();
		setContractData(defaultContractDataState);
		const contractNumber  = searchNumber.slice(0, searchNumber.indexOf('-')), contractYear = searchNumber.slice(searchNumber.indexOf('-')+1);
		searchContract({contractNumber,contractYear}).unwrap()
		.then(rawContract => {
			setContractData({
				...rawContract, 
				contractDate :  moment(rawContract.contractDate).format('LL')
			});		
		})
		.catch(error => {
			console.log(error);
			setSnackbar({type : 'error', text : error.message});
			setOpen(true);
		});
	};

	React.useEffect (()=>{
		if(location?.state?.contractSearchString && location?.state?.contractSearchString !== '')
			submitForm(undefined);
		window.scrollTo(0,0);
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	,[location?.state?.contractSearchString]);

	const handleChange = (e : React.ChangeEvent<HTMLInputElement>) =>{
		setSearchNumber(e.target.value); 
		if(contractData !== defaultContractDataState){
			setContractData(defaultContractDataState);
		}
	};

	const handleClose = (event : Event | React.SyntheticEvent<any, Event>, reason : SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	return (
		<>
			<Typography variant="h4" align="center" sx={{marginBottom:"1vh"}}>
					Поиск заявки по номеру:
			</Typography>
			<Box 	component="form"
				id = 'searchUserForm'
				onSubmit={submitForm}>
				<Row style={{display:"flex"}}>
					<Col md = {10}>
						<TextField 	
							label="Номер заявки" 
							placeholder="xxx-20xx" 
							type="search"
							required
							inputProps={{
								maxLength : 20,
								minLength : 6,
							}}
							value={searchNumber}
							onChange={handleChange}
							fullWidth 
							/>
					</Col>
					<Col md = {2}>
						<LoadingButton 
							type="submit"
							variant="outlined"
							sx = {{fontSize:"large", width:"100%", height:"100%"}}
							loading = {searchResult.isFetching}
							>
							Поиск
						</LoadingButton>
					</Col>
				</Row>

				<Grid 	container 
						spacing={2}
						sx={{marginTop:"2vh"}}>
					
				</Grid>


			</Box>
			
			<Typography variant="h4" align="center" sx={{marginBottom:"1vh"}}>
					Заявка № {`${contractData.contractNumber}-${contractData.contractYear}`}
			</Typography>
			<Grid 	container 
					spacing={2}
					sx={{marginTop:"2vh"}}
					alignItems="flex-start"
					>
				<Grid 
					container 
					spacing={1} 
					xs ={8} 
					>
					<GridRow>
						<HeaderGrid 
							xs = {3} 
							sx={{lineHeight:'1.2rem', fontSize:"1.1rem"}}
							>
							ПЛАТЕЛЬЩИК<br/>
							(ПОКУПАТЕЛЬ):
						</HeaderGrid>
						<DataGrid 
							xs = {9} 
							sx={{alignSelf:'end'}}
							> 
							{contractData.user.customerFullName}
						</DataGrid>
					</GridRow>
					<GridRow>
						<LabelGrid xs ={1}>
							Адрес
						</LabelGrid>
						<DataGrid xs = {11}>
							{contractData.user.legalAddress}
						</DataGrid>
					</GridRow>
					<GridRow>
						<LabelGrid xs ={3}>
							Электронный адрес
						</LabelGrid>
						<DataGrid xs = {9}>
							{contractData.user.email}
						</DataGrid>
					</GridRow>
					<GridRow>
						<LabelGrid xs ={1}>
							УНП
						</LabelGrid>
						<DataGrid xs = {2}>
							{contractData.user.registrationNumber}
						</DataGrid>
						<LabelGrid 
							xs ={3} 
							sx={{fontSize:'medium', textAlign:'center'}}
							>
							Расчетный счет
						</LabelGrid>
						<DataGrid xs = {6}>
							{contractData.user.checkingAccount}
						</DataGrid>
					</GridRow>
					<GridRow>
						<LabelGrid xs ={2}>
							Банк
						</LabelGrid>
						<DataGrid xs = {6}>
							{contractData.user.bankName}
						</DataGrid>
						<LabelGrid 
							xs ={1} 
							sx={{textAlign:'center'}}
							>
							БИК
						</LabelGrid>
						<DataGrid xs = {3}>
							{contractData.user.bankCode}
						</DataGrid>
					</GridRow>
					<GridRow>
						<LabelGrid xs ={3}>
							Контактные телефоны
						</LabelGrid>
						<DataGrid xs = {9}>
							{contractData.user.phoneNumbers.reduce((prev, curr)=>`${prev} ${curr}`,'')}
						</DataGrid>
					</GridRow>
				</Grid>
				<Grid 
					container 
					spacing={1} 
					xs ={4}
					>
					<HeaderGrid xs = {12}>
						СЧЕТ
					</HeaderGrid>
					<GridRow>
						<LabelGrid xs = {3}>
							от
						</LabelGrid>
						<DataGrid xs = {9}>
							{contractData.contractDate}
						</DataGrid>
					</GridRow>
					<GridRow>
						<LabelGrid xs = {3}>
							№
						</LabelGrid>
						<DataGrid xs = {9}>
							{`${contractData.contractNumber}-${contractData.contractYear}`}
						</DataGrid>
					</GridRow>
				</Grid>
			</Grid>
			<Divider sx={{
				marginBlock:"3vh", 
				borderColor:'rgba(0, 0, 0, 0.45)'
				}}/>

			<TravelDocumentsDisplayTable travelDocuments={contractData.travelDocuments}/>
			<Snackbar 
				open={open} 
				autoHideDuration={5000} 
				onClose={handleClose}
				>
				<Alert 
					severity={snackbar.type as AlertColor} 
					sx={{width: '100%'}}
					>
					{snackbar.text}
				</Alert>
			</Snackbar>
		</>
	);
};
export default InvoiceSearchPage;
