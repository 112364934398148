import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap-icons/font/bootstrap-icons.css";
import store from "./app/store";
import {Provider} from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {beBY as coreBgBG} from "@mui/material/locale";
import {beBY as pickersBgBG} from "@mui/x-date-pickers/locales";
import {ruRU} from "@mui/x-data-grid";

const rootElement = document.getElementById("root");
if(rootElement){
	const root = ReactDOM.createRoot(rootElement);
	const theme = createTheme(
		{
		palette: {
			primary: { main: '#1976d2' },
		},
		},
		pickersBgBG, // x-date-pickers translations
		coreBgBG, // core translations
		ruRU
	);
	root.render(
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<App />
			</Provider>
		</ThemeProvider>
	);
}else{
	const root = ReactDOM.createRoot(document.createElement('div'));
	root.render(
		<>Fatal Error!</>
	);
}