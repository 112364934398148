exports.itemsDescription = [
	{
		key : 1,
		label : 'УНП',
		itemName : 'registrationNumber',
		copy : null
	},
	{
		key : 2,
		label : 'Электронная почта',
		itemName : 'email',
		copy : null
	},
	{
		key : 3,
		label : 'Наименование юридического лица',
		itemName : 'customerFullName',
		copy : null
	},
	{
		key : 4,
		label : 'ФИО руководителя',
		itemName : 'chiefFullName',
		copy : null
	},
	{
		key : 5,
		label : 'Документ, на основании которого действует руководитель',
		itemName : 'chiefDocument',
		copy : null
	},
	{
		key : 6,
		label : 'Юридический адрес',
		itemName : 'legalAddress',
		copy : null
	},
	{
		key : 7,
		label : 'Почтовый адрес',
		itemName : 'mailingAddress',
		copy : null
	},
	{
		key : 8,
		label : 'Расчетный счёт',
		itemName : 'checkingAccount',
		copy : null
	},
	{
		key : 9,
		label : 'Банк',
		itemName : 'bankName',
		copy : null
	},
	{
		key : 10,
		label : 'Телефонные номера',
		itemName : 'phoneNumbersLabel',
		copy : 'phoneNumbersCopy'
	},
	{
		key : 11,
		label : 'БИК',
		itemName : 'bankCode',
		copy : null
	},
	{
		key : 12,
		label : 'Cтатус аккаунта',
		itemName : 'statusName',
		copy : null
	},
	{
		key : 13,
		label : 'Дата регистрации в системе',
		itemName : 'registrationDate',
		copy : null
	},
];

