import React from "react";

type SiteMapItemProps ={
	itemRef : string,
	itemText : string | React.ReactNode
}

const SiteMapItem = ({itemRef = "/", itemText =""} : SiteMapItemProps) =>{
	return (
		<p>
			<a 
				href= {itemRef} 
				className='text-reset'
				>
				{itemText}
			</a>
		</p>
	);
};

export default SiteMapItem;