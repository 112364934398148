import {useState} from "react";
import { AlertDialogProps } from "./AlertDialog";

const hiddenState : AlertDialogProps = {
	state : false,
	dialogTitleText : "",
	dialogMainText : "",
	onCancel : ()=>{},
	onOk : ()=>{},
	OkText : null,
	cancelText : null
};
const customState =({state = false, dialogTitleText, dialogMainText = "", onCancel = ()=>{}, onOk = ()=>{}, OkText = 'Продолжить', cancelText = 'Отменить'}: AlertDialogProps) : AlertDialogProps=>({
	state,
	dialogTitleText,
	dialogMainText,
	onCancel,
	onOk,
	OkText,
	cancelText
});

export const alertDialogStates = {hiddenState, customState};

export function useAlertDialog(initState = hiddenState) {  
	const [alertDialog, setAlertDialog] = useState({
		...initState
	});
	const saveAlert = (state = hiddenState) => {
		setAlertDialog(state);
	};
	return {
		alertDialog,
		setAlertDialog : saveAlert
	};
}

