import {useState} from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {Box} from "@mui/material";

type TransferListItem = string;
type TransferListItems = Array<TransferListItem>;

type SelectAllTransferListProps ={
	choises : TransferListItems ,
	choisen : TransferListItems ,
	setChoisen : (newChoisen : TransferListItems ) => void,
	setChoises : (newChoises : TransferListItems ) => void,
}

function not( a : TransferListItems, b : TransferListItems ){
	return a.filter((value) => b.indexOf(value) === -1);
}

function intersection( a : TransferListItems, b : TransferListItems ){
	return a.filter((value) => b.indexOf(value) !== -1);
}

function union( a : TransferListItems, b : TransferListItems ){
	return [...a, ...not(b, a)];
}

export default function SelectAllTransferList({choises, choisen, setChoisen = ()=>{}, setChoises = ()=>{}} : SelectAllTransferListProps) {
	const [checked, setChecked] = useState([] as TransferListItems);
	const [left, setLeft] =	[choises, setChoises]; 
	const [right, setRight] = [choisen, setChoisen];
	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, right);

	const handleToggle = (value : TransferListItem) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];
		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const numberOfChecked = (items : TransferListItems) => intersection(checked, items).length;

	const handleToggleAll = (items  : TransferListItems) => () => {
		if (numberOfChecked(items) === items.length) {
		setChecked(not(checked, items));
		} else {
		setChecked(union(checked, items));
		}
	};

	const handleCheckedRight = () => {
		setRight(right.concat(leftChecked));
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		setLeft(left.concat(rightChecked));
		setRight(not(right, rightChecked));
		setChecked(not(checked, rightChecked));
	};

	function СustomList({title, items} : {title : string, items : TransferListItems}){
		return(
			<Card>
				<CardHeader
					sx={{px: 2, py: 1}}
					avatar={
					<Checkbox
						onClick={handleToggleAll(items)}
						checked={numberOfChecked(items) === items.length && items.length !== 0}
						indeterminate={
						numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
						}
						disabled={items.length === 0}
						inputProps={{
						'aria-label': 'все опции выбраны',
						}}
					/>
					}
					title={title}
					subheader={`${numberOfChecked(items)}/${items.length} выбрано`}
				/>
				<Divider />
				<List
					sx={{
						width: "100%",
						height: "20rem",
						bgcolor: 'background.paper',
						overflow: 'auto',
					}}
					dense
					component="div"
					role="list"
				>
					{items.map((value) => {
					const labelId = `transfer-list-all-item-${value}-label`;

					return (
						<ListItem
							key={value}
							role="listitem"
							onClick={handleToggle(value)}
							>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{
										'aria-labelledby': labelId,
									}}
									/>
							</ListItemIcon>
							<ListItemText 
								id={labelId} 
								primary={`${value}`} 
								/>
						</ListItem>
					);
					})}
				</List>
			</Card>
		);
	}

	if(choises.length === 0 && choisen.length === 0){
		return (<>
			<Box sx = {{textAlign:"center"}}>
				<h2>Идет загрузка данных с сервера....</h2>
			</Box>
		</>);
	}

	return (
		<Grid 
			container 
			spacing={2} 
			justifyContent="center" 
			alignItems="center"
			>
		<Grid item>
			<СustomList 
				title={'Доступные виды транспорта'} 
				items={left}
				/>
		</Grid>
		<Grid item>
			<Grid 
				container
				direction="column" 
				alignItems="center"
				>
			<Button
				sx={{my: 0.5}}
				variant="outlined"
				size="small"
				onClick={handleCheckedRight}
				disabled={leftChecked.length === 0}
				aria-label="move selected right"
				>
				&gt;
			</Button>
			<Button
				sx={{my: 0.5}}
				variant="outlined"
				size="small"
				onClick={handleCheckedLeft}
				disabled={rightChecked.length === 0}
				aria-label="move selected left"
				>
				&lt;
			</Button>
			</Grid>
		</Grid>
			<Grid item>
				<СustomList 
					title={'Выбранные виды транспорта'} 
					items={right} 
					/>
			</Grid>
		</Grid>
	);
}