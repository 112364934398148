import "./PasswordChangeCodeInputForm.css";
import React, {useCallback, useState} from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {FormInput, formInputProps, Timer, AlertChoise, BasicModal, SubmitCancelForm} from "../../../Components";
import ToggleVisibility from "../../../utils/ToggleVisability";
import {useAlert, alertStates} from "../../../states/useAlert";
import {Box} from "@mui/material";
import {clearPasswordChangeData, selectIsPasswordChangeCodeSend, selectPasswordChangeRequester, setPasswordChangeCodeSend, setPasswordChangeToken} from "../../../features/passwordChange/passwordChangeSlice";
import {userApi} from "../../../api/userApi";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";

const timerSessionItemName = "passChangeCodeTimerValue";
const timerStartValue : [number, number] = [2,30];

const PasswordChangeCodeInputForm = ()=> {
	const dispatch = useAppDispatch();
	const registrationNumber = useAppSelector(selectPasswordChangeRequester);
	const clearSession = () => dispatch(clearPasswordChangeData());
	const codeSend = useAppSelector(selectIsPasswordChangeCodeSend);
	const setCodeSend = useCallback((value : boolean)=>dispatch(setPasswordChangeCodeSend(value)),[dispatch]);
	const navigate = useNavigate();
	const modalDefault =React.useMemo(()=> {return {state : false, header : "", mainText:"", onClose : ()=>{}};},[]);
	const modalNoUserError = React.useMemo( ()=> ({ 
		state : true, 
		header : "Ошибка. Пользователя не существует!", 
		mainText:`Пользователя с УНП ${registrationNumber} не существует`, 
		onClose : ()=>{ 
			setModal(modalDefault); 
			navigate("/login");
		}
	}),[modalDefault,navigate, registrationNumber]);
	const modalNoRegNumber = React.useMemo( ()=> ({ 
		state : true, 
		header : "Ошибка. Не указан УНП пользователя!", 
		mainText:"Для изменения пароля воспользуйтесь кнопкой на странице авторизации.", 
		onClose : ()=>{ 
			setModal(modalDefault); 
			navigate("/login");
		}
	}),[modalDefault,navigate]); 

	const location = useLocation();
	const [over, setOver] = React.useState(false);
	const [modal, setModal] = React.useState(modalDefault);
	const [userInputCode, setUserInputCode] = useState("");
	const {alertStatus, setAlertStatus} = useAlert();
	const [againCodeStatus, setAgainCodeStatus] = useState({isRequested : false, labelText : "Нажмите сюда для отправки нового кода на электронную почту"}); 
	const setPassChangeToken = (token : string) => dispatch(setPasswordChangeToken(token));
	const [queryPassRestoreCode, queryResult] = userApi.useLazyQueryPassRestoreCodeQuery();
	const [checkCode, checkResult] = userApi.useLazyCheckPassRestoreCodeQuery();
	React.useEffect (()=>{
		if(!codeSend){
			if(!registrationNumber){
				setAlertStatus(true, alertStates.errorAlert, "Ошибка. Не указан аккаунт пользователя");
				setModal(modalNoRegNumber);
				sessionStorage.clear();
				return;
			}
			setCodeSend(true);
			queryPassRestoreCode({registrationNumber}).unwrap()
			.then(message =>{
				setAgainCodeStatus({isRequested : false, labelText :  "Нажмите сюда для отправки нового кода на электронную почту"});
				setOver(false);
				setAlertStatus(true, alertStates.successAlert, message);
			})
			.catch(error => {
				const {status, message} = error;
				console.log(error);
				setAgainCodeStatus({isRequested : false, labelText :  "Нажмите сюда для отправки нового кода на электронную почту"});
				setAlertStatus(true, alertStates.errorAlert, message);
				if(status === 204){
					setModal(modalNoUserError);
					sessionStorage.clear();
				}
			});
		}
	},[codeSend, modalNoRegNumber, modalNoUserError, queryPassRestoreCode, registrationNumber, setAlertStatus, setCodeSend]);

	if(!location?.state?.isFromLogin || !registrationNumber){
		console.log(location?.state?.isFromLogin);
		console.log(registrationNumber);
		return(
			<Navigate to="/notFound" replace={true} />
		);
	}

	const submitForm = (e : React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		checkCode({registrationNumber, userInputCode}).unwrap()
		.then(res =>{
				clearSession();
				sessionStorage.removeItem(timerSessionItemName);
				setPassChangeToken(res.token);
				navigate("/passwordChangeForm", {replace : true});
			}
		).catch(error => {
			const {message, status} = error;
			setAlertStatus(true, alertStates.errorAlert, message);
			if(status !== 403)
				console.log(error);
		});
	};
    
	const cancelForm = (e : React.FormEvent<HTMLFormElement>)=>{
		e.preventDefault();
		sessionStorage.clear();
		navigate("/", {replace : true});
	};

	const sendCode = () => {
		if(!againCodeStatus.isRequested){
			setCodeSend(false);
			setAgainCodeStatus({isRequested : !againCodeStatus.isRequested, labelText : "Ожидание повторной отправки кода"});
		}
	};

	const handleChange = (e : React.ChangeEvent<HTMLInputElement>)=>{
		setUserInputCode(e.target.value);
	};

	return (
		<form 
			id = "passChangeForm"	
			onSubmit={submitForm} 
			onReset={cancelForm} 
			>
			<div className="row">
				<div className="col-md-12 text-center">
					<h1 className='passChangeHeader'>
						Изменение пароля
					</h1>
					<p className='passChangeText'> 
						Вам на почту отправлено письмо с кодом. Для изменения пароля введите код из письма
					</p>
				</div>
			</div>
			<Box sx={{width:"50%", margin:"auto"}}>
					<FormInput 	
						label={"Код из письма"}
						inputProps={ 
							formInputProps({
								type : "text",
								name : "passChangeCode",
								onChange : handleChange,
								value : userInputCode,
								minLength : 8,
								maxLength : 8,
								placeholder : "Код из письма"
							})
						}
						/>
					<Box sx={{marginTop:"0.5rem"}}>	
						<AlertChoise {...alertStatus}/>		
					</Box>
			</Box>
			<ToggleVisibility visible={!over}>
				<div className="row">            
					<div className='sendCodeAgainText'>
						Код не пришёл? Запросите повторный код через:
						<strong> 
							<Timer 	
								startValue={timerStartValue} 
								whenOver={()=>(setOver(true))}
								sessionItemName={timerSessionItemName}
								/>
						</strong>
					</div>
				</div>
			</ToggleVisibility>
			<ToggleVisibility visible = {over}>
				<div className="row">
					<button 
						className='sendCodeAgainButton' 
						type= 'button' 
						onClick={sendCode}
						>
						{againCodeStatus.labelText}
					</button>
				</div>
			</ToggleVisibility>
			<Box sx={{marginTop:"1rem"}}>			
				<SubmitCancelForm 	
					resetText= 'Отменить' 
					submitText= 'Отправить код' 
					submitLoading = {queryResult.isFetching || checkResult.isFetching}
					submitLoadingText={ queryResult.isFetching ? 'Отправка кода...' : 'Проверка кода...'}
					/>
			</Box>
			<BasicModal header = {modal.header } mainText= {modal.mainText} isOpen={modal.state} onClose={modal.onClose}></BasicModal>
		</form>
	);
};
export default PasswordChangeCodeInputForm;