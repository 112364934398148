import React, {useState, useEffect}   from "react";

type TimerProps = {
	startValue : [number, number], 
	whenOver : ()=>void, 
	sessionItemName? : string | null
}
const Timer = ({startValue, whenOver, sessionItemName = null} : TimerProps)=> {
    
	const [over, setOver] = useState(false);
	const [[m, s], setTime] = useState(getTime());

	useEffect(() => {
		const tick = () => {
			if ( over) return;
			if (m === 0 && s === 0) {
              
				setOver(true);
			} else if (s === 0) {
				setTime([m - 1, 59]);
			} else {
				setTime([m, s - 1]);
			}
			if(sessionItemName)
				sessionStorage.setItem(sessionItemName, JSON.stringify([m,s]));
		};
		if(over){
			whenOver();
			if(sessionItemName)
				sessionStorage.removeItem(sessionItemName);
		}
		const timerID = setInterval(() => tick(), 1000);
		return () => clearInterval(timerID);
	}, [s,m,over, whenOver, sessionItemName]);
    
	function getTime(){
		let time =  sessionItemName ? sessionStorage.getItem("sendActivationCodeTime") : null;
		if(!time){
			return startValue;
		}else{
			time = JSON.parse(time) as any;
			return [ (time as any)?.m as number | 0, (time as any)?.s as number | 0 ];
		}
	}

	return (
		<>{`${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`}</>
	);
};

export default Timer;