import './TravelDocumentTabelFooter.css';
import {Paper, Stack} from "@mui/material";
import React from "react";
import {Col} from "react-bootstrap";
import {selectTotalCount, selectTotalPrice, selectTotalVAT} from "../../../../../features/contract/contractSlice";
import { useAppSelector } from "../../../../../app/hooks/hooks";


const TravelDocumentTableFooter = () =>{	
	const showNumber = (num : number) => num !== 0 ? num : '-';
	const totalCount = useAppSelector(selectTotalCount);
	const totalPrice = useAppSelector(selectTotalPrice);
	const totalVAT = useAppSelector (selectTotalVAT);

	return (
		<Paper elevation={2} className="travelDocFormFooter row">
				<Stack 
					direction= "row"   
					justifyContent="center"
					alignItems="center"
					>
					<Col 
						xs = {1} 
						className="travelDocFormFooterItem "
						>
						<strong>
							Всего
						</strong>
					</Col>
					<Col 
						xs = {6} 
						className="travelDocFormFooterItem ItemBorder"
						/> 
					<Col 
						xs = {1} 
						className="travelDocFormFooterItem ItemBorder"
						>
						<strong>
							{showNumber(totalCount)}
						</strong>
					</Col>
					<Col 
						xs = {1} 
						className="travelDocFormFooterItem ItemBorder"
						/>
					<Col 
						xs = {1} 
						className="travelDocFormFooterItem ItemBorder"
						> 
						<strong>
							{showNumber(totalPrice)}
						</strong> 
					</Col>
					<Col 
						xs = {1} 
						className="travelDocFormFooterItem ItemBorder"
						> 
						<strong>
							{showNumber(totalVAT)}
						</strong>
					</Col>
					<Col 
						xs = {1} 
						className="travelDocFormFooterItem ItemBorder"
						/>
				</Stack>
			</Paper>
	);
};
export default TravelDocumentTableFooter;