import {useCallback, useState} from "react";
import { AlertChoiseProps } from "../Components/AlertChoise/AlertChoise";

let AlertChoisePropsVar : AlertChoiseProps;
type AlertSeverity = typeof AlertChoisePropsVar.severity;
export const alertStates:{errorAlert: AlertSeverity, successAlert: AlertSeverity, passRepeatAlert : AlertSeverity, dangerAlert : AlertSeverity, infoAlert : 'info'} 
= {errorAlert: 'error', successAlert: 'success', passRepeatAlert : 'warning', dangerAlert : 'warning', infoAlert : 'info'};
							


export function useAlert(defaultAlert = alertStates.infoAlert) {  
	const [alertStatus, setAlertStatus] = useState({
		open : false,
		severity: defaultAlert,
		message : "",
	}as AlertChoiseProps);
	const callback = useCallback((open : boolean, severity = alertStatus.severity, message = "") => setAlertStatus({open, severity, message}),[alertStatus.severity]);
	return {
		alertStatus,
		setAlertStatus : callback
	};
}

