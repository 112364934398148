import axios from "axios";
import config from "./config/config";

export default axios.create({
	baseURL: config.env.apiBase,
	headers: {
	} 
});

const fileHttp =  axios.create({
	baseURL: '/',
	headers: {
	} 
});
export {fileHttp};

const customApiConnection = (baseURL) => axios.create({baseURL});
export {customApiConnection};