import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from "prop-types";
import {passwordStrength} from 'check-password-strength';
import generation from "password-generation";
import {Grid, IconButton} from "@mui/material";
import {Autorenew, ContentCopy} from "@mui/icons-material";
import {useClipboard} from "use-clipboard-copy";
import {userApi} from "../../../api";

type PasswordChangeDialogProps = {
	open : boolean,
	setOpen : (open : boolean) => void,
	setSnackbar : (type : string, text : string) => void,
	userRegNumber : string,
};

export default function PasswordChangeDialog({open, setOpen, setSnackbar, userRegNumber} : PasswordChangeDialogProps) {

const [password, setPassword] = React.useState ('');
const [passwordRepeat, setPasswordRepeat] = React.useState('');
const [generatedPass, setGeneratedPass] = React.useState('');
const clipboard = useClipboard();
const [updatePass] = userApi.useAdminChangeUserPasswordMutation();

const handleClose = () => {
	setPassword('');
	setPasswordRepeat('');
	setGeneratedPass('');
	setOpen(false);
};

const handleSubmit = () => {
	if(password !== passwordRepeat){
		setSnackbar('warning', 'Пароли не совпадают');
		return;
	}
	if(password.length < 10){
		setSnackbar('warning', 'Пароли должен содержать не менее 10 символов');
		return;
	}
	if(passwordStrength(password).value !== 'Strong'){
		setSnackbar('warning', "Слишком простой пароль. Пароль должен иметь длину не менее 10 символов, содержать хотя бы 1 спецсимвол, большую букву и цифру.");
		return;
	}
	updatePass({newPassword : password, registrationNumber : userRegNumber}).unwrap()
	.then(() =>{
		setSnackbar('success', 'Пароль изменен');
		setPassword('');
		setPasswordRepeat('');
		setGeneratedPass('');
		setOpen(false);
	})
	.catch(error => {
		console.log(error);
		setSnackbar('error', error.message);
	});
};
const generatePassClick = () => {
	const newPass = generation([10, 12], {specials: 3, nums: 2, uppers: 3, lowers: 2, length : [10,12]});
	setGeneratedPass(newPass);
};
const copyPassword = () => {
	clipboard.copy(generatedPass);
	setSnackbar('success', 'Пароль скопирован в буфер обмена');
};

return (
	<React.Fragment>
		<Dialog 
			open={open} 
			onClose={handleClose}
			>
			<DialogTitle>
				Изменить пароль пользователя
			</DialogTitle>
			<DialogContent>
			<DialogContentText>
				Для изменения пароля дважды введите новый пароль и нажмите подтвердить.
			</DialogContentText>
			
				<TextField
					autoFocus
					margin="dense"
					id="pass"
					label="Пароль"
					type="password"
					fullWidth
					variant="standard"
					value={password}
					onChange={(e)=>setPassword(e.target.value)}
					required
					inputProps={{
						minLength : 10
					}}
				/>
				<TextField
					autoFocus
					margin="dense"
					id="passRepeat"
					label="Повторите пароль"
					type="password"
					value={passwordRepeat}
					onChange={(e)=>setPasswordRepeat(e.target.value)}
					fullWidth
					variant="standard"
					required
					inputProps={{
						minLength : 10
					}}
				/>
				{generatedPass === '' ?
					<>
						<Button 
							variant="outlined" 
							onClick={generatePassClick}
							fullWidth
							>
							Сгенерировать надежный пароль
						</Button>
					</>:
					<Grid 
						container 
						fontSize={'large'} 
						alignItems={'center'}
						>
						<Grid 
							item 
							sm = {1}
							>
							<IconButton 
								size="small" 
								color="primary" 
								onClick={generatePassClick}
								>
								<Autorenew/>
							</IconButton>
						</Grid>
						<Grid 
							item 
							sm = {10}
							> 
							<strong>
								{generatedPass}
							</strong>
						</Grid>
						<Grid 
							item 
							sm = {1} 
							justifyContent={'center'}
							> 
							<IconButton 
								size="small" 
								color="success" 
								onClick={copyPassword}
								>
								<ContentCopy/>
							</IconButton>
						</Grid>
					</Grid>
				}
			</DialogContent>
			<DialogActions>
				<Button 
					type="reset"
					onClick={handleClose}
					>
					Отменить
				</Button>
				<Button 
					onClick={handleSubmit} 
					type="submit"
					>
					Подтвердить изменения
				</Button>
			</DialogActions>
		</Dialog>
	</React.Fragment>
);
}

PasswordChangeDialog.propTypes = {
	open : PropTypes.bool,
	setOpen : PropTypes.func,
	setSnackbar : PropTypes.func,
	userRegNumber : PropTypes.string,
	token : PropTypes.any,
	onExpired : PropTypes.func
};