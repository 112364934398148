import {Action, combineReducers, configureStore} from '@reduxjs/toolkit';
import contractReducer from '../../features/contract';
import authReduser from "../../features/auth";
import activationSlice from "../../features/activation/activationSlice";
import travelDocumentsSlice from "../../features/travelDocuments";
import {authExpired, logout} from "../../features/auth/authSlice";
import passwordChangeSlice from "../../features/passwordChange/passwordChangeSlice";
import {userApi, contractApi, travelDocumentApi} from "../../api";

const appReducer = combineReducers( {
	auth : authReduser,
	contract : contractReducer,
	activation : activationSlice,
	travelDocuments : travelDocumentsSlice,
	passwordChange : passwordChangeSlice,
	[userApi.reducerPath] : userApi.reducer,
	[contractApi.reducerPath] : contractApi.reducer,
	[travelDocumentApi.reducerPath] : travelDocumentApi.reducer,
});

const rootReducer = (state : any, action : Action) => {
	if(action.type === authExpired.type || action.type === logout.type)
		return appReducer(undefined, action);
	return appReducer(state, action);
};

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware(
		{
			serializableCheck: {
				ignoredPaths: ['contract.tableSelectsOptions'],
			},
		}
	).concat(userApi.middleware).concat(contractApi.middleware).concat(travelDocumentApi.middleware),
});
export default store;

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch