import './FormInput.css';
import React, { }  from "react";
import {Col, Row} from "react-bootstrap";

export type RawFormInputProps = {
	type? : string, 
	name? : string, 
	onChange? : React.ChangeEventHandler<HTMLInputElement>, 
	value? : string | number, 
	pattern? : string, 
	minLength? : number, 
	maxLength? : number, 
	placeholder? : string, 
	readOnly? :boolean, 
	disabled? :boolean,
	required? :boolean, 
	step? : number,
	autoComplete? : React.HTMLInputAutoCompleteAttribute | undefined
}

export function formInputProps ({type, name, onChange, value, pattern, minLength = 0, maxLength = 255, placeholder = "", 
								readOnly = false, disabled = false, required = true, step, autoComplete = undefined} : RawFormInputProps) : RawFormInputProps{
	return {
		type, 
		name, 
		onChange, 
		value, 
		pattern, 
		minLength, 
		maxLength, 
		placeholder,
		readOnly, 
		disabled, 
		required,
		step,
		autoComplete
	};
}

interface FormInputProps {
	inputProps? : RawFormInputProps,
	label : React.ReactNode
}

const FormInput = ({inputProps = {}, label = "",} : FormInputProps) => {
	return (
		<Row 
			style={{
				display:"flex", 
				verticalAlign:"middle"
			}}>
			<Col 	
				md ={6}
				className = {`formInputLabel`}
				style={{margin:"auto"}}
				>
				{label}		
			</Col>
			<Col 	
				md ={6}
				className = {`formInputContainer`}
				style={{margin:"auto"}}
				>
				<input 	
					className={`formInput`}
					{...inputProps}
					/>	
			</Col>
		</Row>
	);
};

export default FormInput;