import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import config from "../config/config";
import apiRoutes from "../config/apiRoutes";
import {baseQueryWithExpire, transformAuthorizedErrorResponse, transformFileResponse} from './transformApiResponse';
import { RootState } from "../app/store/store";
import { ContractFullData, ContractListItem, CreateContractResponseData } from "../app/types/contractTypes";
const {contractRoutes, accountantRoutes} = apiRoutes;

export const contractApi = createApi({
	reducerPath: 'contractApi',	
	baseQuery: baseQueryWithExpire(fetchBaseQuery({
		baseUrl: config.env.apiBase,
		prepareHeaders: (headers, {getState}) => {
			const token = (getState() as RootState).auth.token;
			if (token){
				headers.set('Authorization', token);
			}
		},
	})),
	endpoints: (builder) => ({
		getContractList: builder.query({
			query: () => contractRoutes.getList,
			transformResponse: (response : {values : {contracts : ContractListItem[]}}) =>response?.values?.contracts ?? [],
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		downloadContract : builder.mutation({
			query : ({contractId, contractNumber, contractYear}) => ({
				url: `${contractRoutes.printInvoice}/?contractId=${contractId}`,
				responseHandler : (res : Response) => res.status === 200 ? res.blob() :res.json(),
			}),
			transformResponse :(response, meta, args) =>  transformFileResponse(response, meta, args, `Заявка ${args.contractNumber}-${args.contractYear}`),
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
		createContract : builder.mutation({
			query : (body) =>({
				url:`${contractRoutes.create}`,
				method: "POST",
				body
			}),
			transformResponse : (response : {values : {contractData : CreateContractResponseData}}) =>response.values.contractData,
			transformErrorResponse : transformAuthorizedErrorResponse
		}),
		searchContract: builder.query({
			query: ({contractNumber, contractYear}) =>({
				url : accountantRoutes.searchContract,
				params :{contractNumber, contractYear}
			}),
			transformResponse: (response : {values :{contract : ContractFullData}}) =>response?.values?.contract,
			transformErrorResponse : transformAuthorizedErrorResponse,
		}),
	}),

});

export const {useGetContractListQuery, useDownloadContractMutation, useCreateContractMutation} = contractApi;