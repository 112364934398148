import ProtectedRoute from "../ProtectedRoute";
import {useSelector} from "react-redux";
import {selectIsLoggedIn, selectUserRole} from "../../features/auth/authSlice";
 
type AuthenticationGuardProps = {
	redirectPath : string,
	guardType : 'authenticated' | 'unauthenticated',
	acceptedRoles : Array<string>,
};
 
const AuthenticationGuard = ({
  redirectPath = '/login',
  guardType = 'authenticated',
  acceptedRoles = [],
  ...props
} :AuthenticationGuardProps) => {

	const isLoggedIn = useSelector(selectIsLoggedIn);
	const role = useSelector(selectUserRole);

	const isAllowed = guardType === 'authenticated' ? !!isLoggedIn : !isLoggedIn;
	const isAllowedForRole = acceptedRoles.includes(role);
	const message = isAllowed && !isAllowedForRole ? "Запрошенная страница не доступна с вашим уровнем доступа." : null;

	return (
	<ProtectedRoute
		redirectPath={redirectPath}
		isAllowed={isAllowed && isAllowedForRole}
		redirectMessage = {message}
		{...props}
	/>
	);
};
export default AuthenticationGuard;