import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

type BasicModalProps = {
	header : string, 
	mainText : string,
	isOpen : boolean,
	onClose : () => void
}

export default function BasicModal({header = "", mainText = "", isOpen, onClose = ()=>{}} : BasicModalProps) {
	const handleClose = () =>{ 
		onClose();
	};

	return (
		<Modal
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			>
			<Box sx={style}>
				<Typography 
					id="modal-modal-title" 
					variant="h6" 
					component="h2"
					>
					{header}
				</Typography>
				<Typography 
					id="modal-modal-description" 
					sx={{mt: 2}}
					>
					{mainText}
				</Typography>
			</Box>
		</Modal>
	);
}