
import '../TravelDocumentCreateForm.css';
import React, {useEffect, useState}  from "react";
import Box from '@mui/material/Box';
import FormInput, {formInputProps} from "../../../../Components/Inputs/FormInput";
import AlertChoise from "../../../../Components/AlertChoise";
import {alertStates, useAlert} from "../../../../states/useAlert";
import SelectAllTransferList from "../../../../Components/SelectAllTransferList";
import {travelDocumentApi} from "../../../../api";
import {LoadingButton} from "@mui/lab";

const TransportTab = ()=> {
	const [transportCathegoryName, setTransportCathegoryName] = useState('');
	const {alertStatus, setAlertStatus} = useAlert();
	const [availableTransports, setAvailableTransports] = useState([] as string[]);
	const [choisenTransports, setChosenTransports] = useState([] as string[]);
	const transportTypesQuery = travelDocumentApi.useGetTransportsQuery({});
	const [createTransportCath, createResult] = travelDocumentApi.useCreateTransportCathegoryMutation();

	useEffect(()=>{
		const {isSuccess, isError, data, error} = transportTypesQuery;
		if(isSuccess){
			setAvailableTransports(data.map(transport => transport.transportName));
			setChosenTransports([]);
		}else if (isError){
			console.log(error);
			setAlertStatus(true, alertStates.errorAlert, error.message);
		}
	},[setAlertStatus, transportTypesQuery]);

	
	const submitForm = (e : React.FormEvent<HTMLFormElement>) =>{
		e.preventDefault();
		setAlertStatus(false);
		if(choisenTransports.length === 0){
			setAlertStatus(true, alertStates.errorAlert, 'Выберите хотя бы один вид транспорта');
			return;
		}
		const availableTransport = transportTypesQuery?.data?.filter(transport => choisenTransports.includes(transport.transportName)).map(item => item.transportName);
		createTransportCath({transportCathegoryName, availableTransport}).unwrap()
		.then(()=>{
			setAlertStatus(true, alertStates.successAlert, `Вид транспорта ${transportCathegoryName} успешно добавлена!`);
			setTransportCathegoryName('');
			setChosenTransports([]);
			setAvailableTransports(transportTypesQuery?.data?.map(transport => transport.transportName) ?? []);
		})
		.catch(error=>{
			console.log(error);
			setAlertStatus(true, alertStates.errorAlert, error.message);
		});
	};

	return (
		<Box>
			<Box sx={{textAlign:"center"}}>
				<h3>
					Добавление новой категории транспорта
				</h3>
				<h6>
					Категория транспорта - несколько видов транспорта, объединенных по типу проездного
				</h6>
			</Box>
			<Box sx = {{width:"70%", margin:"auto"}}>
				<form 
					id = "addTransportCathegoryForm" 
					onSubmit={submitForm}
					>
					<FormInput 	
						label={"Название категории"} 
						inputProps={ 
							formInputProps({
								type : "text",
								name : "transportCathegoryName",
								onChange : (e) => setTransportCathegoryName(e.target.value),
								value : transportCathegoryName,
								placeholder : "Введите название категории транспорта",
								minLength : 3
							})
						}
						/>
					<Box sx = {{textAlign:"center", marginTop:"0.5rem"}}>
						<h6>
							Выбор входящих в категорию видов транспорта
						</h6>
						<h6>
							Для выбора транспорта переместите его в правую колонку
						</h6>
						<SelectAllTransferList 	
							choises={availableTransports}
							choisen={choisenTransports}
							setChoisen = {(choisen)=>setChosenTransports(choisen)}
							setChoises = {(choises)=>setAvailableTransports(choises)}
							/>
					</Box>
					<Box sx = {{textAlign:"center", marginTop:"0.5rem"}}>
						<AlertChoise {...alertStatus}/>
					</Box>
					<Box sx = {{textAlign:"center", marginTop:"2rem"}}>
						<LoadingButton
							type ="submit"
							className="submitNewCathegory"
							color = "success"
							variant="contained"
							disabled = {!transportTypesQuery.isSuccess}
							loading = {createResult.isLoading}
							>
							Добавить Категорию
						</LoadingButton>
					</Box>
				</form>
			</Box>
		</Box>
	);
};
export default TransportTab;